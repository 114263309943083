export const ACTION_TYPE_SPENDINGLIMITS = {
  GET_SPENDING_LIMITS: 'GET_SPENDING_LIMITS',
  GET_SPENDING_LIMITS_SUCCESS: 'GET_SPENDING_LIMITS_SUCCESS',
  GET_SPENDING_LIMITS_ERROR: 'GET_SPENDING_LIMITS_ERROR',

  ADD_SPENDING_LIMITS: 'ADD_SPENDING_LIMITS',
  ADD_SPENDING_LIMITS_SUCCESS: 'ADD_SPENDING_LIMITS_SUCCESS',
  ADD_SPENDING_LIMITS_ERROR: 'ADD_SPENDING_LIMITS_ERROR',

  EDIT_SPENDING_LIMITS: 'EDIT_SPENDING_LIMITS',
  EDIT_SPENDING_LIMITS_SUCCESS: 'EDIT_SPENDING_LIMITS_SUCCESS',
  EDIT_SPENDING_LIMITS_ERROR: 'EDIT_SPENDING_LIMITS_ERROR',

  GET_SPENDING_LIMITS_DEFAULTS: 'GET_SPENDING_LIMITS_DEFAULTS',
  GET_SPENDING_LIMITS_DEFAULTS_SUCCESS: 'GET_SPENDING_LIMITS_DEFAULTS_SUCCESS',
  GET_SPENDING_LIMITS_DEFAULTS_ERROR: 'GET_SPENDING_LIMITS_DEFAULTS_ERROR',
}
