import './EarnCredit.scss'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { InputMultipleValue } from 'yacob-components'
import { TitleDescription } from 'yacob-components'
import { Button } from 'yacob-components'
import { ShareCredit } from 'yacob-components'
import { CreditHistory } from 'yacob-components'

import { EmailValidation } from '../../utils/validation'
import { LocalStorage } from '../../utils/service'
import { USER_DATA } from '../../constants/app-constants'
import {
  ACTION_TYPE_EARNCREDIT,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'

class EarnCreditTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailaddress: '',
      emailaddressList: [],
      currentFieldFocus: 'emailaddress',
      validationemailaddress: false,
      referralHistory: [],
      referralTotalCredits: 0,
      referralUrl: '',
      sendinviteshowLoader: false,
    }
  }

  componentDidMount() {
    const { getReferralHistory, getReferralUrl, setProgressData } = this.props
    const uuid =
      LocalStorage.getUserData(USER_DATA) &&
      LocalStorage.getUserData(USER_DATA).uuid
    getReferralHistory(uuid)
    getReferralUrl(uuid)
    setProgressData({}, false, 0)
  }

  componentWillReceiveProps(nextProps) {
    const { earnCreditData } = this.props
    const { referralHistory, referralUrl, data } = earnCreditData
    if (
      nextProps.earnCreditData.referralHistory &&
      nextProps.earnCreditData.referralHistory !== referralHistory
    ) {
      if (nextProps.earnCreditData.referralHistory.referral_histories) {
        this.setState({
          referralHistory:
            nextProps.earnCreditData.referralHistory.referral_histories,
        })
      }
      if (nextProps.earnCreditData.referralHistory.total_credits) {
        this.setState({
          referralTotalCredits:
            nextProps.earnCreditData.referralHistory.total_credits,
        })
      }
    }
    if (
      nextProps.earnCreditData.referralUrl &&
      nextProps.earnCreditData.referralUrl !== referralUrl &&
      nextProps.earnCreditData.referralUrl.referral_url
    ) {
      this.setState({
        referralUrl: nextProps.earnCreditData.referralUrl.referral_url,
      })
    }
    if (
      nextProps.earnCreditData.data &&
      nextProps.earnCreditData.data !== data
    ) {
      this.setState({
        sendinviteshowLoader: false,
        emailaddressList: [],
        emailaddress: '',
      })
      if (!nextProps.earnCreditData.data.error) {
        this.alert({
          type: 'success',
          content: 'Invitations sent successfully',
        })
      } else if (nextProps.earnCreditData.data.error) {
        this.alert({
          type: 'success',
          content: nextProps.earnCreditData.data.error,
        })
      }
    }
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  onFocusInput = id => {
    this.setState({ currentFieldFocus: id })
  }

  onChangeInput = (id, value) => {
    this.setState({ [id]: value })
  }

  getEmailAddressList = value => {
    this.setState({ emailaddressList: value })
  }

  sendInvitesHandler = () => {
    const { emailaddress, emailaddressList } = this.state
    if (
      emailaddressList &&
      emailaddressList.length &&
      EmailValidation(emailaddress)
    ) {
      const { getReferralInvitations } = this.props
      const uuid =
        LocalStorage.getUserData(USER_DATA) &&
        LocalStorage.getUserData(USER_DATA).uuid
      const data = {
        user_uuid: uuid,
        emails: emailaddressList,
      }
      this.setState({ sendinviteshowLoader: true })
      getReferralInvitations(data)
    } else {
      this.setState({ validationemailaddress: true })
    }
  }

  validationChangeHandler = (id, value) => {
    this.setState({ validationemailaddress: value })
  }

  copyLinkHandler = () => {
    const copyText = document.getElementById('sharecredit__link_text')
    if (copyText) {
      copyText.select()
      document.execCommand('copy')
    }
  }

  facebookShareHandler = link => {
    window.open(`https://www.facebook.com/dialog/share?href=${link}`, '_blank')
  }

  twitterShareHandler = link => {
    window.open(`https://twitter.com/share?url=${link}`, '_blank')
  }

  linkedInShareHandler = link => {
    window.open(
      `http://www.linkedin.com/shareArticle?mini=true&url=${link}`,
      '_blank',
    )
  }

  render() {
    const {
      emailaddress,
      emailaddressList,
      currentFieldFocus,
      validationemailaddress,
      referralHistory,
      referralTotalCredits,
      referralUrl,
      sendinviteshowLoader,
    } = this.state
    const { t } = this.props
    const name =
      LocalStorage.getUserData(USER_DATA) &&
      `${LocalStorage.getUserData(USER_DATA).first_name} ${
        LocalStorage.getUserData(USER_DATA).last_name
      }`

    const DOM = (
      <div className="earncredit_container">
        <div className="earncredit">
          <TitleDescription title={`${name} — ${t('earn_credit')}`} />
          <div className="earncredit__description">
            {t('earn_credits_description_one')}
            <span className="earncredit__description_bold">
              {` ${LocalStorage.getCurrency()} 20 `}
            </span>
            {t('earn_credits_description_two')}
          </div>
          <div className="earncredit__invites">
            <div className="earncredit__invites_title">{t('send_invites')}</div>
            <div className="earncredit__invites_input">
              <InputMultipleValue
                id="emailaddress"
                label={t('email_addresses')}
                type="text"
                required
                emailaddressList={emailaddressList}
                value={emailaddress}
                onFocus={this.onFocusInput}
                onChange={this.onChangeInput}
                currentFieldFocus={currentFieldFocus}
                validation={validationemailaddress}
                validationChange={this.validationChangeHandler}
                getEmailAddressList={this.getEmailAddressList}
              />
              <Button
                text={t('send_invites')}
                type="block"
                size="big"
                showloader={sendinviteshowLoader}
                onClick={this.sendInvitesHandler}
              />
            </div>
          </div>
          <div className="earncredit__share">
            <div className="earncredit__invites_title">
              {t('share_your_link')}
            </div>
            <ShareCredit
              link={referralUrl}
              copyLink={this.copyLinkHandler}
              facebookShare={this.facebookShareHandler}
              twitterShare={this.twitterShareHandler}
              linkedInShare={this.linkedInShareHandler}
            />
          </div>
          <div className="earncredit__history">
            <div className="earncredit__invites_title">
              {t('credit_history')}
            </div>
            <CreditHistory
              referralHistory={referralHistory}
              referralTotalCredits={referralTotalCredits}
            />
          </div>
        </div>
      </div>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    earnCreditData: state.earnCredit,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getReferralInvitations: data =>
      dispatch({
        type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_INVITATIONS,
        data,
      }),
    getReferralHistory: data =>
      dispatch({
        type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_HISTORY,
        data,
      }),
    getReferralUrl: data =>
      dispatch({
        type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_URL,
        data,
      }),
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const EarnCredit = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(EarnCreditTrans)),
)
