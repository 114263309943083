import './Nutrition.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import { CustomTabs } from 'yacob-components'
import { TitleDescription } from 'yacob-components'
import { NutritionItems } from 'yacob-components'
import { NutritionConsumption } from 'yacob-components'
// import { NutritionInsightAdvice } from 'yacob-components';

import { ACTION_TYPE_NUTRITION } from '../../redux/actions'
import { ROUTES as _routes } from '../../constants/app-constants'
import { Loader } from 'yacob-components'

class NutritionTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabList: [
        { key: 'items', status: 'active', displayName: 'items', index: 0 },
        {
          key: 'consumption',
          status: '',
          displayName: 'consumption',
          index: 1,
        },
        { key: 'insights', status: '', displayName: 'insights', index: 2 },
      ],
      activeTab: 'items',
      adviceData: [],
      insightData: [],
      itemsData: [],
      itemsColumn: [
        { title: 'ITEM', key: 'food_item_name', sort: 'a-d' },
        {
          title: 'YACOB SCORE',
          key: 'yacob_score',
          sort: 'a-d',
        },
        {
          title: 'DATE',
          key: 'ordered_date',
          head_classname: 'u_text_right',
          sort: 'a-d',
          render: item => (
            <div className="u_text_right">
              {moment(item).format('DD MMMM, YYYY')}
            </div>
          ),
        },
        {
          title: 'CALORIES',
          key: 'item_calories',
          head_classname: 'u_text_right',
          data_classname: 'u_font_roboto_medium',
          sort: 'a-d',
          render: item => <div className="u_text_right ">{item}</div>,
        },
      ],
      itemsTotalRecords: 40,
      filterDropdown: [
        { key: '30', displayName: 'last 30 days', index: 0 },
        { key: 'recent', displayName: 'recent', index: 1 },
      ],
      filterActiveValue: 'last 30 days',
      filterActiveKey: '30',
      nutritionConsumptionData: {},
      showloader: false,
      activeSort: '',
      activePage: 1,
    }
  }

  componentDidMount() {
    this.getTabApi('items')
  }

  componentWillReceiveProps(nextProps) {
    const { nutritionData } = this.props
    if (
      nextProps.nutritionData &&
      nextProps.nutritionData.data &&
      nextProps.nutritionData.data !== nutritionData.data
    ) {
      if (nextProps.nutritionData.data.consumption) {
        this.setNutritionConsumptionData(
          nextProps.nutritionData.data.consumption,
        )
      }
      this.setState({ showloader: false })
    }
    if (
      nextProps.nutritionData &&
      nextProps.nutritionData.itemData &&
      nextProps.nutritionData.itemData !== nutritionData.itemData
    ) {
      if (nextProps.nutritionData.itemData.items)
        this.setState({
          itemsData: nextProps.nutritionData.itemData.items,
          itemsTotalRecords: nextProps.nutritionData.itemData.items.length,
          // itemsTotalRecords: nextProps.nutritionData.itemData.total_records,
          showloader: false,
        })
    }
    if (
      nextProps.nutritionData &&
      nextProps.nutritionData.insightAdvData &&
      nextProps.nutritionData.insightAdvData !== nutritionData.insightAdvData
    ) {
      this.setState({ showloader: false })
      if (nextProps.nutritionData.insightAdvData.nutrition_insights) {
        if (nextProps.nutritionData.insightAdvData.nutrition_insights.advices) {
          this.setState({
            adviceData:
              nextProps.nutritionData.insightAdvData.nutrition_insights.advices,
          })
        }
        if (
          nextProps.nutritionData.insightAdvData.nutrition_insights.insights
        ) {
          this.setState({
            insightData:
              nextProps.nutritionData.insightAdvData.nutrition_insights
                .insights,
          })
        }
      }
    }
  }

  getChildUuid = () => {
    const { location } = this.props
    const { state } = location
    if (!state) {
      const { history } = this.props
      history.push(_routes.HOME)
      return null
    }
    if (!state.data) {
      const { history } = this.props
      history.push(_routes.HOME)
      return null
    }
    const uuid = state.data && state.data.uuid
    return uuid
  }

  getChildName = () => {
    const { location } = this.props
    const { state } = location
    if (!state) {
      return ''
    }
    let name = ''
    if (state.data && state.data.first_name) name = state.data.first_name
    if (state.data && state.data.last_name)
      name = `${name} ${state.data.last_name}`
    return name
  }

  getTabApi = key => {
    const uuid = this.getChildUuid()
    if (key === 'consumption') {
      const { nutritionConsumptionData } = this.state
      if (
        nutritionConsumptionData &&
        nutritionConsumptionData.nutrition_details
      )
        return
      const { getNutritionConsumption } = this.props
      const { filterActiveKey } = this.state
      const data = {
        uuid,
        days_limit: filterActiveKey,
      }
      getNutritionConsumption(data)
    } else if (key === 'items') {
      const { itemsData } = this.state
      if (itemsData && itemsData.length) return
      this.getNutritionItems(1)
    } else if (key === 'insights') {
      const { insightData, adviceData } = this.state
      if (insightData && insightData.length && adviceData && adviceData.length)
        return
      const { getNutritionInsightAdvice } = this.props
      const data = {
        uuid,
      }
      getNutritionInsightAdvice(data)
    }
    this.setState({ showloader: true })
  }

  tabonClick = data => {
    const { tabList } = this.state
    Object.keys(tabList).forEach(e => {
      if (tabList[e].index === data.index) {
        tabList[e].status = 'active'
        this.getTabApi(tabList[e].key)
        this.setState({ activeTab: tabList[e].key })
      } else {
        tabList[e].status = ''
      }
    })
    this.setState({ tabList })
  }

  setNutritionConsumptionData = data => {
    if (data.nutrition_details) {
      const categoryJson = { data: [], colors: [], labelValue: [] }
      Object.keys(data.nutrition_details).forEach(key => {
        if (key === 'neutral') {
          categoryJson.colors.push('#f5f5f5')
          categoryJson.labelValue.push('Neutral')
        } else if (key === 'healthy') {
          categoryJson.colors.push('#8ab303')
          categoryJson.labelValue.push('Healthy')
        } else if (key === 'junk') {
          categoryJson.colors.push('#f25a49')
          categoryJson.labelValue.push('Junk')
        }
        categoryJson.data.push(data.nutrition_details[key])
      })
      data.nutrition_details = categoryJson
    }
    this.setState({ nutritionConsumptionData: data })
  }

  onfilterValueClick = (key, value) => {
    this.setState({ filterActiveKey: key, filterActiveValue: value })
  }

  onChangeNutritionItemPagination = key => {
    const { activeSort } = this.state
    this.getNutritionItems(key, activeSort)
  }

  tableItemsSort = key => {
    const { itemsColumn, activePage } = this.state
    itemsColumn.forEach((item, index) => {
      if (key === item.key) {
        const split = item.sort.split('')
        const array = split.reverse()
        const string = array.join('')
        itemsColumn[index].sort = string

        if (string === 'a-d') key = `%2B${key}`
        else key = `%20${key}`
        this.setState({ activeSort: key })
        this.getNutritionItems(activePage, key)
      } else {
        itemsColumn[index].sort = 'a-d'
      }
    })
    this.setState({ itemsColumn })
  }

  getNutritionItems = (page, sort) => {
    const uuid = this.getChildUuid()
    const { getNutritionItems } = this.props
    const data = {
      uuid,
      page,
      limit: 20,
      sort,
    }
    getNutritionItems(data)
  }

  renderContent = () => {
    const {
      activeTab,
      itemsData,
      filterDropdown,
      filterActiveValue,
      // adviceData, insightData,
      nutritionConsumptionData,
      itemsTotalRecords,
      itemsColumn,
    } = this.state
    let dom = null
    switch (activeTab) {
      case 'consumption':
        dom = (
          <NutritionConsumption
            contentData={nutritionConsumptionData}
            filterdata={filterDropdown}
            filterActiveValue={filterActiveValue}
            onfilterValueClick={this.onfilterValueClick}
          />
        )
        break
      case 'items':
        dom = (
          <NutritionItems
            columns={itemsColumn}
            totalRecords={itemsTotalRecords}
            onChangePagination={this.onChangeNutritionItemPagination}
            itemsData={itemsData}
            tableItemsSort={this.tableItemsSort}
          />
        )
        break
      case 'insights':
        dom = <div className="u_overlay_text">Coming soon...</div>
        // dom = <NutritionInsightAdvice adviceData={adviceData} insightData={insightData} />
        break
      default:
        break
    }
    return dom
  }

  render() {
    const { tabList, showloader } = this.state
    const { t } = this.props
    return (
      <div className="nutrition_container">
        <div className="nutrition">
          <div className="nutrition__name">
            <TitleDescription
              title={`${this.getChildName()} — ${t('nutrition')}`}
            />
          </div>
          <CustomTabs data={tabList} onClick={this.tabonClick} />
          {showloader ? (
            <div className="nutrition__loader">
              <Loader fitContent="full" />
            </div>
          ) : (
            this.renderContent()
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    nutritionData: state.nutrition,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNutritionConsumption: data =>
      dispatch({
        type: ACTION_TYPE_NUTRITION.GET_NUTRITION_CONSUMPTION,
        data,
      }),
    getNutritionItems: data =>
      dispatch({
        type: ACTION_TYPE_NUTRITION.GET_NUTRITION_ITEM,
        data,
      }),
    getNutritionInsightAdvice: data =>
      dispatch({
        type: ACTION_TYPE_NUTRITION.GET_NUTRITION_INSIGHT_ADVICE,
        data,
      }),
  }
}

export const Nutrition = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(NutritionTrans)),
)
