import './ChildTopup.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'

import { TopupAmount } from 'yacob-components'
import { TopupSuccess } from 'yacob-components'

import {
  ACTION_TYPE_PROGRESSTRACK,
  ACTION_TYPE_TOPUP,
  ACTION_TYPE_LOGIN,
} from '../../redux/actions'

import {
  ROUTES as _routes,
  USER_DATA,
  PAYMENT_ID,
} from '../../constants/app-constants'

import { LocalStorage } from '../../utils/service'
import { Payment } from 'yacob-components'

class ChildTopupTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCustomAmount: false,
      customAmount: '',
      currentFieldFocus: '',
      currentProgress: 'amount',
      paymentCardArray: [
        {
          amount: 100,
          mainText: `${LocalStorage.getCurrency()} 100`,
          subText: '',
        },
        {
          amount: 300,
          mainText: `${LocalStorage.getCurrency()} 300`,
          subText: '',
        },
        {
          amount: 500,
          mainText: `${LocalStorage.getCurrency()} 500`,
          subText: '',
        },
      ],
      childData: {},
      verifyPayment: {},
      progressData: [
        {
          key: 'amount',
          status: 'active',
          displayName: 'AMOUNT',
          index: 0,
          route: `${_routes.CHILD_TOPUP}/amount`,
        },
        {
          key: 'payment',
          status: '',
          displayName: 'PAYMENT METHOD',
          index: 1,
          route: `${_routes.CHILD_TOPUP}/payment`,
        },
      ],
      paymentSelection: 'Credit card',
      useMyBalance: true,
      paybtnshowloader: false,
    }
    this.totalamount = 0
  }

  componentDidMount() {
    const { setProgressData, location, history } = this.props
    const { progressData } = this.state
    const { state } = location
    const splitpath = location.pathname.split(`${_routes.CHILD_TOPUP}/`)
    if (splitpath[1] === 'status') {
      this.checkPathSearch()
    } else if (!state) {
      history.push(_routes.HOME)
    } else {
      const { data } = state
      if (data && data.uuid) {
        this.setState({ childData: data })
        this.setPaymentCardArray(data)
      } else {
        history.push(_routes.HOME)
      }
    }
    if (splitpath[1] !== 'status') {
      this.currentPath(history)
      setProgressData(progressData, true, 0)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { topupdata, location } = this.props

    if (nextProps.location !== location) this.currentPath(nextProps.history)
    if (
      nextProps.topupdata &&
      nextProps.topupdata.childdata &&
      nextProps.topupdata.childdata !== topupdata.childdata
    ) {
      this.setState({ paybtnshowloader: false })
      if (nextProps.topupdata.childdata.payment) {
        this.redirectPaymentGateway(nextProps.topupdata.childdata.payment)
      } else if (nextProps.topupdata.childdata.balances) {
        this.parentBalanceSuccess(nextProps.topupdata.childdata.balances)
      } else if (nextProps.topupdata.childdata.error_type === 'PaymentError') {
        this.alert({ type: 'error', content: 'Please topup your account' })
        const { history } = this.props
        const { totalamount } = this
        const path = {
          pathname: `${_routes.ACCOUNT_TOPUP}/amount`,
          state: { amount: totalamount },
        }
        history.push(path)
      } else if (nextProps.topupdata.childdata.error) {
        this.alert({
          type: 'error',
          content: nextProps.topupdata.childdata.error,
        })
      }
    }
  }

  currentPath = history => {
    const splitpath = history.location.pathname.split(`${_routes.CHILD_TOPUP}/`)
    this.setProgressDataHandler(splitpath[1])
  }

  checkPathSearch = () => {
    const { history } = this.props
    if (history.location && history.location.search) {
      const searchqueryjson = decodeURI(history.location.search)
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce((values, [key, value]) => {
          values[key] = value
          return values
        }, {})
      const apijson = {
        amount: searchqueryjson.amount,
        balance: searchqueryjson.child_balance,
        name: searchqueryjson.child_name,
        status: searchqueryjson.status,
      }
      this.verifyPayment(apijson)
    }
  }

  setProgressDataHandler = (path = 'amount') => {
    const { progressData } = this.state
    const { setProgressData, history } = this.props
    const { totalamount } = this
    let index = 0
    const scrollElement = document && document.getElementById('container')
    if (scrollElement) scrollElement.scrollTop = 0
    if (path === 'status') {
      this.setState({ currentProgress: 'status' })
      this.checkPathSearch()
      setProgressData({}, false, index)
      return
    }
    if (!totalamount && path === 'payment') {
      const { childData } = this.state
      const replacepath = {
        pathname: `${_routes.CHILD_TOPUP}`,
        state: { data: childData },
      }
      history.replace(replacepath)
      return
    }

    Object.keys(progressData).forEach(e => {
      if (progressData[e].key === path.replace('/', '')) {
        progressData[e].status = 'active'
        index = e
      }
    })
    Object.keys(progressData).forEach(e => {
      if (e > index) progressData[e].status = ''
      else if (e < index) progressData[e].status = 'done'
    })

    this.setState({ currentProgress: path.replace('/', '') })
    setProgressData(progressData, true, index)
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  historyPush = path => {
    const { history } = this.props
    history.push(path)
  }

  redirectPaymentGateway = data => {
    window.open(data.payment_url, '_self')
    LocalStorage.setData(PAYMENT_ID, data.tran_ref)
  }

  customAmountClick = () => {
    this.setState({ showCustomAmount: true })
  }

  customAmountChange = e => {
    this.setState({ customAmount: e.target.value })
  }

  onFocusCustomAmount = () => {
    this.setState({ currentFieldFocus: 'customAmount' })
  }

  onClickPaymentCard = amount => {
    this.totalamount = amount
    const { childData } = this.state
    const path = {
      pathname: `${_routes.CHILD_TOPUP}/payment`,
      state: { data: childData },
    }
    this.historyPush(path)
  }

  onClickPay = () => {
    const { setTopup } = this.props
    const { childData, useMyBalance, paymentSelection } = this.state
    const uuid =
      LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid
    const childuuid = childData && childData.uuid
    const payload = {
      parent_uuid: uuid,
      child_uuid: childuuid,
      amount: Number(this.totalamount),
      payment: {
        use_balance: useMyBalance,
        payment_type: paymentSelection,
        return_url: `${window.location.origin}/child-topup/status`,
      },
    }
    this.setState({ paybtnshowloader: true })
    setTopup(payload)
  }

  saveCustomAmountClick = () => {
    const { customAmount } = this.state
    this.onClickPaymentCard(customAmount)
  }

  parentBalanceSuccess = data => {
    const { childData } = this.state
    const apijson = {
      amount: data.amount,
      balance: data.child_balance,
      name: childData && childData.first_name,
      status: 'SUCCESS',
      parent_balance: data.parent_balance,
    }
    this.verifyPayment(apijson)
  }

  verifyPayment = data => {
    const { logindata, setProgressData } = this.props
    if (logindata && logindata.data && data.parent_balance) {
      const { setUserData } = this.props
      logindata.data.balance = data.parent_balance
      const user = { user: logindata.data }
      setUserData(user)
    }
    setProgressData({}, false, 0)
    this.setState({ verifyPayment: data, currentProgress: 'status' })
  }

  successContinueClick = () => {
    const { history } = this.props
    history.push(_routes.HOME)
  }

  setPaymentCardArray = data => {
    const { paymentCardArray } = this.state
    const name = data.first_name
    const { balance } = data
    const currency = LocalStorage.getCurrency()
    let balanceEst = balance + 100
    let days = 5
    paymentCardArray[0].subText = (
      <Trans i18nKey="child_topup_suggestion">
        {{ name }}
        {{ currency }}
        {{ balanceEst }}
        {{ days }}
      </Trans>
    )
    balanceEst = balance + 300
    days = 10
    paymentCardArray[1].subText = (
      <Trans i18nKey="child_topup_suggestion">
        {{ name }}
        {{ currency }}
        {{ balanceEst }}
        {{ days }}
      </Trans>
    )
    balanceEst = balance + 500
    days = 15
    paymentCardArray[2].subText = (
      <Trans i18nKey="child_topup_suggestion">
        {{ name }}
        {{ currency }}
        {{ balanceEst }}
        {{ days }}
      </Trans>
    )
    this.setState({ paymentCardArray })
  }

  toggleBalance = e => {
    this.setState({ useMyBalance: e.target.checked })
  }

  onChangePaymentSelect = e => {
    this.setState({ paymentSelection: e.target.value })
  }

  renderContent = () => {
    const {
      showCustomAmount,
      useMyBalance,
      paymentSelection,
      paybtnshowloader,
      customAmount,
      currentFieldFocus,
      currentProgress,
      paymentCardArray,
      verifyPayment,
      childData,
    } = this.state
    const { t } = this.props
    const balance =
      LocalStorage.getUserData(USER_DATA) &&
      LocalStorage.getUserData(USER_DATA).balance
    const name = childData && childData.first_name
    let dom = null
    switch (currentProgress) {
      case 'amount':
        dom = (
          <TopupAmount
            balanceText={t('your_balance')}
            name={name}
            balanceValue={balance}
            onClickPaymentCard={this.onClickPaymentCard}
            showCustomAmount={showCustomAmount}
            customAmount={customAmount}
            paymentCardValue={paymentCardArray}
            currentFieldFocus={currentFieldFocus}
            customAmountClick={this.customAmountClick}
            customAmountChange={this.customAmountChange}
            onFocusCustomAmount={this.onFocusCustomAmount}
            saveCustomAmountClick={this.saveCustomAmountClick}
          />
        )
        break
      case 'payment':
        dom = (
          <Payment
            showUseMyBalance
            totalAmount={String(this.totalamount)}
            balance={balance}
            onChange={this.toggleBalance}
            toggleValue={useMyBalance}
            onChangeRadio={this.onChangePaymentSelect}
            paymentSelection={paymentSelection}
            toggleId="useMyBalance"
            onClickPay={this.onClickPay}
            paybtnshowloader={paybtnshowloader}
          />
        )
        break
      case 'status':
        dom = (
          <TopupSuccess
            cardText={`${verifyPayment && verifyPayment.name}’s ${t(
              'new_balance',
            )}`}
            data={verifyPayment}
            name={verifyPayment && verifyPayment.name}
            showButton
            successonClick={this.successContinueClick}
            balance={verifyPayment && verifyPayment.balance}
            currency={LocalStorage.getCurrency()}
          />
        )
        break
      default:
        break
    }
    return dom
  }

  render() {
    const DOM = (
      <div className="childtopup_container">{this.renderContent()}</div>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    topupdata: state.topup,
    logindata: state.login,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setTopup: data =>
      dispatch({
        type: ACTION_TYPE_TOPUP.GET_CHILD_TOPUP,
        data,
      }),
    setUserData: response =>
      dispatch({
        type: ACTION_TYPE_LOGIN.GET_LOGIN_SUCCESS,
        response,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const ChildTopup = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ChildTopupTrans)),
)
