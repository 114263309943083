import './Home.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { TitleDescription } from 'yacob-components'
import { Button } from 'yacob-components'
import { Accordion } from 'yacob-components'
import { HomeChildrenListItem } from 'yacob-components'
import { Loader } from 'yacob-components'

import {
  ROUTES as _routes,
  USER_DATA,
  USER_ROLE as _userRoles,
} from '../../constants/app-constants'
import {
  ACTION_TYPE_CHILD,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'
import { LocalStorage, getSelectedSchool } from '../../utils/service'

import { BASE_URL } from '../../constants/api-constants'
import { fetchCall } from '../../utils/ajax'

import moment from 'moment'

const date = moment()
const year = date.year()

const flagName = `childInfoUpdatedFor${year}`

class HomeTrans extends Component {
  constructor(props) {
    super(props)

    this.state = {
      childrenList: [],
      showloader: true,
      userRole: _userRoles.PARENT,
      selectedSchool: getSelectedSchool(),
    }
  }

  componentDidMount() {
    const { getChildList, setProgressData } = this.props
    const userData = LocalStorage.getUserData(USER_DATA)
    if (userData && userData.role === _userRoles.FACULTY) {
      this.setState({ userRole: _userRoles.FACULTY, showloader: false })
    } else {
      const uuid = userData && userData.uuid
      if (uuid) {
        getChildList(uuid)
      } else {
        this.setState({ showloader: false })
      }
    }

    // Remind parents to update child profile (grade, section, etc.)
    // for new school year starting each September
    const userSavedChildInfoForThisYear = localStorage.getItem(flagName)

    if (!userSavedChildInfoForThisYear) {
      const month = date.month()

      const prevYear = year - 1
      const prevFlagName = `childInfoUpdatedFor${prevYear}`

      const userSavedChildInfoForPrevYear = localStorage.getItem(prevFlagName)

      // Check that school started
      // Months are zero indexed, so September is month 8
      // https://momentjs.com/docs/#/get-set/month/
      // If it's second half after new year, check previous year
      if (month > 7 || userSavedChildInfoForPrevYear !== 'true') {
        this.alert({
          content:
            'Please review and update your child(ren) profile if necessary. The notification will disappear once you save the profile information.',
          type: 'warning',
          timeout: 0,
          id: flagName,
        })
      }
    }

    const { uuid: selectedSchoolUUID } = this.state.selectedSchool || {}
    const { school_uuid: userSchoolUUID } = userData || {}

    const schoolUUID = userSchoolUUID || selectedSchoolUUID

    // TODO: Ensure we have access token at that point,
    // Because that's not the case after the initial signup
    if (schoolUUID) {
      // TODO: Use redux saga instead?
      fetchCall(`${BASE_URL}schools/${schoolUUID}`, 'GET').then(data => {
        if (data.school) {
          localStorage.setItem('selectedSchool', JSON.stringify(data.school))
          this.setState({ selectedSchool: data.school })
        }
      })
    }

    setProgressData({}, false, 0)
  }

  componentWillUnmount() {
    const { deleteAlert } = this.props
    deleteAlert(flagName)
  }

  componentWillReceiveProps(nextProps) {
    const { childData } = this.props
    if (
      nextProps.childData &&
      nextProps.childData.childList &&
      nextProps.childData.childList !== childData.childList &&
      nextProps.childData.childList.children
    ) {
      this.setState({
        childrenList: nextProps.childData.childList.children,
        showloader: false,
      })
    }
  }

  historyPush = path => {
    const { history } = this.props
    history.push(path)
  }

  alert = data => {
    const { addAlert } = this.props
    addAlert(data)
  }

  deleteChild = (route, data) => {
    const path = {
      pathname: route,
      state: { uuid: data.uuid, name: data.first_name },
    }
    this.historyPush(path)
  }

  editProfileChild = (route, data) => {
    const profileData = {
      firstname: data.first_name,
      lastname: data.last_name,
      studentId: data.card_id,
      date_of_birth: new Date(data.date_of_birth),
      gradeuuid: data.grade_uuid,
      schooluuid: data.school_uuid,
      sectionuuid: data.section_uuid,
    }
    const path = {
      pathname: route,
      state: {
        isEdit: true,
        childuuid: data.uuid,
        data: profileData,
        progress: 'Profile',
      },
    }
    this.historyPush(path)
  }

  editSchoolChild = (route, data) => {
    const schoolData = {
      schooluuid: data.school_uuid,
      gradeuuid: data.grade_uuid,
      sectionuuid: data.section_uuid,
      studentId: data.card_id,
    }
    const path = {
      pathname: route,
      state: {
        isEdit: true,
        childuuid: data.uuid,
        data: schoolData,
        progress: 'School',
      },
    }
    this.historyPush(path)
  }

  editPhysicalChild = (route, data) => {
    const physicalData = {
      date_of_birth: new Date(data.date_of_birth),
      bloodgroup: data.blood_group,
      height: data.height_in_cms,
      weight: data.weight_in_kgs,
    }
    const path = {
      pathname: route,
      state: {
        isEdit: true,
        childuuid: data.uuid,
        data: physicalData,
        progress: 'Physical',
      },
    }
    this.historyPush(path)
  }

  editDietChild = (route, data) => {
    const dietData = {
      diet_custom: data.diet_custom,
      diet_uuid: data.diet_uuid,
    }
    const path = {
      pathname: route,
      state: {
        isEdit: true,
        childuuid: data.uuid,
        data: dietData,
        progress: 'Diet',
      },
    }
    this.historyPush(path)
  }

  editAllergyChild = (route, data) => {
    const allergydata = {
      has_allergies: data.has_allergies,
    }
    const path = {
      pathname: route,
      state: {
        isEdit: true,
        childuuid: data.uuid,
        data: allergydata,
        progress: 'Allergy',
      },
    }
    this.historyPush(path)
  }

  spendingLimitHandler = (route, data) => {
    const path = {
      pathname: route,
      state: { data },
    }
    this.historyPush(path)
  }

  addChildHandler = () => {
    this.historyPush(`${_routes.SIGNUP}/profile`)
  }

  lunchOrderClickHandler = data => {
    const path = {
      pathname: _routes.LUNCH_ORDER,
      state: { data },
    }
    this.historyPush(path)
  }

  lunchSubscribeClickHandler = data => {
    const path = {
      pathname: `${_routes.LUNCH_SUBSCRIBE}/delivery-plan`,
      state: { data },
    }
    this.historyPush(path)
  }

  childTopUpHandler = data => {
    const path = {
      pathname: _routes.CHILD_TOPUP,
      state: { data },
    }
    this.historyPush(path)
  }

  childNutritionHandler = data => {
    const path = {
      pathname: _routes.NUTRITION,
      state: { data },
    }
    this.historyPush(path)
  }

  childTransactionHistory = data => {
    const path = {
      pathname: _routes.CHILD_TRANSACTION_HISTORY,
      state: { data },
    }
    this.historyPush(path)
  }

  menuClickHandler = url => {
    if (url) window.open(url)
    else
      this.alert({ type: 'error', content: 'Menu not available to download' })
  }

  reportCardLost = (route, data) => {
    const path = {
      pathname: route,
      state: { uuid: data.uuid },
    }
    this.historyPush(path)
  }

  render() {
    const { childrenList, showloader, userRole } = this.state
    const {
      deleteChild,
      editProfileChild,
      editPhysicalChild,
      editDietChild,
      editAllergyChild,
      menuClickHandler,
      reportCardLost,
    } = this
    const { t } = this.props

    const tooltipListData = [
      {
        text: t('profile'),
        key: 'profile',
        route: `${_routes.SIGNUP}/profile`,
        onClick: editProfileChild,
      },
      {
        text: t('physical_information'),
        key: 'Physical Information',
        route: `${_routes.SIGNUP}/physical`,
        onClick: editPhysicalChild,
      },
      {
        text: t('diet_restrictions'),
        key: 'Diet Restrictions',
        route: `${_routes.SIGNUP}/diet`,
        onClick: editDietChild,
      },
      {
        text: t('allergy'),
        key: 'Allergy',
        route: `${_routes.SIGNUP}/allergy`,
        onClick: editAllergyChild,
      },
      // { text: t('spending_limits'), key: 'spending limits', route: _routes.SPENDING_LIMITS, onClick: spendingLimitHandler },
      { text: t('menu'), key: 'menu', onClick: menuClickHandler },
      {
        text: t('Report a Lost or Stolen Card'),
        key: 'report_lost',
        route: _routes.REPORT_CARD_LOST,
        onClick: reportCardLost,
      },
      {
        text: t('delete'),
        key: 'delete',
        route: _routes.DELETE_CHILDREN,
        onClick: deleteChild,
      },
    ]

    const contentDom = []
    const childrenDom = []
    if (childrenList && childrenList.length > 0) {
      childrenDom.push(
        <div className="home__accordion" key="accordion_home">
          <Accordion tooltipListData={tooltipListData} key="accordion">
            {childrenList.map(item => {
              return (
                <div
                  key={item.uuid}
                  label={`${item.first_name} ${item.last_name} ${item.uuid}`}
                  displayLabel={`${item.first_name} ${item.last_name}`}
                  apiData={item}
                >
                  <HomeChildrenListItem
                    lunchSubscribeClick={this.lunchSubscribeClickHandler}
                    item={item}
                    lunchOrderClick={this.lunchOrderClickHandler}
                    childTopUpClick={this.childTopUpHandler}
                    nutritionClick={this.childNutritionHandler}
                    transactionHistoryClick={this.childTransactionHistory}
                  />
                </div>
              )
            })}
          </Accordion>
        </div>,
      )
    }

    if (showloader) {
      contentDom.push(<Loader key="loader" fitContent="full" />)
    } else {
      contentDom.push(
        <div className="home">
          <TitleDescription
            title={t('children')}
            description={t('children_subtext')}
          />

          {childrenDom}

          <div className="home__button">
            <Button
              size="small"
              text={t('add_child')}
              onClick={() => this.addChildHandler()}
            />
          </div>
        </div>,
      )
    }

    const DOM =
      userRole === _userRoles.FACULTY ? (
        ''
      ) : (
        <div className="home_container">{contentDom}</div>
      )
    return DOM
  }
}

const mapStateToProps = state => {
  return {
    childData: state.child,
    userData: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getChildList: data =>
      dispatch({
        type: ACTION_TYPE_CHILD.GET_CHILD_LIST,
        data,
      }),
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    addAlert: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
    deleteAlert: id =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.DELETE_ALERT,
        id,
      }),
  }
}

export const Home = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeTrans)),
)
