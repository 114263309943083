import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import { withTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { Loader } from 'yacob-components'

import {
  ROUTES,
  REFRESH_TOKEN,
  USER_DATA,
  GA_ID,
} from './constants/app-constants'

import { ACTION_TYPE_SESSION, ACTION_TYPE_PROGRESSTRACK } from './redux/actions'

import { LocalStorage } from './utils/service'
import { AlertMessage, Header } from './containers'

const refreshToken = LocalStorage.getUserData(REFRESH_TOKEN)

const containersNames = [
  'Home',
  'Login',
  'Signup',
  'ResetPassword',
  'DeleteChildren',
  'ChangePassword',
  'LunchOrder',
  'SpendingLimits',
  'EarnCredit',
  'LunchSubscribe',
  'ChildTopup',
  'AccountTopup',
  'Nutrition',
  'ContactDetails',
  'Notification',
  'TransactionHistory',
  'ChildTransactionHistory',
  'ReportLostCard',
  'SchoolInfo',
  'SchoolSelect',
]

const pages = containersNames.reduce(
  (pagesMap, containerName) => ({
    ...pagesMap,
    [containerName + 'Page']: Loadable({
      loader: () =>
        import('./containers').then(module => module[containerName]),
      loading: Loader,
      delay: 300,
      timeout: 8000,
    }),
  }),
  {},
)

class RouterTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      failedApiList: [],
      alertMessage: [],
      isLoggedUserPage: this.isLoggedUserPage(),
    }
    this.firstApiFailCalled = true
    this.loadInitialPage()
  }

  componentDidMount() {
    const { history } = this.props
    if (history.location.pathname.indexOf(ROUTES.SIGNUP) > -1)
      this.redirectAddChild(history.location.pathname)
  }

  componentWillReceiveProps(nextProps) {
    const { sessionData, location } = this.props
    if (location.pathname !== nextProps.location.pathname) {
      this.reactGAPageview(nextProps.history.location.pathname)
    }
    this.isLoggedIn()
    if (
      nextProps.sessionData &&
      nextProps.sessionData.isTokenExpired !== sessionData.isTokenExpired &&
      this.firstApiFailCalled
    ) {
      this.getRefreshTokenHAndler()
    }

    if (
      nextProps.sessionData &&
      nextProps.sessionData.failedApiList !== sessionData.failedApiList
    ) {
      this.failedApiListHandler(nextProps.sessionData.failedApiList)
    }

    if (
      nextProps.sessionData &&
      nextProps.sessionData.refreshToken &&
      nextProps.sessionData.refreshToken !== sessionData.refreshToken
    ) {
      if (
        !nextProps.sessionData.refreshToken.error &&
        !nextProps.sessionData.refreshToken.tokenExpired
      )
        this.refreshTokenSuccess()
      else this.refreshTokenFailure()
    }
  }

  redirectAddChild = pathname => {
    const { location } = this.props
    const { state } = location
    let notFirstStep = false
    // FIXME
    if (!(state && state.isEdit)) {
      switch (pathname) {
        case '/signup/physical':
          notFirstStep = true
          break
        case '/signup/diet':
          notFirstStep = true
          break
        case '/signup/allergy':
          notFirstStep = true
          break
        case '/signup/success':
          notFirstStep = true
          break
        default:
          notFirstStep = false
      }
    }
    if (notFirstStep) {
      const { history } = this.props
      history.push(`${ROUTES.SIGNUP}/profile`)
    }
  }

  getRefreshTokenHAndler = () => {
    const { getRefreshToken } = this.props
    const payload = {
      refresh_token: refreshToken,
    }
    this.firstApiFailCalled = false
    getRefreshToken(payload)
  }

  failedApiListHandler = data => {
    const { failedApiList } = this.state
    const failedApiListCopy = [...failedApiList]
    const failedApiListProps = data.action
    failedApiListCopy.push(failedApiListProps)
    this.setState({ failedApiList: failedApiListCopy })
  }

  refreshTokenSuccess = () => {
    const { failedApiList } = this.state
    const { failedApiDispatch } = this.props
    failedApiList.map(data => failedApiDispatch(data))
    this.setState({ failedApiList: [] })
  }

  refreshTokenFailure = () => {
    const { history, addAlert } = this.props
    addAlert({
      type: 'error',
      content: 'Session timeout please login again',
    })
    LocalStorage.clearSessionData()
    history.push(ROUTES.LOGIN)
  }

  initializeReactGA = url => {
    ReactGA.initialize(GA_ID)
    this.reactGAPageview(url)
  }

  reactGAPageview = url => {
    ReactGA.pageview(url)
  }

  loadInitialPage = () => {
    const { history, location } = this.props
    // FIXME
    if (
      LocalStorage.getUserData(USER_DATA) &&
      location.pathname === ROUTES.LOGIN
    ) {
      this.initializeReactGA(ROUTES.HOME)
      history.replace(ROUTES.HOME)
    } else if (
      !LocalStorage.getUserData(USER_DATA) &&
      location.pathname === ROUTES.HOME
    ) {
      this.initializeReactGA(ROUTES.LOGIN)
      history.replace(ROUTES.LOGIN)
    } else {
      this.initializeReactGA(location.pathname)
    }
  }

  isLoggedUserPage = () => {
    const { location } = this.props
    if (
      location.pathname.indexOf(ROUTES.LOGIN) > -1 ||
      location.pathname === ROUTES.SIGNUP ||
      location.pathname.indexOf(ROUTES.RESET_PASSWORD) > -1 ||
      !LocalStorage.getData(USER_DATA)
    ) {
      LocalStorage.clearSessionData()
      return false
    }
    if (
      LocalStorage.getData(USER_DATA) &&
      LocalStorage.getData(USER_DATA).uuid
    ) {
      return true
    }
    return false
  }

  isLoggedIn = () => {
    const { history } = this.props

    if (
      history.action === 'POP' &&
      history.location.pathname === ROUTES.LOGIN &&
      LocalStorage.getUserData(USER_DATA)
    ) {
      history.replace(ROUTES.HOME)
    } else if (
      history.action === 'POP' &&
      history.location.pathname === ROUTES.HOME &&
      !LocalStorage.getUserData(USER_DATA)
    ) {
      history.replace(ROUTES.LOGIN)
    }
  }

  onChange = e => {
    const { i18n } = this.props
    i18n.changeLanguage(e.target.value)
  }

  render() {
    const { isLoggedUserPage } = this.state
    const { deleteAlert, progressTrackData } = this.props
    const { alerts } = progressTrackData

    const DOM = (
      <div className="app">
        <div className="alertsWrapper">
          {alerts.map(props => {
            const { id } = props
            return (
              <AlertMessage
                {...{
                  ...props,
                  key: id,
                  destroy: () => deleteAlert(id),
                }}
              />
            )
          })}
        </div>
        <Header isLoggedIn={isLoggedUserPage} />

        <Switch>
          {[
            {
              path: ROUTES.LOGIN,
              component: pages.LoginPage,
              exact: true,
            },
            {
              path: ROUTES.SIGNUP,
              component: pages.SignupPage,
              exact: true,
            },
            {
              path: `${ROUTES.SCHOOL_INFO}/:schoolCode`,
              component: pages.SchoolInfoPage,
            },
            {
              path: `${ROUTES.SIGNUP}/:progressKey`,
              component: pages.SignupPage,
            },
            {
              path: ROUTES.RESET_PASSWORD,
              component: pages.ResetPasswordPage,
              exact: true,
            },
            {
              path: ROUTES.DELETE_CHILDREN,
              component: pages.DeleteChildrenPage,
              exact: true,
            },
            {
              path: ROUTES.HOME,
              component: pages.HomePage,
              exact: true,
            },
            {
              path: ROUTES.CHANGE_PASSWORD,
              component: pages.ChangePasswordPage,
            },
            {
              path: ROUTES.LUNCH_ORDER,
              component: pages.LunchOrderPage,
            },
            {
              path: `${ROUTES.LUNCH_ORDER}/:progressKey`,
              component: pages.LunchOrderPage,
            },
            {
              path: ROUTES.SPENDING_LIMITS,
              component: pages.SpendingLimitsPage,
            },
            {
              path: ROUTES.EARN_CREDIT,
              component: pages.EarnCreditPage,
            },
            {
              path: ROUTES.LUNCH_SUBSCRIBE,
              component: pages.LunchSubscribePage,
            },
            {
              path: ROUTES.CHILD_TOPUP,
              component: pages.ChildTopupPage,
            },
            {
              path: ROUTES.ACCOUNT_TOPUP,
              component: pages.AccountTopupPage,
            },
            {
              path: `${ROUTES.ACCOUNT_TOPUP}/:progressKey`,
              component: pages.AccountTopupPage,
            },
            {
              path: ROUTES.NUTRITION,
              component: pages.NutritionPage,
            },
            {
              path: ROUTES.CONTACT_DETAILS,
              component: pages.ContactDetailsPage,
            },
            {
              path: ROUTES.NOTIFICATION,
              component: pages.NotificationPage,
            },
            {
              path: ROUTES.TRANSACTION_HISTORY,
              component: pages.TransactionHistoryPage,
            },
            {
              path: ROUTES.CHILD_TRANSACTION_HISTORY,
              component: pages.ChildTransactionHistoryPage,
            },
            {
              path: ROUTES.REPORT_CARD_LOST,
              component: pages.ReportLostCardPage,
            },
          ].map(props => (
            <Route {...props} />
          ))}
          <Route
            path={ROUTES.SCHOOL_SELECT}
            component={pages.SchoolSelectPage}
          />
        </Switch>
        {/* <input type='radio' name='lang' value='en' onChange={this.onChange} />
        <input type='radio' name='lang' value='ar' onChange={this.onChange} /> */}
      </div>
    )
    return DOM
  }
}

const mapStateToProps = state => {
  return {
    sessionData: state.session,
    progressTrackData: state.progressTrack,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addAlert: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
    deleteAlert: id =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.DELETE_ALERT,
        id,
      }),
    getRefreshToken: data =>
      dispatch({
        type: ACTION_TYPE_SESSION.GET_REFRESH_TOKEN,
        data,
      }),
    failedApiDispatch: data =>
      dispatch({
        type: data.type,
        data: data.data,
      }),
  }
}
export const Router = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RouterTrans)),
)
