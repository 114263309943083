import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-datepicker/dist/react-datepicker.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'

import { configureStore } from './redux/store'
import { Router } from './router'
import i18n from './i18n'

import './index.scss'

const store = configureStore()

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <Route path="/" component={Router} />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
)
