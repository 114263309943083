import { ACTION_TYPE_SIGNUP } from '../actions'
import { LocalStorage } from '../../utils/service'
import { USER_DATA } from '../../constants/app-constants'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  schoolListData: null,
  gradeListData: null,
  createData: null,
  sectionListData: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_SIGNUP.GET_SIGNUP:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SIGNUP.GET_SIGNUP_SUCCESS:
      if (action.response && action.response.faculty) {
        action.response.user =
          action.response.faculty && action.response.faculty.user
        action.response.user.user_uuid = action.response.faculty.user_uuid
        action.response.user.school_uuid = action.response.faculty.school_uuid
        action.response.user.uuid = action.response.faculty.uuid
        LocalStorage.setData(USER_DATA, action.response.user)
      }
      return {
        ...state,
        fetching: false,
        data: action.response,
      }

    case ACTION_TYPE_SIGNUP.GET_SIGNUP_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL_SUCCESS:
      return {
        ...state,
        fetching: false,
        schoolListData: action.response,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL_ERROR:
      return {
        ...state,
        fetching: false,
        schoolListData: null,
        error: action.error,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_GRADE:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_GRADE_SUCCESS:
      return {
        ...state,
        fetching: false,
        gradeListData: action.response,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_GRADE_ERROR:
      return {
        ...state,
        fetching: false,
        gradeListData: null,
        error: action.error,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_CREATE:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        createData: action.response,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_CREATE_ERROR:
      return {
        ...state,
        fetching: false,
        createData: null,
        error: action.error,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_SECTION:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_SECTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        sectionListData: action.response,
      }

    case ACTION_TYPE_SIGNUP.GET_ADDCHILD_SECTION_ERROR:
      return {
        ...state,
        fetching: false,
        sectionListData: null,
        error: action.error,
      }

    default:
      return state
  }
}
