import { ACTION_TYPE_EARNCREDIT } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  referralHistory: null,
  referralUrl: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_INVITATIONS:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_INVITATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_INVITATIONS_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_HISTORY:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        referralHistory: action.response,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_HISTORY_ERROR:
      return {
        ...state,
        fetching: false,
        referralHistory: null,
        error: action.error,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_URL:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_URL_SUCCESS:
      return {
        ...state,
        fetching: false,
        referralUrl: action.response,
      }

    case ACTION_TYPE_EARNCREDIT.GET_REFERRAL_URL_ERROR:
      return {
        ...state,
        fetching: false,
        referralUrl: null,
        error: action.error,
      }

    default:
      return state
  }
}
