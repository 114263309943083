import { ACTION_TYPE_USER } from '../actions'
import { LocalStorage } from '../../utils/service'
import { USER_DATA } from '../../constants/app-constants'

const initialState = {
  data: null,
  fetching: false,
  error: {},
  updateUser: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_USER.GET_USER:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_USER.GET_USER_SUCCESS:
      if (action.response && action.response.faculty) {
        action.response.user =
          action.response.faculty && action.response.faculty.user
        action.response.user.user_uuid = action.response.faculty.user_uuid
        action.response.user.school_uuid = action.response.faculty.school_uuid
        action.response.user.uuid = action.response.faculty.uuid
      }
      return {
        ...state,
        fetching: false,
        data: !action.response.error ? action.response.user : action.response,
      }

    case ACTION_TYPE_USER.GET_USER_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_USER.GET_USER_UPDATE:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_USER.GET_USER_UPDATE_SUCCESS:
      if (action.response && action.response.faculty) {
        action.response.user =
          action.response.faculty && action.response.faculty.user
        action.response.user.user_uuid = action.response.faculty.user_uuid
        action.response.user.school_uuid = action.response.faculty.school_uuid
        action.response.user.uuid = action.response.faculty.uuid
      }
      if (action.response && action.response.user) {
        LocalStorage.setData(USER_DATA, action.response.user)
      }
      return {
        ...state,
        fetching: false,
        updateUser: !action.response.error
          ? action.response.user
          : action.response,
      }

    case ACTION_TYPE_USER.GET_USER_UPDATE_ERROR:
      return {
        ...state,
        fetching: false,
        updateUser: null,
        error: action.error,
      }

    default:
      return state
  }
}
