import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_SPENDINGLIMITS, ACTION_TYPE_SESSION } from '../actions'

import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getSpendingLimits(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.SPENDING_LIMITS}?child_uuid=${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_ERROR,
      error,
    })
  }
}

function* addSpendingLimits(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.SPENDING_LIMITS}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SPENDINGLIMITS.ADD_SPENDING_LIMITS_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_SPENDINGLIMITS.ADD_SPENDING_LIMITS_ERROR,
      error,
    })
  }
}

function* editSpendingLimits(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.SPENDING_LIMITS}${action.data.uuid}`
    const response = yield call(fetchCall, url, 'PUT', action.data.payload)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SPENDINGLIMITS.EDIT_SPENDING_LIMITS_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_SPENDINGLIMITS.EDIT_SPENDING_LIMITS_ERROR,
      error,
    })
  }
}

function* getSpendingLimitsDefaults(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.SPENDING_LIMITS_DEFAULTS}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_DEFAULTS_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_DEFAULTS_ERROR,
      error,
    })
  }
}

export function* watchSpendingLimitsSaga() {
  yield takeLatest(
    ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS,
    getSpendingLimits,
  )
  yield takeLatest(
    ACTION_TYPE_SPENDINGLIMITS.ADD_SPENDING_LIMITS,
    addSpendingLimits,
  )
  yield takeLatest(
    ACTION_TYPE_SPENDINGLIMITS.EDIT_SPENDING_LIMITS,
    editSpendingLimits,
  )
  yield takeLatest(
    ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_DEFAULTS,
    getSpendingLimitsDefaults,
  )
}
const spendingLimitsSaga = [fork(watchSpendingLimitsSaga)]
export default spendingLimitsSaga
