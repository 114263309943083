import './Signup.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  Carousel,
  SignupCreateAccount,
  SignupAddChildProfile,
  SignupAddChildPhysical,
  SignupAddChildDiet,
  SignupAddChildAllergy,
  SignupAddChildSuccess,
} from 'yacob-components'

import SignupIcon from '../../assets/pipe.svg'

import { ROUTES as _routes, USER_DATA } from '../../constants/app-constants'
import {
  ACTION_TYPE_PROGRESSTRACK,
  ACTION_TYPE_SIGNUP,
  ACTION_TYPE_LOGIN,
  ACTION_TYPE_CHILD,
} from '../../redux/actions'
import { LocalStorage, getSelectedSchool } from '../../utils/service'

import moment from 'moment'

// TODO: Refactor this into reusable helper
const date = moment()
const year = date.year()

const flagName = `childInfoUpdatedFor${year}`

const carouselData = [
  {
    key: 0,
    title: 'Healthier Habits',
    description: 'Healthy school meals improve kids’ eating habits.',
  },
  {
    key: 1,
    title: 'Healthier Options',
    description: 'Today’s school meals offer healthier—not fewer—calories',
  },
  {
    key: 2,
    title: 'Direct Daily Benefits',
    description:
      'All students in participating schools have healthier food and drink choices because of these programs. ',
  },
]

class SignupBase extends Component {
  constructor(props) {
    super(props)

    const selectedSchool = getSelectedSchool()

    this.state = {
      currentProgress: 'create an account',
      bgClassName: 'create',
      addChildsuccess: '',
      currentPath: '',
      progressData: [
        {
          key: 'profile',
          status: 'active',
          displayName: 'profile',
          index: 0,
          route: `${_routes.SIGNUP}/profile`,
        },
        {
          key: 'physical',
          status: '',
          displayName: 'physical_information',
          index: 1,
          route: `${_routes.SIGNUP}/physical`,
        },
        {
          key: 'diet',
          status: '',
          displayName: 'diet',
          index: 2,
          route: `${_routes.SIGNUP}/diet`,
        },
        {
          key: 'allergy',
          status: '',
          displayName: 'allergies',
          index: 3,
          route: `${_routes.SIGNUP}/allergy`,
        },
      ],
      addChildSchoolList: [],
      addChildGradeList: [],
      addChildSectionList: [],
      addChildDietList: [],
      addChildAllergyList: [],
      signupData: {},
      addChildData: null,
      addChildProfile: selectedSchool
        ? {
            school: selectedSchool.value,
            school_uuid: selectedSchool.uuid,
          }
        : {},
      addChildPhysical: {},
      addChildDiet: {},
      addChildAllergy: {},
      addChildAllergySelected: {},
      isEditable: false,
      editChildUuid: '',
      showloader: false,
      showbtnloader: false,
      referralCode: '',
      isFaculty: false,
      selectedSchool,
    }
    this.windowLength = props.history.length
  }

  componentDidMount() {
    const { history, location, getAddChildSchoolData } = this.props
    this.currentPath(history)
    const { selectedSchool } = this.state
    const { state } = location
    if (!selectedSchool) {
      this.historyPush(_routes.SCHOOL_SELECT)
    }
    if (state && state.isEdit) {
      const { data, progress, childuuid } = state
      this.setState({ isEditable: true })
      this.editChildDataHandler(data, progress, childuuid)
    } else {
      getAddChildSchoolData()
    }
  }

  componentWillReceiveProps(nextProps) {
    // TODO: Refactor to use callbacks/thunks and remove this
    const { signupData, childData } = this.props
    this.currentPath(nextProps.history)
    if (
      nextProps.signupData &&
      nextProps.signupData.data &&
      nextProps.signupData.data !== signupData.data
    ) {
      this.setState({ showloader: false })
      if (!nextProps.signupData.data.error) {
        this.signupSuccessHandler(nextProps.signupData.data)
      } else {
        this.alert({ type: 'error', content: nextProps.signupData.data.error })
      }
    }
    if (
      nextProps.signupData &&
      nextProps.signupData.schoolListData !== signupData.schoolListData &&
      nextProps.signupData.schoolListData.schools
    ) {
      this.setState({
        addChildSchoolList: nextProps.signupData.schoolListData.schools,
      })
    }
    if (
      nextProps.signupData &&
      nextProps.signupData.gradeListData &&
      nextProps.signupData.gradeListData !== signupData.gradeListData &&
      nextProps.signupData.gradeListData.grades
    ) {
      this.setState({
        addChildGradeList: nextProps.signupData.gradeListData.grades,
      })
    }

    if (
      nextProps.signupData &&
      nextProps.signupData.sectionListData &&
      nextProps.signupData.sectionListData !== signupData.sectionListData &&
      nextProps.signupData.sectionListData.sections
    ) {
      this.setState({
        addChildSectionList: nextProps.signupData.sectionListData.sections,
      })
    }

    if (
      nextProps.signupData &&
      nextProps.signupData.createData &&
      nextProps.signupData.createData !== signupData.createData
    ) {
      this.setState({ showbtnloader: false })
      if (!nextProps.signupData.createData.error) {
        this.addChildAllergy(nextProps.signupData.createData.child)
      } else {
        this.alert({
          type: 'error',
          content: nextProps.signupData.createData.error,
        })
      }
    }

    if (
      nextProps.childData &&
      nextProps.childData.allergyAdd !== childData.allergyAdd &&
      !nextProps.childData.allergyAdd.error
    ) {
      this.setState({ showbtnloader: false })
      this.addChildAllergySuccessHandler()
    }

    if (
      nextProps.childData &&
      nextProps.childData.dietList !== childData.dietList &&
      nextProps.childData.dietList.diets
    ) {
      this.setState({
        addChildDietList: nextProps.childData.dietList.diets,
        showloader: false,
      })
    }

    if (
      nextProps.childData &&
      nextProps.childData.allergyList !== childData.allergyList &&
      nextProps.childData.allergyList.allergies
    ) {
      this.setState({
        addChildAllergyList: nextProps.childData.allergyList.allergies,
        showloader: false,
      })
    }

    if (
      nextProps.childData &&
      nextProps.childData.editChild !== childData.editChild &&
      nextProps.childData.editChild.child &&
      nextProps.childData.editChild.child.uuid
    ) {
      this.setState({ showbtnloader: false })
      this.alert({ type: 'success', content: 'Updated Successfully' })
      this.historyPush(_routes.HOME)
    }

    if (
      nextProps.childData &&
      nextProps.childData.allergyChild !== childData.allergyChild &&
      nextProps.childData.allergyChild.child_allergies
    ) {
      this.setAllergySelection(nextProps.childData.allergyChild.child_allergies)
    }
  }

  currentPath = history => {
    const { currentPath } = this.state
    const { location } = this.props
    if (currentPath !== history.location.pathname) {
      if (history.location.pathname !== _routes.SIGNUP) {
        this.setState({ currentPath: history.location.pathname })
        const splitpath = history.location.pathname.split(`${_routes.SIGNUP}/`)
        if (!(location.state && location.state.isEdit))
          this.setProgressDataHandler(splitpath[1])
      } else if (history.location.pathname === _routes.SIGNUP) {
        const { search } = location
        let referralCode = ''
        if (search) {
          const splitSearch = location.search.split('=')
          // eslint-disable-next-line prefer-destructuring
          if (splitSearch[0] === '?referral-url') referralCode = splitSearch[1]
        }
        this.setState({
          currentProgress: 'create an account',
          bgClassName: 'create',
          currentPath: history.location.pathname,
          referralCode,
        })
      }
    }
  }

  historyPush = path => {
    const { history } = this.props
    history.push(path)
  }

  setProgressDataHandler = path => {
    const { progressData } = this.state
    const { setProgressData } = this.props
    let index = 0
    const scrollElement =
      document && document.getElementById('signup_container')
    if (scrollElement) scrollElement.scrollTop = 0
    if (path === 'success') {
      this.setState({
        currentProgress: 'add child success',
        addChildsuccess: 'success',
        bgClassName: 'success',
      })
      setProgressData({}, false, index)
      return
    }
    Object.keys(progressData).forEach(e => {
      if (progressData[e].key === path) {
        progressData[e].status = 'active'
        index = e
      }
    })
    Object.keys(progressData).forEach(e => {
      if (e > index) {
        progressData[e].status = ''
      } else if (e < index) {
        progressData[e].status = 'done'
      }
    })
    this.setState({
      currentProgress: `add child ${path}`,
      bgClassName: `addchild_${path}`,
      addChildsuccess: '',
    })
    setProgressData(progressData, true, index)
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  signupSuccessHandler = apidata => {
    const { setLogin } = this.props
    const { signupData, isFaculty, selectedSchool } = this.state
    const data = {
      isFaculty,
      payload: {
        email: signupData.email,
        password: signupData.password,
      },
    }
    setLogin(data, response => {
      if (response && response.access_token) {
        this.setState({ signupData: apidata })
        if (isFaculty || selectedSchool.status !== 'ACTIVE') {
          this.historyPush(`${_routes.SCHOOL_INFO}/${selectedSchool.code}`)
        } else {
          this.historyPush(`${_routes.SIGNUP}/profile`)
        }
      }
    })
  }

  setAllergySelection = allergiesArray => {
    const allergySelected = {}
    Object.keys(allergiesArray).forEach(data => {
      if (allergiesArray[data].allergy_custom) {
        const { addChildAllergy } = this.state
        addChildAllergy.allergy_custom = allergiesArray[data].allergy_custom
        this.setState({ addChildAllergy })
      } else {
        const allergyuuid = allergiesArray[data].allergy_uuid
        const allergylevel = allergiesArray[data].allergy_level
        allergySelected[allergyuuid] = allergylevel
      }
    })
    this.setState({ addChildAllergySelected: allergySelected })
  }

  addChildAllergySuccessHandler = () => {
    const { setProgressData } = this.props
    const { isEditable } = this.state
    setProgressData({}, false, 0)
    if (isEditable) {
      this.alert({ type: 'success', content: 'Updated Successfully' })
      this.historyPush(_routes.HOME)
    }
  }

  addChildCreate = payload => {
    const { getAddChildCreateData } = this.props

    // FIXME: Use store for user data
    const useruuid =
      LocalStorage.getUserData(USER_DATA) &&
      LocalStorage.getUserData(USER_DATA).uuid

    this.setState({ showbtnloader: true })
    getAddChildCreateData(
      {
        parent_uuid: useruuid,
        ...payload,
      },
      response => {
        if (response && response.child && response.child.uuid) {
          this.historyPush(_routes.HOME)
        }
      },
    )
  }

  addChildAllergy = createdData => {
    const { addChildAllergy } = this.state
    const { getChildAllergyAddData } = this.props
    const addChildAllergyCopy = addChildAllergy
    addChildAllergyCopy.child_uuid = createdData.uuid
    this.setState({ addChildData: createdData })
    getChildAllergyAddData(addChildAllergyCopy)
  }

  onClickCreateAccount = payload => {
    const { setSignupData } = this.props
    const { isFaculty, selectedSchool } = this.state
    this.setState({ signupData: payload, showloader: true })
    const data = {
      payload: {
        ...payload,
        school_uuid: selectedSchool.uuid,
      },
      isFaculty,
    }
    setSignupData(data)
  }

  onClickAddChildProfile = data => {
    const { isEditable } = this.state
    this.setState({ addChildProfile: data })
    //
    localStorage.setItem(flagName, true)
    // TODO: Fix data vars case to camelCase
    const payload = {
      first_name: data.firstname,
      last_name: data.lastname,
      date_of_birth: moment(data.date_of_birth).format('MM-DD-YYYY'),
      grade_uuid: data.gradeuuid,
      school_uuid: data.schooluuid,
      section_uuid: data.sectionuuid,
      card_id: data.studentId,
    }
    if (isEditable) {
      this.setState({ showbtnloader: true })
      this.editChildClickHandler(payload)
    } else {
      this.setState({ addChildProfile: data })
      this.addChildCreate(payload)
    }
  }

  onClickAddChildPhysical = data => {
    const { isEditable } = this.state
    this.setState({ addChildPhysical: data })
    if (isEditable) {
      const payload = {
        height_in_cms: Number(data.height),
        weight_in_kgs: Number(data.weight),
        blood_group: data.bloodgroup,
      }
      this.setState({ showbtnloader: true })
      this.editChildClickHandler(payload)
    }
  }

  onClickAddChildDiet = data => {
    const { isEditable } = this.state
    this.setState({ addChildDiet: data })

    if (isEditable) {
      const payload = {
        diet_custom: data.diet_custom,
        diet_uuid: data.diet_uuid,
      }
      this.setState({ showbtnloader: true })
      this.editChildClickHandler(payload)
    }
  }

  onClickAddChildAllergy = (payload, data) => {
    const { isEditable } = this.state
    this.setState({ showbtnloader: true })
    if (isEditable) {
      const { getChildAllergyAddData } = this.props
      const { editChildUuid } = this.state
      payload.child_uuid = editChildUuid
      getChildAllergyAddData(payload)
    }
  }

  editChildDataHandler = (data, progress, childuuid) => {
    if (progress === 'Diet') {
      const { getChildDietData } = this.props
      this.setState({ showloader: true })
      getChildDietData()
    } else if (progress === 'Allergy') {
      const { getChildAllergyListData } = this.props
      this.setState({ showloader: true })
      getChildAllergyListData()
      if (data.has_allergies) {
        const { getChildAllergyData } = this.props
        getChildAllergyData(childuuid)
      }
    } else if (progress === 'Profile') {
      const {
        getAddChildSchoolData,
        getAddChildGradeData,
        getAddChildSectionData,
      } = this.props
      getAddChildSchoolData()
      getAddChildGradeData(data.schooluuid)
      getAddChildSectionData(data.gradeuuid)
    }
    this.setState({
      editChildUuid: childuuid,
      currentProgress: `add child ${progress.toLowerCase()}`,
      bgClassName: `addchild_${progress.toLowerCase()}`,
      [`addChild${progress}`]: data,
    })
  }

  editChildClickHandler = payload => {
    const { editChild } = this.props
    const { editChildUuid } = this.state
    const data = {
      uuid: editChildUuid,
      payload,
    }
    editChild(data)
  }

  topupChildrenHandler = () => {
    const { addChildData } = this.state
    const path = {
      pathname: _routes.CHILD_TOPUP,
      state: { data: addChildData },
    }
    this.historyPush(path)
  }

  continueToChildrenClick = () => {
    // TODO: Remove
    const { history } = this.props
    history.go(this.windowLength - history.length - 1)
    setTimeout(() => {
      history.push(_routes.HOME)
    }, 10)
  }

  clearGradeSectionValueHandler = () => {
    this.setState({ addChildGradeList: [], addChildSectionList: [] })
  }

  onFacultyChangeHandler = () => {
    this.setState({ isFaculty: true })
    const { getAddChildSchoolData } = this.props
    getAddChildSchoolData()
  }

  renderContent = () => {
    const {
      currentProgress,
      isEditable,
      showloader,
      showbtnloader,
      referralCode,
      addChildSectionList,
      addChildProfile,
      addChildPhysical,
      addChildDiet,
      addChildAllergySelected,
      addChildSchoolList,
      addChildGradeList,
      addChildDietList,
      addChildAllergyList,
      addChildAllergy,
      addChildData,
    } = this.state
    const { getAddChildGradeData, getAddChildSectionData } = this.props
    let dom
    switch (currentProgress) {
      case 'create an account':
        dom = (
          <SignupCreateAccount
            alert={this.alert}
            schoolList={addChildSchoolList}
            onFacultyChange={this.onFacultyChangeHandler}
            showloader={showloader}
            onClickCreateAccount={this.onClickCreateAccount}
            referralCode={referralCode}
          />
        )
        break
      case 'add child profile':
        dom = (
          <SignupAddChildProfile
            isEditable={isEditable}
            data={addChildProfile}
            onClickAddChildProfile={this.onClickAddChildProfile}
            showbtnloader={showbtnloader}
            addChildSchoolList={addChildSchoolList}
            addChildGradeList={addChildGradeList}
            getAddChildGradeData={getAddChildGradeData}
            getAddChildSectionData={getAddChildSectionData}
            addChildSectionList={addChildSectionList}
            clearGradeSectionValue={this.clearGradeSectionValueHandler}
          />
        )
        break
      case 'add child physical':
        dom = (
          <SignupAddChildPhysical
            isEditable={isEditable}
            data={addChildPhysical}
            onClickAddChildPhysical={this.onClickAddChildPhysical}
            showbtnloader={showbtnloader}
          />
        )
        break
      case 'add child diet':
        dom = (
          <SignupAddChildDiet
            isEditable={isEditable}
            data={addChildDiet}
            onClickAddChildDiet={this.onClickAddChildDiet}
            addChildDietList={addChildDietList}
            showloader={showloader}
            showbtnloader={showbtnloader}
          />
        )
        break
      case 'add child allergy':
        dom = (
          <SignupAddChildAllergy
            isEditable={isEditable}
            data={addChildAllergy}
            allergySelected={addChildAllergySelected}
            onClickAddChildAllergy={this.onClickAddChildAllergy}
            addChildAllergyList={addChildAllergyList}
            showloader={showloader}
            showbtnloader={showbtnloader}
          />
        )
        break
      case 'add child success':
        // TODO: Remove
        dom = (
          <SignupAddChildSuccess
            addChildData={addChildData}
            topupChildren={this.topupChildrenHandler}
            continueToChildrenClick={this.continueToChildrenClick}
          />
        )
        break
      default:
        dom = null
    }
    return dom
  }

  render() {
    const { bgClassName, addChildsuccess } = this.state

    const DOM = (
      <div className="signup_container">
        <div className={`signup ${addChildsuccess}`} id="signup_container">
          {bgClassName === 'create' && (
            <div className="u_mobile_display_block signup_carousel">
              <div className="signup_carousel_icon">
                <img src={SignupIcon} alt="pipe" />
              </div>
              <Carousel carouselData={carouselData} />
            </div>
          )}
          {this.renderContent()}
          <div
            className={`u_mobile_display_none signup_carousel ${bgClassName}`}
          >
            {bgClassName === 'create' && (
              <>
                <Carousel carouselData={carouselData} />
                {/* <div className="signup_carousel_imageshadow" /> */}
              </>
            )}
          </div>
        </div>
      </div>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    progressTrackData: state.progressTrack,
    signupData: state.signup,
    childData: state.child,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setSignupData: data =>
      dispatch({
        type: ACTION_TYPE_SIGNUP.GET_SIGNUP,
        data,
      }),
    setLogin: (data, callback) =>
      dispatch({
        type: ACTION_TYPE_LOGIN.GET_LOGIN,
        data,
        callback,
      }),
    getAddChildSchoolData: () =>
      dispatch({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL,
      }),
    getAddChildGradeData: data =>
      dispatch({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_GRADE,
        data,
      }),
    getAddChildSectionData: data =>
      dispatch({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_SECTION,
        data,
      }),
    getChildDietData: () =>
      dispatch({
        type: ACTION_TYPE_CHILD.GET_CHILD_DIET,
      }),
    getChildAllergyListData: () =>
      dispatch({
        type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_LIST,
      }),
    getChildAllergyData: data =>
      dispatch({
        type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY,
        data,
      }),
    getAddChildCreateData: (data, callback) =>
      dispatch({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_CREATE,
        data,
        callback,
      }),
    getChildAllergyAddData: data =>
      dispatch({
        type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ADD,
        data,
      }),
    editChild: data =>
      dispatch({
        type: ACTION_TYPE_CHILD.EDIT_CHILD,
        data,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const Signup = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignupBase),
)
