import { ACTION_TYPE_NOTIFICATION } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  setNotificationData: null,
}

export default function lunchOrderReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_NOTIFICATION.GET_NOTIFICATION_LIST:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_NOTIFICATION.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response,
      }

    case ACTION_TYPE_NOTIFICATION.GET_NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_NOTIFICATION.SET_NOTIFICATION_LIST:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_NOTIFICATION.SET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        setNotificationData: action.response,
      }

    case ACTION_TYPE_NOTIFICATION.SET_NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        setNotificationData: null,
        error: action.error,
      }

    default:
      return state
  }
}
