import { all } from 'redux-saga/effects'

import loginSaga from './login'
import signupSaga from './signup'
import childSaga from './child'
import sessionSaga from './session'
import spendingLimitsSaga from './spendingLimits'
import lunchOrderSaga from './lunchOrder'
import topupSaga from './topup'
import userSaga from './user'
import nutritionSaga from './nutrition'
import earnCreditSaga from './earnCredits'
import notificationSaga from './notification'
import preAuthDetailSaga from './pre-auth-details'

export function* rootSaga() {
  yield all([
    ...loginSaga,
    ...signupSaga,
    ...sessionSaga,
    ...childSaga,
    ...spendingLimitsSaga,
    ...lunchOrderSaga,
    ...topupSaga,
    ...userSaga,
    ...nutritionSaga,
    ...earnCreditSaga,
    ...notificationSaga,
    ...preAuthDetailSaga,
  ])
}
