import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_NOTIFICATION, ACTION_TYPE_SESSION } from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getNotificationListSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.NOTIFICATION}${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_NOTIFICATION.GET_NOTIFICATION_LIST_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_NOTIFICATION.GET_NOTIFICATION_LIST_ERROR,
      error,
    })
  }
}

function* setNotificationSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.NOTIFICATION}${action.data.uuid}`
    const response = yield call(fetchCall, url, 'POST', action.data.payload)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_NOTIFICATION.SET_NOTIFICATION_LIST_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_NOTIFICATION.SET_NOTIFICATION_LIST_ERROR,
      error,
    })
  }
}

export function* watchNotificationSaga() {
  yield takeLatest(
    ACTION_TYPE_NOTIFICATION.GET_NOTIFICATION_LIST,
    getNotificationListSaga,
  )
  yield takeLatest(
    ACTION_TYPE_NOTIFICATION.SET_NOTIFICATION_LIST,
    setNotificationSaga,
  )
}
const notificationSaga = [fork(watchNotificationSaga)]
export default notificationSaga
