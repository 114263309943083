export const ACTION_TYPE_LUNCHORDER = {
  GET_MENU_LIST: 'GET_MENU_LIST',
  GET_MENU_LIST_SUCCESS: 'GET_MENU_LIST_SUCCESS',
  GET_MENU_LIST_ERROR: 'GET_MENU_LIST_ERROR',

  ADD_CART_CHECKOUT: 'ADD_CART_CHECKOUT',
  ADD_CART_CHECKOUT_SUCCESS: 'ADD_CART_CHECKOUT_SUCCESS',
  ADD_CART_CHECKOUT_ERROR: 'ADD_CART_CHECKOUT_ERROR',

  CART_CHECKOUT_VERIFY: 'CART_CHECKOUT_VERIFY',
  CART_CHECKOUT_VERIFY_SUCCESS: 'CART_CHECKOUT_VERIFY_SUCCESS',
  CART_CHECKOUT_VERIFY_ERROR: 'CART_CHECKOUT_VERIFY_ERROR',
}
