import { ACTION_TYPE_NUTRITION } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  itemData: {},
  insightAdvData: {},
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_NUTRITION.GET_NUTRITION_CONSUMPTION:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_CONSUMPTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_CONSUMPTION_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_ITEM:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_ITEM_SUCCESS:
      return {
        ...state,
        fetching: false,
        itemData: action.response,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_ITEM_ERROR:
      return {
        ...state,
        fetching: false,
        itemData: null,
        error: action.error,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_INSIGHT_ADVICE:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_INSIGHT_ADVICE_SUCCESS:
      return {
        ...state,
        fetching: false,
        insightAdvData: action.response,
      }

    case ACTION_TYPE_NUTRITION.GET_NUTRITION_INSIGHT_ADVICE_ERROR:
      return {
        ...state,
        fetching: false,
        insightAdvData: null,
        error: action.error,
      }

    default:
      return state
  }
}
