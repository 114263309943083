import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_NUTRITION, ACTION_TYPE_SESSION } from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getnutritionConsumptionSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.NUTRITION_CONSUMPTION}${action.data.uuid}?days_limit=${action.data.days_limit}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_NUTRITION.GET_NUTRITION_CONSUMPTION_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_NUTRITION.GET_NUTRITION_CONSUMPTION_ERROR,
      error,
    })
  }
}

function* getnutritionItemSaga(action) {
  try {
    const { data } = action
    const { uuid, page, limit, sort } = data
    let endpoint = ''
    endpoint += page ? `page=${page}&` : ''
    endpoint += limit ? `limit=${limit}&` : ''
    endpoint += sort ? `sort=${sort}` : ''
    const url = `${BASE_URL}${ENDPOINT.NUTRITION_ITEM}${uuid}?${endpoint}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const datatokenExpiry = true
      yield put({
        type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY,
        datatokenExpiry,
      })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_NUTRITION.GET_NUTRITION_ITEM_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_NUTRITION.GET_NUTRITION_ITEM_ERROR,
      error,
    })
  }
}

function* getnutritionInsightAdvSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.NUTRITION_INSIGNHT_ADVICE}${action.data.uuid}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_NUTRITION.GET_NUTRITION_INSIGHT_ADVICE_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_NUTRITION.GET_NUTRITION_INSIGHT_ADVICE_ERROR,
      error,
    })
  }
}

export function* watchNutritionSaga() {
  yield takeLatest(
    ACTION_TYPE_NUTRITION.GET_NUTRITION_CONSUMPTION,
    getnutritionConsumptionSaga,
  )
  yield takeLatest(
    ACTION_TYPE_NUTRITION.GET_NUTRITION_ITEM,
    getnutritionItemSaga,
  )
  yield takeLatest(
    ACTION_TYPE_NUTRITION.GET_NUTRITION_INSIGHT_ADVICE,
    getnutritionInsightAdvSaga,
  )
}
const nutritionSaga = [fork(watchNutritionSaga)]
export default nutritionSaga
