import { takeLatest, call, put, fork } from 'redux-saga/effects'

import {
  ACTION_TYPE_USER,
  ACTION_TYPE_LOGIN,
  ACTION_TYPE_SESSION,
} from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getuserSaga(action) {
  try {
    const { data } = action
    const { isFaculty, uuid } = data
    const url = `${BASE_URL}${
      isFaculty ? ENDPOINT.FACULTY_USER : ENDPOINT.USER
    }${uuid}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data1 = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data1 })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_USER.GET_USER_SUCCESS,
        response,
      })
      yield put({
        type: ACTION_TYPE_LOGIN.GET_LOGIN_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_USER.GET_USER_ERROR,
      error,
    })
  }
}

function* getuserUpdateSaga(action) {
  try {
    const { data } = action
    const { uuid, payload, isFaculty } = data
    const url = `${BASE_URL}${
      isFaculty ? ENDPOINT.FACULTY_USER : ENDPOINT.USER
    }${uuid}`
    const response = yield call(fetchCall, url, 'PATCH', payload)
    if (response.tokenExpired) {
      const data1 = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data1 })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_USER.GET_USER_UPDATE_SUCCESS, response })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_USER.GET_USER_UPDATE_ERROR,
      error,
    })
  }
}

export function* watchUserSaga() {
  yield takeLatest(ACTION_TYPE_USER.GET_USER, getuserSaga)
  yield takeLatest(ACTION_TYPE_USER.GET_USER_UPDATE, getuserUpdateSaga)
}
const userSaga = [fork(watchUserSaga)]
export default userSaga
