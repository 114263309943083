export const ACTION_TYPE_CHILD = {
  GET_CHILD_ALLERGY_LIST: 'GET_CHILD_ALLERGY_LIST',
  GET_CHILD_ALLERGY_LIST_SUCCESS: 'GET_CHILD_ALLERGY_LIST_SUCCESS',
  GET_CHILD_ALLERGY_LIST_ERROR: 'GET_CHILD_ALLERGY_LIST_ERROR',

  GET_CHILD_ALLERGY: 'GET_CHILD_ALLERGY',
  GET_CHILD_ALLERGY_SUCCESS: 'GET_CHILD_ALLERGY_SUCCESS',
  GET_CHILD_ALLERGY_ERROR: 'GET_CHILD_ALLERGY_ERROR',

  GET_CHILD_ALLERGY_ADD: 'GET_CHILD_ALLERGY_ADD',
  GET_CHILD_ALLERGY_ADD_SUCCESS: 'GET_CHILD_ALLERGY_ADD_SUCCESS',
  GET_CHILD_ALLERGY_ADD_ERROR: 'GET_CHILD_ALLERGY_ADD_ERROR',

  GET_CHILD_DIET: 'GET_CHILD_DIET',
  GET_CHILD_DIET_SUCCESS: 'GET_CHILD_DIET_SUCCESS',
  GET_CHILD_DIET_ERROR: 'GET_CHILD_DIET_ERROR',

  GET_CHILD_DELETE: 'GET_CHILD_DELETE',
  GET_CHILD_DELETE_SUCCESS: 'GET_CHILD_DELETE_SUCCESS',
  GET_CHILD_DELETE_ERROR: 'GET_CHILD_DELETE_ERROR',

  GET_CHILD_LIST: 'GET_CHILD_LIST',
  GET_CHILD_LIST_SUCCESS: 'GET_CHILD_LIST_SUCCESS',
  GET_CHILD_LIST_ERROR: 'GET_CHILD_LIST_ERROR',

  EDIT_CHILD: 'EDIT_CHILD',
  EDIT_CHILD_SUCCESS: 'EDIT_CHILD_SUCCESS',
  EDIT_CHILD_ERROR: 'EDIT_CHILD_ERROR',

  REPORTING_CARD_LOST: 'REPORTING_CARD_LOST',
  REPORTING_CARD_LOST_SUCCESS: 'REPORTING_CARD_LOST_SUCCESS',
  REPORTING_CARD_LOST_ERROR: 'REPORTING_CARD_LOST_ERROR',
}
