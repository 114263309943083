import { ACTION_TYPE_TOPUP } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  verifyPayment: null,
  childdata: {},
  accountTransactionData: null,
  childTransactionData: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_TOPUP.GET_PARENT_TOPUP:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_TOPUP.GET_PARENT_TOPUP_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response,
      }

    case ACTION_TYPE_TOPUP.GET_PARENT_TOPUP_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_TOPUP.GET_VERIFY_PAYMENT:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_TOPUP.GET_VERIFY_PAYMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        verifyPayment: action.response,
      }

    case ACTION_TYPE_TOPUP.GET_VERIFY_PAYMENT_ERROR:
      return {
        ...state,
        fetching: false,
        verifyPayment: null,
        error: action.error,
      }

    case ACTION_TYPE_TOPUP.GET_CHILD_TOPUP:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_TOPUP.GET_CHILD_TOPUP_SUCCESS:
      return {
        ...state,
        fetching: false,
        childdata: action.response,
      }

    case ACTION_TYPE_TOPUP.GET_CHILD_TOPUP_ERROR:
      return {
        ...state,
        fetching: false,
        childdata: null,
        error: action.error,
      }

    case ACTION_TYPE_TOPUP.GET_ACCOUNT_TRANSACTION_HISTORY:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_TOPUP.GET_ACCOUNT_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        accountTransactionData: action.response,
      }

    case ACTION_TYPE_TOPUP.GET_ACCOUNT_TRANSACTION_HISTORY_ERROR:
      return {
        ...state,
        fetching: false,
        accountTransactionData: null,
        error: action.error,
      }

    case ACTION_TYPE_TOPUP.GET_CHILD_TRANSACTION_HISTORY:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_TOPUP.GET_CHILD_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        childTransactionData: action.response,
      }

    case ACTION_TYPE_TOPUP.GET_CHILD_TRANSACTION_HISTORY_ERROR:
      return {
        ...state,
        fetching: false,
        childTransactionData: null,
        error: action.error,
      }

    default:
      return state
  }
}
