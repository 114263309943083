import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER_DATA,
} from '../constants/app-constants'

const setValueToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getValueFromLocalStorage = key => {
  if (localStorage.getItem(key) !== 'undefined')
    return JSON.parse(localStorage.getItem(key))
  return null
}

const clearSessionData = () => {
  localStorage.removeItem('USERDATA')
  localStorage.removeItem('ATKN')
  localStorage.removeItem('RTKN')
}

const setUserToken = value => {
  setValueToLocalStorage(ACCESS_TOKEN, value.access_token)
  setValueToLocalStorage(REFRESH_TOKEN, value.refresh_token)
}

const getUserData = key => {
  return getValueFromLocalStorage(key)
}

const removeItem = key => {
  localStorage.removeItem(key)
}

const getCurrency = () => {
  return 'AED'
}

export const getSelectedSchool = () => {
  const selectedSchoolData = localStorage.getItem('selectedSchool')
  return (
    selectedSchoolData &&
    selectedSchoolData !== 'undefined' &&
    selectedSchoolData !== 'null' &&
    JSON.parse(selectedSchoolData)
  )
}

export const LocalStorage = {
  getUserData,
  setUserToken,
  getCurrency,
  setData: setValueToLocalStorage,
  getData: getValueFromLocalStorage,
  clearSessionData: clearSessionData,
  removeItem,
}
