import { ACTION_TYPE_LOGIN } from '../actions'
import { LocalStorage } from '../../utils/service'
import { USER_DATA } from '../../constants/app-constants'

const initialState = {
  data: LocalStorage.getUserData(USER_DATA)
    ? LocalStorage.getUserData(USER_DATA)
    : {},
  fetching: false,
  error: {},
  changePassword: null,
  forgotPassword: null,
  resetPassword: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_LOGIN.GET_LOGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_LOGIN.GET_LOGIN_SUCCESS:
      if (action.response && action.response.faculty) {
        action.response.user =
          action.response.faculty && action.response.faculty.user
        action.response.user.user_uuid = action.response.faculty.user_uuid
        action.response.user.school_uuid = action.response.faculty.school_uuid
        action.response.user.uuid = action.response.faculty.uuid
      }
      if (action.response && action.response.user) {
        LocalStorage.setData(USER_DATA, action.response.user)
      }
      return {
        ...state,
        fetching: false,
        data: !action.response.error ? action.response.user : action.response,
      }

    case ACTION_TYPE_LOGIN.GET_LOGIN_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_LOGIN.GET_CHANGE_PASSWORD:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_LOGIN.GET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
        changePassword: action.response,
      }

    case ACTION_TYPE_LOGIN.GET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        fetching: false,
        changePassword: null,
        error: action.error,
      }

    case ACTION_TYPE_LOGIN.GET_FORGOT_PASSWORD:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_LOGIN.GET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
        forgotPassword: action.response,
      }

    case ACTION_TYPE_LOGIN.GET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        fetching: false,
        forgotPassword: null,
        error: action.error,
      }

    case ACTION_TYPE_LOGIN.GET_RESET_PASSWORD:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_LOGIN.GET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
        resetPassword: action.response,
      }

    case ACTION_TYPE_LOGIN.GET_RESET_PASSWORD_ERROR:
      return {
        ...state,
        fetching: false,
        resetPassword: null,
        error: action.error,
      }

    default:
      return state
  }
}
