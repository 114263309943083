import './TransactionHistory.scss'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { TitleDescription } from 'yacob-components'
import { Table } from 'yacob-components'
import { Button } from 'yacob-components'
import { Loader } from 'yacob-components'
import { Pagination } from 'yacob-components'

import { LocalStorage } from '../../utils/service'
import {
  USER_DATA,
  USER_ROLE as _userRole,
} from '../../constants/app-constants'
import {
  ACTION_TYPE_TOPUP,
  ACTION_TYPE_PROGRESSTRACK,
  ACTION_TYPE_SESSION,
} from '../../redux/actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

const componentClass = class TransactionHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      historyColumns: [
        {
          title: 'method',
          key: 'payment_type',
          sort: 'a-d',
          render: item => <div>{item && item.replace('_', ' ')}</div>,
        },
        {
          title: 'child',
          key: 'child_name',
          sort: 'a-d',
        },
        {
          title: 'date',
          key: 'created_at',
          head_classname: 'u_text_right',
          sort: 'a-d',
          render: item => (
            <div className="u_text_right">
              {moment(item).format('DD MMMM, YYYY')}
            </div>
          ),
        },
        {
          title: 'amount',
          key: 'amount',
          head_classname: 'u_text_right',
          data_classname: 'u_font_roboto_medium',
          sort: 'a-d',
          render: (item, row) => (
            <div
              className={`u_text_right ${
                row.is_credit ? 'amount-in' : 'amount-out'
              }`}
            >{`${LocalStorage.getCurrency()} ${item}`}</div>
          ),
        },
      ],
      historyData: [],
      filterActiveKey: '',
      totalRecords: 0,
      activeSort: '',
      showloader: true,
      showDownloadButtonLoader: false,
    }
  }

  componentDidMount() {
    const { setProgressData } = this.props
    setProgressData({}, false, 0)
    this.getTransactionHistory()
  }

  // FIXME: Remove this deprecated method and refactor logic inside
  // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  componentWillReceiveProps(nextProps) {
    const { topupData } = this.props
    if (
      nextProps.topupData &&
      nextProps.topupData.accountTransactionData &&
      nextProps.topupData.accountTransactionData !==
        topupData.accountTransactionData
    ) {
      if (nextProps.topupData.accountTransactionData.transaction_histories) {
        this.setState({
          historyData:
            nextProps.topupData.accountTransactionData.transaction_histories,
          showloader: false,
          totalRecords:
            nextProps.topupData.accountTransactionData.total_records,
        })
      }
    }
  }

  getTransactionHistory = (page, sort, date_limit) => {
    const { getTransactionHistory } = this.props
    let uuid = ''
    if (
      LocalStorage.getData(USER_DATA) &&
      LocalStorage.getData(USER_DATA).role === _userRole.FACULTY
    ) {
      uuid =
        LocalStorage.getData(USER_DATA) &&
        LocalStorage.getData(USER_DATA).user_uuid
    } else {
      uuid =
        LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid
    }
    const data = {
      uuid,
      page: !page ? 1 : page,
      limit: 20,
      sort,
      date_limit,
    }
    getTransactionHistory(data)
  }

  tableItemsSort = key => {
    const { historyColumns, activePage, filterActiveKey } = this.state
    historyColumns.forEach((item, index) => {
      if (key === item.key) {
        const split = item.sort.split('')
        const array = split.reverse()
        const string = array.join('')
        historyColumns[index].sort = string

        if (string === 'a-d') key = `%2B${key}`
        else key = `%20${key}`
        this.setState({ activeSort: key, showloader: true })
        this.getTransactionHistory(activePage, key, filterActiveKey)
      } else {
        historyColumns[index].sort = 'a-d'
      }
    })
    this.setState({ historyColumns })
  }

  onChangePagination = key => {
    const { activeSort, filterActiveKey } = this.state
    this.setState({ activePage: key })
    this.getTransactionHistory(key, activeSort, filterActiveKey)
  }

  downloadTransactionHistoryCSV = () => {
    this.setState({ showDownloadButtonLoader: true })
    this.props.getSingleUseToken(() => {
      const { sessionData, showAlertMessage } = this.props
      const { token } = sessionData.singleUseToken || {}

      if (!token) {
        showAlertMessage({
          type: 'error',
          // TODO: Move text to locale file
          content: 'Unable to download .csv, please try again',
        })
        return this.setState({ showDownloadButtonLoader: false })
      }

      const { activeSort, filterActiveKey } = this.state

      // FIXME: don't use localStorage
      const uuid =
        LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid

      const params = {
        token,
        sort: activeSort,
        date_limit: filterActiveKey,
      }

      // TODO: Move this to utils.js
      const createURLParameters = params => {
        const entries = Object.entries(params)
        return entries.length > 0
          ? '?' +
              entries
                .filter(([key, value]) => value)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')
          : ''
      }

      const url = ''.concat(
        BASE_URL,
        ENDPOINT.ACCOUNT_TRANSACTION_DOWNLOAD,
        uuid,
        createURLParameters(params),
      )

      window.open(url)
      this.setState({ showDownloadButtonLoader: false })
    })
  }

  render() {
    const {
      historyData,
      historyColumns,
      showloader,
      totalRecords,
      showDownloadButtonLoader,
    } = this.state
    const { t } = this.props
    const name =
      LocalStorage.getData(USER_DATA) &&
      `${LocalStorage.getData(USER_DATA).first_name} ${
        LocalStorage.getData(USER_DATA).last_name
      }`

    const mobileView = historyData.map(data => {
      return (
        <div className="transactionHistory__table_list" key={data.created_at}>
          <div className="transactionHistory__table_list_left">
            <div className="transactionHistory__table_list_bold">
              {data.child_name}
            </div>
            <div className="transactionHistory__table_list_payment">
              {data.payment_type}
            </div>
          </div>
          <div className="transactionHistory__table_list_right">
            <div className="transactionHistory__table_list_created">
              {moment(data.created_at).format('DD MMMM, YYYY')}
            </div>
            <div className="transactionHistory__table_list_bold">{`${LocalStorage.getCurrency()} ${
              data.amount
            }`}</div>
          </div>
        </div>
      )
    })

    return (
      <div className="transactionHistory_container">
        <div className="transactionHistory">
          <TitleDescription title={`${name} — ${t('transaction_history')}`} />
          {showloader ? (
            <div className="transactionHistory__loader" key="loader">
              <Loader fitContent="full" />
            </div>
          ) : (
            <>
              <div className="transactionHistory__filter"></div>
              <div className="transactionHistory__table">
                {window.innerWidth <= 768 ? (
                  <div className="transactionHistory__table_list_wrapper">
                    {mobileView}
                  </div>
                ) : (
                  <Table
                    data={historyData}
                    columns={historyColumns}
                    tableSort={this.tableItemsSort}
                    itemkey="created_at"
                  />
                )}
                {historyData && historyData.length ? (
                  <>
                    <div className="transactionHistory__table_total">
                      <div>
                        {`${t('total')}: ${totalRecords} ${t('items')}`}
                      </div>
                    </div>
                    <div className="transactionHistory__table_pagination">
                      <Pagination
                        totalPage={Math.ceil(totalRecords / 20)}
                        onChange={this.onChangePagination}
                      />
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="transactionHistory__btn">
                <Button
                  text={t('download_data')}
                  size="small"
                  type="block"
                  onClick={this.downloadTransactionHistoryCSV}
                  showloader={showDownloadButtonLoader}
                />
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    topupData: state.topup,
    sessionData: state.session,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTransactionHistory: data =>
      dispatch({
        type: ACTION_TYPE_TOPUP.GET_ACCOUNT_TRANSACTION_HISTORY,
        data,
      }),
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    getSingleUseToken: callback =>
      dispatch({
        type: ACTION_TYPE_SESSION.GET_SINGLE_USE_TOKEN,
        callback,
      }),
    showAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const TransactionHistory = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(componentClass)),
)
