import React from 'react'
import { LocalStorage } from './service'

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

// school
export function getSuggestionValueSchool(suggestion) {
  return suggestion.name
}

export function renderSuggestionSchool(suggestion) {
  return <span>{`${suggestion.name}`}</span>
}

export function getSuggestionsSchool(value, list) {
  if (!value) {
    return list
  }
  value = value.toLowerCase()

  const filteredList = []
  list.forEach(word => {
    if (word.name.toLowerCase().includes(value)) {
      filteredList.push(word)
    }
  })

  return filteredList
}

// section
export function getSuggestionValueSection(suggestion) {
  return suggestion.name
}

export function renderSuggestionSection(suggestion) {
  return <span>{`${suggestion.name}`}</span>
}

export function getSuggestionsSection(value, list) {
  if (!value) {
    return list
  }
  const escapedValue = escapeRegexCharacters(value.trim())

  const regex = new RegExp(`^${escapedValue}`, 'i')
  return list.filter(item => regex.test(item.name))
}

// blood group

export function getSuggestionValueBloodGroup(suggestion) {
  return suggestion.value
}

export function renderSuggestionBloodGroup(suggestion) {
  return <span>{`${suggestion.value}`}</span>
}

export function getSuggestionsBloodGroup(value, list) {
  const escapedValue = escapeRegexCharacters(value.trim())
  if (escapedValue === '') {
    return list
  }
  const regex = new RegExp(`^${escapedValue}`, 'i')
  return list.filter(item => regex.test(item.value))
}

// daily limit

export function getSuggestionValueDailyLimit(suggestion) {
  return `${suggestion.limit}`
}

export function renderSuggestionDailyLimit(suggestion) {
  return <span>{`${suggestion.limit}`}</span>
}

export function getSuggestionsDailyLimit(value, list) {
  const escapedValue = escapeRegexCharacters(value.trim())
  if (escapedValue === '') {
    return list
  }
  const regex = new RegExp(`^${escapedValue}`, 'i')
  return list.filter(item =>
    regex.test(`${LocalStorage.getCurrency()} ${item.limit}`),
  )
}

// category
export function getSuggestionValueCategory(suggestion) {
  return `${suggestion.name}`
}

export function renderSuggestionCategory(suggestion) {
  return <span>{`${suggestion.name}`}</span>
}

export function getSuggestionsCategory(value, list) {
  const escapedValue = escapeRegexCharacters(value.trim())
  if (escapedValue === '') {
    return list
  }
  const regex = new RegExp(`^${escapedValue}`, 'i')
  return list.filter(item => regex.test(item.name))
}

// menu type
export function getSuggestionValueMenu(suggestion) {
  return `${suggestion.name}`
}

export function renderSuggestionMenu(suggestion) {
  return <span>{`${suggestion.name}`}</span>
}

export function getSuggestionsMenu(value, list) {
  const escapedValue = escapeRegexCharacters(value.trim())
  if (escapedValue === '') {
    return list
  }
  const regex = new RegExp(`^${escapedValue}`, 'i')
  return list.filter(item => regex.test(item.name))
}
