import './Notification.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { TitleDescription } from 'yacob-components'
import { PaymentCard } from 'yacob-components'
import { Button } from 'yacob-components'

import {
  ACTION_TYPE_PROGRESSTRACK,
  ACTION_TYPE_NOTIFICATION,
} from '../../redux/actions'
import { LocalStorage } from '../../utils/service'
import { USER_DATA } from '../../constants/app-constants'
import { Loader } from 'yacob-components'

class NotificationTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationData: [],
      showloader: true,
      showbtnloader: false,
    }
    this.notificationDataCopy = []
  }

  componentDidMount() {
    const { setProgressData } = this.props
    setProgressData({}, false, 0)
    this.getNotificationData()
  }

  componentWillReceiveProps(nextProps) {
    const { notificationData } = this.props
    if (
      nextProps.notificationData &&
      nextProps.notificationData.data &&
      nextProps.notificationData.data !== notificationData.data
    ) {
      if (nextProps.notificationData.data.notifications_settings) {
        this.setState({
          notificationData:
            nextProps.notificationData.data.notifications_settings,
          showloader: false,
        })
        this.notificationDataCopy =
          nextProps.notificationData.data.notifications_settings
      }
    }
    if (
      nextProps.notificationData &&
      nextProps.notificationData.setNotificationData &&
      nextProps.notificationData.setNotificationData !==
        notificationData.setNotificationData
    ) {
      this.setState({ showbtnloader: false })
      if (
        nextProps.notificationData.setNotificationData.notifications_settings
      ) {
        this.setNotificationSuccess()
      } else if (nextProps.notificationData.setNotificationData.error) {
        this.alert({
          type: 'error',
          content: nextProps.notificationData.setNotificationData.error,
        })
      }
    }
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  getNotificationData = () => {
    const { getNotificationData } = this.props
    const uuid =
      LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid
    getNotificationData(uuid)
  }

  setNotificationSuccess = () => {
    const { notificationData } = this.state
    this.notificationDataCopy = notificationData
    this.alert({ type: 'success', content: 'Updated successfully' })
  }

  onChangeToggleHandler = e => {
    const { target } = e
    const { id, checked } = target
    const { notificationData } = this.state
    const notificationDataDuplicate = [...notificationData]
    const idSplit = id.split('-')
    notificationDataDuplicate.forEach((item, index) => {
      if (idSplit[0] === item.notification_type) {
        const itemCopy = { ...notificationDataDuplicate[index] }
        itemCopy[idSplit[1]] = checked
        notificationDataDuplicate[index] = itemCopy
      }
    })
    this.setState({ notificationData: notificationDataDuplicate })
  }

  saveClickHandler = () => {
    const { notificationData } = this.state
    const array = []
    this.notificationDataCopy.forEach((data, index) => {
      if (JSON.stringify(data) !== JSON.stringify(notificationData[index])) {
        array.push(notificationData[index])
      }
    })
    if (array && array.length) {
      const { setNotificationData } = this.props
      const uuid =
        LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid
      const data = {
        uuid,
        payload: array,
      }
      this.setState({ showbtnloader: true })
      setNotificationData(data)
    } else {
      this.alert({
        type: 'error',
        content: 'Please toggle atleast one notification',
      })
    }
  }

  notificationTitle = string => {
    const lowerCaseString = string.toLowerCase()
    const firstCharUpperString =
      lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.substring(1)
    return firstCharUpperString
  }

  renderNotificationList = () => {
    const { notificationData, showbtnloader } = this.state
    const { onChangeToggleHandler } = this
    const { t } = this.props
    let dom = null
    if (notificationData && notificationData.length) {
      dom = (
        <>
          {notificationData.map(data => {
            return (
              <div
                className="notification__list_item"
                key={data.notification_type}
              >
                <div className="notification__list_heading">
                  {this.notificationTitle(data.notification_type)}
                </div>
                <PaymentCard
                  id={`${data.notification_type}-enable_sms`}
                  onChange={onChangeToggleHandler}
                  toggleValue={data.enable_sms}
                  mainText={t('SMS')}
                  // subText='Lorem ipsum dolor sit amet avec pur io'
                  hasToggle
                />
                <PaymentCard
                  id={`${data.notification_type}-enable_email`}
                  onChange={onChangeToggleHandler}
                  toggleValue={data.enable_email}
                  mainText={t('email')}
                  // subText='Lorem ipsum dolor sit amet avec pur io'
                  hasToggle
                />
              </div>
            )
          })}
          <div className="notification__list_button">
            <Button
              text="SAVE"
              size="big"
              type="block"
              onClick={() => this.saveClickHandler()}
              showloader={showbtnloader}
            />
          </div>
        </>
      )
    }
    return dom
  }

  render() {
    const { showloader } = this.state
    const { t } = this.props
    const name =
      LocalStorage.getUserData(USER_DATA) &&
      `${LocalStorage.getUserData(USER_DATA).first_name} ${
        LocalStorage.getUserData(USER_DATA).last_name
      }`

    return (
      <div className="notification_container">
        <div className="notification">
          <TitleDescription title={`${name} — ${t('notifications')}`} />
          <div className="notification__list">
            {showloader ? (
              <div className="notification__list_loader">
                <Loader fitContent="full" />
              </div>
            ) : (
              this.renderNotificationList()
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    progressTrackData: state.progressTrack,
    notificationData: state.notification,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setNotificationData: data =>
      dispatch({
        type: ACTION_TYPE_NOTIFICATION.SET_NOTIFICATION_LIST,
        data,
      }),
    getNotificationData: data =>
      dispatch({
        type: ACTION_TYPE_NOTIFICATION.GET_NOTIFICATION_LIST,
        data,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const Notification = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(NotificationTrans)),
)
