import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_SESSION } from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'
import { LocalStorage } from '../../utils/service'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../constants/app-constants'

function* getRefreshTokenSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.REFRESH_TOKEN}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (!(response.error && response.tokenExpired)) {
      LocalStorage.setData(ACCESS_TOKEN, response.access_token)
      LocalStorage.setData(REFRESH_TOKEN, response.refresh_token)
    }
    yield put({
      type: ACTION_TYPE_SESSION.GET_REFRESH_TOKEN_SUCCESS,
      response,
    })
  } catch (error) {
    yield put({
      type: ACTION_TYPE_SESSION.GET_REFRESH_TOKEN_ERROR,
      error,
    })
  }
}

function* getSingleUseTokenSaga({ callback = response => response }) {
  try {
    const url = `${BASE_URL}${ENDPOINT.SINGLE_USE_TOKEN}`
    const response = yield call(fetchCall, url, 'GET')
    yield put({
      type: ACTION_TYPE_SESSION.GET_SINGLE_USE_TOKEN_SUCCESS,
      response,
    })
    callback(response)
  } catch (error) {
    yield put({
      type: ACTION_TYPE_SESSION.GET_SINGLE_USE_TOKEN_ERROR,
      error,
    })
    callback(error)
  }
}

export function* watchSessionSaga() {
  yield takeLatest(ACTION_TYPE_SESSION.GET_REFRESH_TOKEN, getRefreshTokenSaga)
  yield takeLatest(
    ACTION_TYPE_SESSION.GET_SINGLE_USE_TOKEN,
    getSingleUseTokenSaga,
  )
}
const sessionSaga = [fork(watchSessionSaga)]
export default sessionSaga
