import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_EARNCREDIT, ACTION_TYPE_SESSION } from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getreferralInvitationSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.REFERRAL_INVITATION}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    yield put({
      type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_INVITATIONS_SUCCESS,
      response,
    })
  } catch (error) {
    yield put({
      type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_INVITATIONS_ERROR,
      error,
    })
  }
}

function* getreferralHistorySaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.REFERRAL_HISTORY}${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_HISTORY_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_HISTORY_ERROR,
      error,
    })
  }
}

function* getreferralUrlSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.REFERRAL_URL}${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_URL_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_EARNCREDIT.GET_REFERRAL_URL_ERROR,
      error,
    })
  }
}

export function* watchEarnCreditSaga() {
  yield takeLatest(
    ACTION_TYPE_EARNCREDIT.GET_REFERRAL_INVITATIONS,
    getreferralInvitationSaga,
  )
  yield takeLatest(
    ACTION_TYPE_EARNCREDIT.GET_REFERRAL_HISTORY,
    getreferralHistorySaga,
  )
  yield takeLatest(ACTION_TYPE_EARNCREDIT.GET_REFERRAL_URL, getreferralUrlSaga)
}
const earnCreditSaga = [fork(watchEarnCreditSaga)]
export default earnCreditSaga
