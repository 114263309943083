import './ChangePassword.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { TitleDescription } from 'yacob-components'
import { InputLabelFloat } from 'yacob-components'
import { Button } from 'yacob-components'

import {
  ConfirmPasswordValidation,
  PasswordValidation,
} from '../../utils/validation'
import { LocalStorage } from '../../utils/service'
import {
  USER_DATA,
  ROUTES as _routes,
  USER_ROLE as _userRole,
} from '../../constants/app-constants'
import {
  ACTION_TYPE_LOGIN,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'

class ChangePasswordTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      validation: {
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      },
      currentFieldFocus: '',
      showloader: false,
    }
  }

  componentDidMount() {
    const { setProgressData } = this.props
    setProgressData({}, false, 0)
    if (
      document &&
      document.getElementsByClassName('changePassword_input') &&
      document.getElementsByClassName('changePassword_input')[0]
    )
      document
        .getElementsByClassName('changePassword_input')[0]
        .addEventListener('keyup', this.enterListener)
  }

  componentWillReceiveProps(nextProps) {
    const { loginData } = this.props
    if (
      nextProps.loginData &&
      nextProps.loginData.changePassword !== loginData.changePassword
    ) {
      this.setState({ showloader: false })
      if (nextProps.loginData.changePassword.user) {
        this.alert({
          type: 'success',
          content: 'Password changed Successfully',
        })
        this.changePasswordSuccess()
      } else if (nextProps.loginData.changePassword.error) {
        this.alert({
          type: 'error',
          content: nextProps.loginData.changePassword.error,
        })
      }
    }
  }

  componentWillUnmount() {
    if (
      document &&
      document.getElementsByClassName('changePassword_input') &&
      document.getElementsByClassName('changePassword_input')[0]
    )
      document
        .getElementsByClassName('changePassword_input')[0]
        .removeEventListener('keyup', this.enterListener)
  }

  enterListener = event => {
    event.preventDefault()
    if (event.keyCode === 13) {
      this.saveChangePassword()
    }
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  changePasswordSuccess = () => {
    const { history } = this.props
    history.push(_routes.HOME)
  }

  validationChangeHandler = (id, value) => {
    const { validation } = this.state
    const validationCopy = { ...validation }
    validationCopy[id] = value
    this.setState({ validation: validationCopy })
  }

  onFocusInput = id => {
    this.setState({ currentFieldFocus: id })
  }

  onChangeInput = e => {
    const { target } = e
    const { id } = target
    const { value } = target
    const { inputValue } = this.state
    const valueCopy = { ...inputValue }
    valueCopy[id] = value
    this.setState({ inputValue: valueCopy })
  }

  validationOnClickHandler = () => {
    const { validation, inputValue } = this.state
    const validationCopy = { ...validation }
    validationCopy.currentPassword = !PasswordValidation(
      inputValue.currentPassword,
    )
    validationCopy.newPassword = !PasswordValidation(inputValue.newPassword)
    validationCopy.confirmNewPassword =
      !inputValue.confirmNewPassword ||
      !ConfirmPasswordValidation(
        inputValue.newPassword,
        inputValue.confirmNewPassword,
      )
    this.setState({ validation: validationCopy })
    return validationCopy
  }

  validationAlert = key => {
    const { inputValue } = this.state
    if (key === 'currentPassword') {
      if (inputValue.currentPassword)
        this.alert({
          type: 'error',
          content:
            'Password must be a combination of upper case, lower case, and number',
        })
      else
        this.alert({ type: 'error', content: 'Please enter current password' })
    } else if (key === 'newPassword') {
      if (inputValue.newPassword)
        this.alert({
          type: 'error',
          content:
            'Password must be a combination of upper case, lower case, and number',
        })
      else this.alert({ type: 'error', content: 'Please enter new password' })
    } else if (key === 'confirmNewPassword') {
      if (inputValue.confirmNewPassword)
        this.alert({
          type: 'error',
          content: 'Entered Password is not matching!! Please Try Again',
        })
      else
        this.alert({
          type: 'error',
          content: 'Please enter confirm new password',
        })
    }
  }

  saveChangePassword = () => {
    const validation = this.validationOnClickHandler()
    if (
      Object.keys(validation).every(k => {
        this.setState({ currentFieldFocus: k })
        if (validation[k]) {
          this.validationAlert(k)
        }
        return !validation[k]
      })
    ) {
      const { inputValue } = this.state
      const { setChangePassword } = this.props
      let uuid = ''
      if (
        LocalStorage.getData(USER_DATA) &&
        LocalStorage.getData(USER_DATA).role === _userRole.FACULTY
      ) {
        uuid =
          LocalStorage.getData(USER_DATA) &&
          LocalStorage.getData(USER_DATA).user_uuid
      } else {
        uuid =
          LocalStorage.getData(USER_DATA) &&
          LocalStorage.getData(USER_DATA).uuid
      }
      const payload = {
        old_password: inputValue.currentPassword,
        new_password: inputValue.newPassword,
      }
      const data = {
        uuid,
        payload,
      }
      this.setState({ showloader: true })
      setChangePassword(data)
    }
  }

  render() {
    const { inputValue, validation, currentFieldFocus, showloader } = this.state
    const { t } = this.props
    const userDetail = LocalStorage.getUserData(USER_DATA)
    const name = `${userDetail.first_name} ${userDetail.last_name}`

    const DOM = (
      <div className="changePassword_container">
        <div className="changePassword">
          <TitleDescription title={`${name} — Change Password`} />
          <div className="changePassword_input">
            <InputLabelFloat
              id="currentPassword"
              label={t('current_password')}
              type="password"
              required
              value={inputValue.currentPassword}
              onChange={this.onChangeInput}
              validation={validation.currentPassword}
              validationChange={this.validationChangeHandler}
              onFocus={this.onFocusInput}
              currentFieldFocus={currentFieldFocus}
            />

            <InputLabelFloat
              id="newPassword"
              label={t('new_password')}
              type="password"
              required
              value={inputValue.newPassword}
              onChange={this.onChangeInput}
              validation={validation.newPassword}
              validationChange={this.validationChangeHandler}
              onFocus={this.onFocusInput}
              currentFieldFocus={currentFieldFocus}
            />

            <InputLabelFloat
              id="confirmNewPassword"
              label={t('confirm_new_Password')}
              type="password"
              required
              value={inputValue.confirmNewPassword}
              onChange={this.onChangeInput}
              validation={validation.confirmNewPassword}
              validationChange={this.validationChangeHandler}
              onFocus={this.onFocusInput}
              currentFieldFocus={currentFieldFocus}
            />

            <Button
              size="big"
              text={t('save')}
              type="block"
              showloader={showloader}
              onClick={this.saveChangePassword}
            />
          </div>
        </div>
      </div>
    )
    return DOM
  }
}

const mapStateToProps = state => {
  return {
    loginData: state.login,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChangePassword: data =>
      dispatch({
        type: ACTION_TYPE_LOGIN.GET_CHANGE_PASSWORD,
        data,
      }),
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const ChangePassword = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ChangePasswordTrans)),
)
