export const ACTION_TYPE_LOGIN = {
  GET_LOGIN: 'GET_LOGIN',
  GET_LOGIN_SUCCESS: 'GET_LOGIN_SUCCESS',
  GET_LOGIN_ERROR: 'GET_LOGIN_ERROR',

  GET_CHANGE_PASSWORD: 'GET_CHANGE_PASSWORD',
  GET_CHANGE_PASSWORD_SUCCESS: 'GET_CHANGE_PASSWORD_SUCCESS',
  GET_CHANGE_PASSWORD_ERROR: 'GET_CHANGE_PASSWORD_ERROR',

  GET_FORGOT_PASSWORD: 'GET_FORGOT_PASSWORD',
  GET_FORGOT_PASSWORD_SUCCESS: 'GET_FORGOT_PASSWORD_SUCCESS',
  GET_FORGOT_PASSWORD_ERROR: 'GET_FORGOT_PASSWORD_ERROR',

  GET_RESET_PASSWORD: 'GET_RESET_PASSWORD',
  GET_RESET_PASSWORD_SUCCESS: 'GET_RESET_PASSWORD_SUCCESS',
  GET_RESET_PASSWORD_ERROR: 'GET_RESET_PASSWORD_ERROR',
}
