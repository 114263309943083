import { combineReducers } from 'redux'

import progressTrack from './progressTrack'
import login from './login'
import signup from './signup'
import child from './child'
import session from './session'
import limits from './spendingLimits'
import lunchOrder from './lunchOrder'
import topup from './topup'
import user from './user'
import nutrition from './nutrition'
import earnCredit from './earnCredits'
import notification from './notification'
import preAuthDetail from './pre-auth-details'

export const rootReducer = combineReducers({
  progressTrack,
  session,
  login,
  signup,
  child,
  limits,
  lunchOrder,
  topup,
  user,
  nutrition,
  earnCredit,
  notification,
  preAuthDetail,
})
