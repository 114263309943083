import { takeLatest, takeEvery, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_SIGNUP, ACTION_TYPE_SESSION } from '../actions'

import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getsignupSaga(action) {
  try {
    const { data } = action
    const { isFaculty, payload } = data
    const url = `${BASE_URL}${
      isFaculty ? ENDPOINT.FACULTY_SIGNUP : ENDPOINT.SIGNUP
    }`
    const response = yield call(fetchCall, url, 'POST', payload)
    yield put({ type: ACTION_TYPE_SIGNUP.GET_SIGNUP_SUCCESS, response })
  } catch (error) {
    yield put({ type: ACTION_TYPE_SIGNUP.GET_SIGNUP_ERROR, error })
  }
}

function* getAddChildSchool(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.ADDCHILD_SCHOOL}`
    const response = yield call(fetchCall, url, 'GET', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL_ERROR, error })
  }
}

function* getAddChildGrade(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.ADDCHILD_GRADE}?school_uuid=${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_GRADE_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_GRADE_ERROR, error })
  }
}

function* getAddChildCreate(action) {
  const { callback = () => {} } = action
  try {
    const url = `${BASE_URL}${ENDPOINT.ADDCHILD_CREATE}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_CREATE_SUCCESS,
        response,
      })
      callback(response)
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_CREATE_ERROR, error })
    callback(error)
  }
}

function* getAddChildSection(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.ADDCHILD_SECTION}?grade_uuid=${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_SECTION_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_SECTION_ERROR, error })
  }
}

export function* watchSignupSaga() {
  yield takeLatest(ACTION_TYPE_SIGNUP.GET_SIGNUP, getsignupSaga)
  yield takeLatest(ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL, getAddChildSchool)
  yield takeLatest(ACTION_TYPE_SIGNUP.GET_ADDCHILD_GRADE, getAddChildGrade)
  yield takeEvery(ACTION_TYPE_SIGNUP.GET_ADDCHILD_CREATE, getAddChildCreate)
  yield takeEvery(ACTION_TYPE_SIGNUP.GET_ADDCHILD_SECTION, getAddChildSection)
}

const signupSaga = [fork(watchSignupSaga)]
export default signupSaga
