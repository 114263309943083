import './LunchOrder.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { TitleDescription } from 'yacob-components'
import { MealListing } from 'yacob-components'
import { Button } from 'yacob-components'
import { LunchOrderCalendar } from 'yacob-components'
import { Loader } from 'yacob-components'
import { Payment } from 'yacob-components'
import { OrderStatus } from 'yacob-components'

import {
  ROUTES as _routes,
  USER_DATA,
  PAYMENT_ID,
} from '../../constants/app-constants'
import {
  ACTION_TYPE_PROGRESSTRACK,
  ACTION_TYPE_LUNCHORDER,
  ACTION_TYPE_USER,
} from '../../redux/actions'
import { LocalStorage } from '../../utils/service'

const componentClass = class LunchOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addedItemCount: 0,
      addedItemPrice: 0,
      weekIndex: 0,
      cart: this.setInitialDate(),
      activeDate: this.setInitialDate('active'),
      progressData: [
        {
          key: 'menu',
          status: 'active',
          displayName: 'menu_selection',
          index: 0,
          route: this.getMenuRoute(),
        },
        {
          key: 'payment',
          status: '',
          displayName: 'payment',
          index: 1,
          route: `${_routes.LUNCH_ORDER}/payment`,
        },
      ],
      childData: {},
      dateList: this.setDateList(),
      showloader: true,
      mealShowloader: false,
      WeeklyPrevDateSelect: {},
      useMyBalance: true,
      paybtnshowloader: false,
      paymentSelection: 'Credit card',
      userData: {},
      currentProgress: 'menu',
      mealListCheckoutData: [],
      orderStatusData: {},
    }
  }

  componentDidMount() {
    const { history } = this.props
    this.currentPath(history)
    this.getMenuList(0, 4)
  }

  componentWillReceiveProps(nextProps) {
    const { lunchOrderData, location } = this.props

    if (nextProps.location !== location) this.currentPath(nextProps.history)

    if (
      nextProps.lunchOrderData &&
      nextProps.lunchOrderData.data &&
      nextProps.lunchOrderData.data !== lunchOrderData.data
    ) {
      this.setMenuList(nextProps.lunchOrderData.data)
    }
    if (
      nextProps.lunchOrderData &&
      nextProps.lunchOrderData.checkoutData !== lunchOrderData.checkoutData
    ) {
      if (
        nextProps.lunchOrderData.checkoutData.order &&
        !nextProps.lunchOrderData.checkoutData.payment
      ) {
        this.orderSuccessHandler()
      } else if (nextProps.lunchOrderData.checkoutData.payment) {
        this.paymentGatewayRedirect(
          nextProps.lunchOrderData.checkoutData.payment,
        )
      } else if (nextProps.lunchOrderData.checkoutData.error) {
        this.orderErrorHandler(nextProps.lunchOrderData.checkoutData)
      }
      this.setState({ paybtnshowloader: false })
    }

    if (
      nextProps.userData &&
      nextProps.userData.data &&
      nextProps.userData.data.uuid
    ) {
      this.setState({ userData: nextProps.userData.data })
    }
  }

  currentPath = history => {
    const splitpath = history.location.pathname.split(`${_routes.LUNCH_ORDER}`)
    this.setProgressDataHandler(splitpath[1])
  }

  historyPush = path => {
    const { history } = this.props
    history.push(path)
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  getMenuRoute = () => {
    const { location } = this.props
    const { state } = location
    if (state) {
      const { data } = state
      const path = {
        pathname: _routes.LUNCH_ORDER,
        state: { data },
      }
      return path
    }
    return null
  }

  orderSuccessHandler = () => {
    const data = { status: 'success', data: 'Order placed successfully' }
    this.setState({ orderStatusData: data })
    const { getUserData } = this.props
    const uuid =
      LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid
    if (uuid) {
      const userdata = {
        isFaculty: false,
        uuid,
      }
      getUserData(userdata)
    }
    this.historyPush(`${_routes.LUNCH_ORDER}/status`)
  }

  orderErrorHandler = errorData => {
    const data = { status: 'error', data: errorData.error }
    this.setState({ orderStatusData: data })
    this.historyPush(`${_routes.LUNCH_ORDER}/status`)
  }

  getMenuList = (fromIndex, toIndex) => {
    const { location } = this.props
    const { state, pathname } = location
    // TODO: refactor this
    if (!pathname.includes('status'))
      if (!state) {
        this.historyPush(_routes.HOME)
      } else {
        const { data } = state
        if (data && data.uuid) {
          const { getMenuListData } = this.props
          const { dateList } = this.state
          const from_date =
            dateList[fromIndex] &&
            moment(dateList[fromIndex]).format('YYYY-MM-DD')
          const to_date =
            dateList[toIndex] && moment(dateList[toIndex]).format('YYYY-MM-DD')
          getMenuListData({ uuid: data.uuid, from_date, to_date })
          this.setState({ childData: data })
        } else {
          this.historyPush(_routes.HOME)
        }
      }
  }

  setInitialDate = type => {
    const startDate = moment().add(4, 'days').format('ddd')
    let dateIndex = 4
    if (startDate.indexOf('Fri') > -1) dateIndex = 6
    if (startDate.indexOf('Sat') > -1) dateIndex = 5
    if (type === 'active')
      return moment().add(dateIndex, 'days').format('YYYY-MM-DD')
    const selectDate = {}
    selectDate[moment().add(dateIndex, 'days').format('YYYY-MM-DD')] = {}
    return selectDate
  }

  setDateList = () => {
    const startDate = moment().add(4, 'days').format('ddd')
    let dateIndex = 4
    if (startDate.indexOf('Fri') > -1) dateIndex = 6
    if (startDate.indexOf('Sat') > -1) dateIndex = 5
    const dateArray = []
    for (let i = dateIndex; dateArray.length < 20; i += 1) {
      const date = moment().add(i, 'days')
      if (
        !(
          date.format('ddd').indexOf('Fri') > -1 ||
          date.format('ddd').indexOf('Sat') > -1
        )
      )
        dateArray.push(date)
    }
    return dateArray
  }

  setMenuList = () => {
    this.setState({
      showloader: false,
      mealShowloader: false,
    })
  }

  mealItemOnClick = ({
    menuUUID,
    menuItemUUID,
    categoryUUID,
    price,
    isMain,
  }) => {
    const { addedItemPrice, addedItemCount } = this.state

    const { food_items, menu_items } = this.props.lunchOrderData.data

    const { activeDate, cart } = this.state

    let box = cart[activeDate]

    if (!box) {
      box = cart[activeDate] = {}
    }

    let menu = box[menuUUID]

    if (!menu) {
      menu = box[menuUUID] = {}
    }

    let total = addedItemPrice
    let count = addedItemCount

    let cartItemUUID = menu[categoryUUID]

    if (cartItemUUID === menuItemUUID) {
      count = count - 1
      total = total - price
      delete menu[categoryUUID]
      if (isMain) {
        delete menu.selectedMainCategory
      }
    }
    if (cartItemUUID && cartItemUUID !== menuItemUUID) {
      const foodItemUUID = menu_items[cartItemUUID].item_uuid
      total = total + price - food_items[foodItemUUID].price
      cartItemUUID = menu[categoryUUID] = menuItemUUID
    }
    if (!cartItemUUID) {
      count = count + 1
      total = total + price
      cartItemUUID = menu[categoryUUID] = menuItemUUID
      if (isMain) {
        if (menu.selectedMainCategory) {
          const selectedMainItemFoodUUID =
            menu_items[menu[menu.selectedMainCategory]].item_uuid
          count = count - 1
          total = total - food_items[selectedMainItemFoodUUID].price
          delete menu[menu.selectedMainCategory]
        }
        menu.selectedMainCategory = categoryUUID
      }
    }

    if (Object.keys(box[menuUUID]).length === 0) {
      delete box[menuUUID]
    }

    this.setState({
      cart: {
        ...cart,
        [activeDate]: box,
      },
      addedItemCount: count,
      addedItemPrice: total,
    })
  }

  changeWeek = weekIndex => {
    const { dateList } = this.state

    // Days relative to the zero index week
    const firstDayOfTheWeek = weekIndex * 5
    const lastDayOfTheWeek = firstDayOfTheWeek + 9

    this.setState({ mealShowloader: true })
    this.getMenuList(firstDayOfTheWeek, lastDayOfTheWeek)

    this.setState({
      weekIndex,
      activeDate: moment(dateList[firstDayOfTheWeek]).format('YYYY-MM-DD'),
    })
  }

  contentCalendarHandler = date => {
    this.setState({
      activeDate: date,
    })
  }

  checkoutOrderClickHandler = () => {
    const { cart, childData } = this.state
    const mealListFilter = []

    const datesWithItems = Object.entries(cart).filter(
      ([date, menus]) => Object.keys(menus).length > 0,
    )

    if (datesWithItems.length === 0) {
      this.alert({
        type: 'error',
        content: `Select at least one Main Course`,
      })
      return
    }

    // At least one main selected for each menu category for each date

    for (const [date, menus] of datesWithItems) {
      const niceDate = moment(date).format('Do')
      const menuCategories = Object.values(menus)

      const categoriesStatuses = menuCategories.map(menu => {
        return menu.selectedMainCategory ? true : false
      })

      if (
        categoriesStatuses.includes(false) &&
        categoriesStatuses.includes(true)
      ) {
        this.alert({
          type: 'error',
          content:
            `For the order on ${niceDate} select the Main Course ` +
            `from the same menu you've selected Snacks and/or Beverage`,
        })
        return
      }

      if (!categoriesStatuses.includes(true)) {
        this.alert({
          type: 'error',
          content: `For the order on ${niceDate} select at least one Main Course`,
        })
        return
      }

      menuCategories.forEach(category => {
        const foodCategories = Object.entries(category)
        foodCategories.forEach(([key, uuid]) => {
          if (key !== 'selectedMainCategory') {
            mealListFilter.push(uuid)
          }
        })
      })
    }

    this.setState({ mealListCheckoutData: mealListFilter })
    const path = {
      state: { data: childData },
      pathname: `${_routes.LUNCH_ORDER}/payment`,
    }
    this.historyPush(path)
  }

  toggleBalance = e => {
    this.setState({ useMyBalance: e.target.checked })
  }

  onChangePaymentSelect = e => {
    this.setState({ paymentSelection: e.target.value })
  }

  setTopup = () => {
    const { addCheckoutCart, history } = this.props
    const {
      mealListCheckoutData,
      childData,
      paymentSelection,
      userData,
    } = this.state

    let order_type

    const { allow_classroom_delivery, allow_pre_prepared_meals } =
      history.location.state.data || {}

    if (allow_pre_prepared_meals) {
      order_type = 'PRE_PREPARED_MEAL'
    }
    // Default to CLASSROOM_DELIVERY
    if (allow_classroom_delivery) {
      order_type = 'CLASSROOM_DELIVERY'
    }

    let { useMyBalance } = this.state
    if (useMyBalance && userData && !userData.balance) useMyBalance = false
    if (paymentSelection || useMyBalance) {
      const payload = {
        menu_items: mealListCheckoutData,
        child_uuid: childData.uuid,
        payment: {
          use_balance: useMyBalance,
          payment_type: paymentSelection,
          return_url: `${window.location.origin}/lunch-order/status`,
        },
        order_type: order_type,
      }
      this.setState({ paybtnshowloader: true })
      addCheckoutCart(payload)
    } else {
      this.alert({ type: 'error', content: 'Please select payment method' })
    }
  }

  paymentGatewayRedirect = data => {
    window.open(data.payment_url, '_self')
    LocalStorage.setData(PAYMENT_ID, data.tran_ref)
  }

  orderStatusContinue = () => {
    const { history } = this.props
    history.replace(_routes.HOME)
  }

  checkPathSearch = () => {
    const { history } = this.props
    if (history.location && history.location.search) {
      const split = history.location.search.split('&')
      const splitStatus = split[0].split('?status=')
      const splitResult = split[1].split('api_result=')
      if (
        splitStatus &&
        splitStatus[1] &&
        splitStatus[1].toLowerCase().indexOf('success') > -1
      ) {
        const data = { status: 'success', data: 'Order placed successfully' }
        this.setState({ orderStatusData: data })
      } else if (splitStatus && splitStatus[1]) {
        const data = {
          status: 'error',
          data: decodeURI(splitResult && splitResult[1]),
        }
        this.setState({ orderStatusData: data })
      }
    }
  }

  setProgressDataHandler = (path = 'menu') => {
    const { progressData, addedItemPrice } = this.state
    const { setProgressData, history } = this.props
    let index = 0
    const scrollElement = document && document.getElementById('container')
    if (scrollElement) scrollElement.scrollTop = 0
    if (path === '/status') {
      this.setState({ currentProgress: 'status', showloader: false })
      this.checkPathSearch()
      setProgressData({}, false, index)
      return
    }
    if (!addedItemPrice && path === '/payment') {
      const { childData } = this.state
      const replacepath = {
        pathname: _routes.LUNCH_ORDER,
        state: { data: childData },
      }
      history.replace(replacepath)
      return
    }

    Object.keys(progressData).forEach(e => {
      if (progressData[e].key === path.replace('/', '')) {
        progressData[e].status = 'active'
        index = e
      }
    })
    Object.keys(progressData).forEach(e => {
      if (e > index) progressData[e].status = ''
      else if (e < index) progressData[e].status = 'done'
    })

    this.setState({ currentProgress: path.replace('/', '') })
    setProgressData(progressData, true, index)
  }

  render() {
    const {
      showloader,
      mealShowloader,
      dateList,
      activeDate,
      addedItemCount,
      addedItemPrice,
      weekIndex,
      cart,
    } = this.state
    const { t, lunchOrderData } = this.props
    const mealDom = []
    const contentDom = []

    const {
      menus,
      categories: categoriesData,
      food_items,
      menu_items,
      food_items_map,
      allergies,
      diets,
    } = lunchOrderData.data

    const box = cart[activeDate] || {}

    const menuMapForActiveDate = food_items_map && food_items_map[activeDate]
    const menuForActiveDate =
      menuMapForActiveDate && Object.entries(menuMapForActiveDate)

    if (mealShowloader) {
      mealDom.push(
        <div key="meal_loader_con" className="no-content">
          <Loader key="loader" fitContent="full" />
        </div>,
      )
    } else if (menuForActiveDate && menuForActiveDate.length) {
      mealDom.push(
        menuForActiveDate
          .sort((a, b) => {
            // Sort menus alphabetically
            const menuA = menus[a[0]].menu_category
            const menuB = menus[b[0]].menu_category
            return menuA.localeCompare(menuB)
          })
          .map(([menuUUID, categories]) => {
            const menu = menus[menuUUID]
            return (
              <div>
                <h2 className="lunchOrder_category__menuTitle">
                  {`${menu.menu_category} Menu`}
                </h2>
                {Object.entries(categories)
                  .sort((a, b) => {
                    const categoryA = categoriesData[a[0]].sequence_number
                    const categoryB = categoriesData[b[0]].sequence_number
                    return categoryA - categoryB
                  })
                  .map(([categoryUUID, items]) => {
                    const category = categoriesData[categoryUUID]
                    return (
                      <li className="lunchOrder_category" key={categoryUUID}>
                        <div className="lunchOrder_category__title">
                          {`${menu.menu_category} – ${category.name}`}
                        </div>
                        <div className="lunchOrder_category__mealListing">
                          {items.map(menuItemUUID => {
                            const itemUUID =
                              menu_items &&
                              menu_items[menuItemUUID] &&
                              menu_items[menuItemUUID].item_uuid
                            const itemError = text => (
                              <p className="lunchOrder_category__itemError">
                                {text}
                              </p>
                            )
                            if (!itemUUID) {
                              return itemError(
                                `Error: Menu Item ${menuItemUUID} not found!`,
                              )
                            }

                            const item = food_items[itemUUID]
                            if (!item) {
                              return itemError(
                                `Error: Food Item ${itemUUID} not found!`,
                              )
                            }
                            const isMenuInCart = box[menuUUID] ? true : false
                            const isCategoryInCart =
                              isMenuInCart && box[menuUUID][categoryUUID]
                                ? true
                                : false

                            const isItemInCart =
                              isCategoryInCart &&
                              box[menuUUID][categoryUUID] === menuItemUUID

                            const isOtherMainInCart =
                              category.is_main &&
                              isMenuInCart &&
                              box[menuUUID].selectedMainCategory &&
                              box[menuUUID].selectedMainCategory !==
                                categoryUUID

                            return (
                              <MealListing
                                isInactive={
                                  !isItemInCart &&
                                  (isCategoryInCart || isOtherMainInCart)
                                }
                                item={{
                                  ...item,
                                  menuItemUUID,
                                  categoryUUID,
                                  menuUUID,
                                  isMain: category.is_main,
                                  is_added: isItemInCart,
                                  allergies:
                                    item &&
                                    item.allergy_ids &&
                                    item.allergy_ids.map(allergyUUID => {
                                      return allergies[allergyUUID]
                                    }),
                                  diets:
                                    item.diet_ids &&
                                    item.diet_ids.map(dietUUID => {
                                      return diets[dietUUID]
                                    }),
                                }}
                                onClick={this.mealItemOnClick}
                                key={itemUUID}
                              />
                            )
                          })}
                        </div>
                      </li>
                    )
                  })}
              </div>
            )
          }),
      )
    } else {
      mealDom.push(
        <div key="no-content" className="no-content">
          <div>No items found !!!</div>
          <div>Please select some other dates</div>
        </div>,
      )
    }

    if (showloader) {
      contentDom.push(
        <div key="loader_con" className="container__loader">
          <Loader key="loader" fitContent="full" />
        </div>,
      )
    } else {
      const {
        currentProgress,
        useMyBalance,
        paybtnshowloader,
        paymentSelection,
        userData,
        orderStatusData,
      } = this.state
      if (currentProgress === 'payment') {
        contentDom.push(
          <Payment
            showUseMyBalance
            totalAmount={addedItemPrice}
            balance={userData && userData.balance}
            onChange={this.toggleBalance}
            toggleValue={useMyBalance}
            onChangeRadio={this.onChangePaymentSelect}
            paymentSelection={paymentSelection}
            toggleId="useMyBalance"
            onClickPay={this.setTopup}
            paybtnshowloader={paybtnshowloader}
            key="payment"
          />,
        )
      } else if (currentProgress === 'status') {
        contentDom.push(
          <OrderStatus
            key="order_status"
            data={orderStatusData}
            onClick={this.orderStatusContinue}
          />,
        )
      } else {
        const calendarComponent = (
          <LunchOrderCalendar
            incrementWeek={weekIndex * 5}
            leftArrowClick={() => this.changeWeek(weekIndex - 1)}
            rightArrowClick={() => this.changeWeek(weekIndex + 1)}
            contentClick={this.contentCalendarHandler}
            activeDate={activeDate}
            selectedDateList={cart}
            dateList={dateList}
          />
        )
        contentDom.push(
          <div className="lunchOrder" key="lunchorder">
            <div className="u_mobile_display_block">{calendarComponent}</div>

            <TitleDescription
              title={t('order')}
              description={t('order_subtext')}
            />
            <div className="u_mobile_display_none">{calendarComponent}</div>
            <ul className="lunchOrder_category-wrapper">{mealDom}</ul>
            <div className="lunchOrder_button">
              <Button
                text={t('checkout')}
                size="big"
                type="block"
                leftContent={addedItemCount}
                rightContent={`${LocalStorage.getCurrency()} ${addedItemPrice}`}
                onClick={this.checkoutOrderClickHandler}
              />
            </div>
          </div>,
        )
      }
    }

    const DOM = (
      <div className="container" id="container">
        {contentDom}
      </div>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    progressTrackData: state.progressTrack,
    lunchOrderData: state.lunchOrder,
    topupdata: state.topup,
    userData: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    getMenuListData: data =>
      dispatch({
        type: ACTION_TYPE_LUNCHORDER.GET_MENU_LIST,
        data,
      }),
    addCheckoutCart: data =>
      dispatch({
        type: ACTION_TYPE_LUNCHORDER.ADD_CART_CHECKOUT,
        data,
      }),
    getUserData: data =>
      dispatch({
        type: ACTION_TYPE_USER.GET_USER,
        data,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const LunchOrder = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(componentClass)),
)
