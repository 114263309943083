export const ACTION_TYPE_SESSION = {
  GET_REFRESH_TOKEN: 'GET_REFRESH_TOKEN',
  GET_REFRESH_TOKEN_SUCCESS: 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_ERROR: 'GET_REFRESH_TOKEN_ERROR',

  FAILED_API_LIST: 'FAILED_API_LIST',

  GET_ACCESS_TOKEN_EXPIRY: 'GET_ACCESS_TOKEN_EXPIRY',

  GET_ACCESS_TOKEN: 'GET_ACCESS_TOKEN',

  GET_SINGLE_USE_TOKEN: 'GET_SINGLE_USE_TOKEN',
  GET_SINGLE_USE_TOKEN_SUCCESS: 'GET_SINGLE_USE_TOKEN_SUCCESS',
  GET_SINGLE_USE_TOKEN_ERROR: 'GET_SINGLE_USE_TOKEN_ERROR',
}
