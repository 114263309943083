import './ReportLostCard.scss'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import React, { Component } from 'react'
import { Button } from 'yacob-components'
import { Textarea } from 'yacob-components'
import { TitleDescription } from 'yacob-components'
import { ReportCardLostSuccess } from 'yacob-components'

import { ROUTES as _routes } from '../../constants/app-constants'
import {
  ACTION_TYPE_CHILD,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'

class ReportLostCardTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      childuuid: '',
      currentProgress: 'confirmation',
      reasonValue: '',
      showloader: false,
    }
  }

  componentDidMount() {
    const { location } = this.props
    const { state } = location
    if (state && state.uuid) this.setState({ childuuid: state.uuid })
    else this.historyPush(_routes.HOME)
  }

  componentWillReceiveProps(nextProps) {
    const { childData } = this.props
    if (
      nextProps.childData &&
      nextProps.childData.reportCardLost &&
      nextProps.childData.reportCardLost !== childData.reportCardLost
    ) {
      this.setState({ showloader: false })
      if (
        !nextProps.childData.reportCardLost.error &&
        nextProps.childData.reportCardLost.result
      ) {
        this.setState({ currentProgress: 'submitsuccess' })
      } else if (
        !nextProps.childData.reportCardLost.error &&
        !nextProps.childData.reportCardLost.result
      ) {
        this.alert({
          type: 'error',
          content: 'Email inviation failed but your report has been generated',
        })
      } else if (nextProps.childData.reportCardLost.error) {
        this.alert({
          type: 'error',
          content: nextProps.childData.reportCardLost.error,
        })
      }
    }
  }

  onChangeHandler = e => {
    this.setState({ reasonValue: e.target.value })
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  historyPush = () => {
    const { history } = this.props
    history.push(_routes.HOME)
  }

  confirmHandler = () => {
    const { childuuid, reasonValue } = this.state
    if (!reasonValue) {
      this.alert({ type: 'error', content: 'Please enter the valid reason' })
    } else {
      this.setState({ showloader: true })
      const { getReportLostCard } = this.props
      const data = {
        uuid: childuuid,
        payload: {
          reason: reasonValue,
        },
      }
      getReportLostCard(data)
    }
  }

  cancelHandler = () => {
    this.historyPush()
  }

  successContinueClick = () => {
    const { history } = this.props
    history.replace(_routes.HOME)
  }

  renderContent = () => {
    const { currentProgress, reasonValue, showloader } = this.state
    const { t } = this.props
    let dom

    switch (currentProgress) {
      case 'confirmation':
        dom = (
          <div className="reportcardlost">
            <TitleDescription
              title={t(
                'Are you sure you want to report this card as lost/stolen?',
              )}
              description={t(
                'The card will be disabled and a charge of X AED will be applied to issue a new card',
              )}
            />
            <div className="reportcardlost__textarea">
              <Textarea
                title={t('Reason')}
                value={reasonValue}
                onChange={this.onChangeHandler}
              />
            </div>
            <div className="reportcardlost__button">
              <div className="reportcardlost__button_btn">
                <Button
                  text={t('Cancel')}
                  color="lightgrey"
                  size="big"
                  type="block"
                  className="u_margin_1rem_top_bot"
                  onClick={this.cancelHandler}
                  disabled={showloader}
                />
              </div>
              <div className="reportcardlost__button_btn">
                <Button
                  text={t('Confirm')}
                  size="big"
                  type="block"
                  className="u_margin_1rem_top_bot"
                  onClick={this.confirmHandler}
                  showloader={showloader}
                />
              </div>
            </div>
          </div>
        )
        break
      case 'submitsuccess':
        dom = (
          <ReportCardLostSuccess successonClick={this.successContinueClick} />
        )
        break
      default:
        dom = null
    }
    return dom
  }

  render() {
    return (
      <div className="reportcardlost_container">{this.renderContent()}</div>
    )
  }
}

const mapStateToProps = state => {
  return {
    childData: state.child,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getReportLostCard: data =>
      dispatch({
        type: ACTION_TYPE_CHILD.REPORTING_CARD_LOST,
        data,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const ReportLostCard = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ReportLostCardTrans)),
)
