import './SchoolInfo.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import { Button } from 'yacob-components'
import { TitleDescription } from 'yacob-components'
import { Loader } from 'yacob-components'

import CompleteDesktopIcon from '../../assets/complete-desktop.svg'
import CompleteMobileIcon from '../../assets/complete-mobile.svg'
import CircleIcon from '../../assets/circle.svg'

import {
  ACTION_TYPE_PRE_AUTH_DETAILS,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'

import {
  SUPPORT_EMAIL,
  PAYMENT_ID,
  ROUTES,
} from '../../constants/app-constants'

import { LocalStorage } from '../../utils/service'

const componentClass = class SchoolInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: true,
      schoolInfo: {},
      buttonLoader: false,
      payment: '',
      paymentStatusData: {},
    }
  }

  componentDidMount() {
    const {
      match,
      getSchoolInformation,
      getPaymentDetail,
      history,
    } = this.props
    let code = match.params.schoolCode
    if (!code) {
      return
    }
    if (history.location.search === '') {
      getSchoolInformation(code, () => {
        const { schoolInformation } = this.props
        this.setState({
          schoolInfo: schoolInformation.getSchoolInformation,
        })
        if (
          schoolInformation.getSchoolInformation &&
          schoolInformation.getSchoolInformation.school_pre_auth &&
          schoolInformation.getSchoolInformation.school_pre_auth.require_payment
        ) {
          let uuid =
            schoolInformation.getSchoolInformation.school_pre_auth.school_uuid
          const queryParam = {
            school_uuid: uuid,
            school_code: match.params.schoolCode,
          }
          getPaymentDetail(queryParam, () => {
            const { schoolInformation } = this.props
            this.setState({
              payment: schoolInformation.payment.user_pre_auth_details,
            })
          })
        }
        this.setState({ showLoader: false })
      })
    } else {
      this.checkPathSearch()
      this.setState({ showLoader: false })
    }
  }

  copyCodeToClipboard = () => {
    const { showAlertMessage } = this.props
    window.navigator.clipboard.writeText(document.location.href)
    showAlertMessage({
      type: 'success',
      content: 'Link Copied Successfully',
    })
  }

  contactSupportMail = () => {
    window.location.href = 'mailto:' + SUPPORT_EMAIL
  }

  paymentGateway = () => {
    const { createPreAuthPayment, showAlertMessage, match } = this.props
    const { schoolInfo } = this.state
    this.setState({ buttonLoader: true })
    const payload = {
      school_uuid: schoolInfo.school_pre_auth.school_uuid,
      payment_type: 'Credit card',
      return_url: `${window.location.origin}/schools/${match.params.schoolCode}/status`,
    }

    createPreAuthPayment(payload, () => {
      const { schoolInformation } = this.props
      this.setState({ buttonLoader: false })
      if (
        schoolInformation.createPayment &&
        schoolInformation.createPayment.payment !== null
      ) {
        this.paymentGatewayRedirect(
          schoolInformation.createPayment.payment_page,
        )
      } else if (
        schoolInformation.createPayment &&
        schoolInformation.createPayment.error
      ) {
        showAlertMessage({
          type: 'error',
          content: schoolInformation.createPayment.error,
        })
      }
    })
  }

  paymentGatewayRedirect = data => {
    window.open(data.payment_url, '_self')
    LocalStorage.setData(PAYMENT_ID, data.tran_ref)
  }

  checkPathSearch = () => {
    const { history } = this.props
    if (history.location && history.location.search) {
      const searchqueryjson = decodeURI(history.location.search)
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce((values, [key, value]) => {
          values[key] = value
          return values
        }, {})
      const message = {
        status: searchqueryjson.status.toLowerCase(),
        data: searchqueryjson.api_result,
      }
      this.setState({ paymentStatusData: message })
    }
  }

  historyPush = path => {
    const { history } = this.props
    history.push(path)
  }

  goToDashboard = () => {
    this.historyPush(ROUTES.HOME)
  }

  goToLogin = () => {
    this.historyPush(ROUTES.LOGIN)
  }

  render() {
    // TODO: need to add translation

    const {
      schoolInfo,
      buttonLoader,
      showLoader,
      payment,
      paymentStatusData,
    } = this.state
    return (
      <div className="schoolinfo">
        {showLoader && <Loader key="loader" fitContent="full" />}
        {schoolInfo && schoolInfo.school_pre_auth && (
          <div className="schoolinfo__container" key="success">
            <TitleDescription title={schoolInfo.school_pre_auth.school.name} />
            <div className="schoolinfo__container__details">
              <p className="schoolinfo__container__details_para">
                To start delivering healthy food to the kids in this school we
                need more parents to signup.
              </p>
              <p className="schoolinfo__container__details_para">
                Share this page with your kids classmates parents!
              </p>
              <p className="schoolinfo__container__details_signup">
                {schoolInfo.school_pre_auth.required_request_count -
                  schoolInfo.school_pre_auth.required_count}{' '}
                more parents need to signup
              </p>
              <p className="schoolinfo__container__details_para">
                {schoolInfo.school_pre_auth.required_count} out{' '}
                {schoolInfo.school_pre_auth.required_request_count} already
                signed up
              </p>
            </div>
            <div className="schoolinfo__container__button">
              <Button
                size="small"
                text="Share this page"
                onClick={this.copyCodeToClipboard}
              />
            </div>

            {payment !== '' && payment !== undefined && payment.has_user_paid && (
              <p className="schoolinfo__container__details">
                You have already paid
                <span className="schoolinfo__container__details_bold">
                  {' '}
                  {schoolInfo.school_pre_auth.amount} AED{' '}
                </span>
                which will be available on your account’s balance once school is
                active.
              </p>
            )}

            {payment !== '' && payment !== undefined && !payment.has_user_paid && (
              <>
                <div className="schoolinfo__container__details">
                  <p className="schoolinfo__container__details_para">
                    This school requires a deposit of
                    <span className="schoolinfo__container__details_bold">
                      {' '}
                      {schoolInfo.school_pre_auth.amount} AED{' '}
                    </span>
                    which will be available on your account’s balance once
                    school is active.
                  </p>
                </div>
                <div className="schoolinfo__container__button">
                  <Button
                    size="small"
                    text="Proceed to Payment"
                    onClick={this.paymentGateway}
                    showloader={buttonLoader}
                  />
                </div>
              </>
            )}

            {payment === undefined &&
              schoolInfo.school_pre_auth.require_payment && (
                <>
                  <div className="schoolinfo__container__details">
                    <p className="schoolinfo__container__details_para">
                      This school requires a deposit of
                      <span className="schoolinfo__container__details_bold">
                        {' '}
                        {schoolInfo.school_pre_auth.amount} AED{' '}
                      </span>
                      which will be available on your account’s balance once
                      school is active.
                    </p>
                  </div>
                  <div className="schoolinfo__container__button">
                    <Button
                      size="small"
                      text="Login"
                      onClick={this.goToLogin}
                      showloader={buttonLoader}
                    />
                  </div>
                </>
              )}
          </div>
        )}
        {schoolInfo && schoolInfo.error === 'SchoolPreAuth not found' && (
          <div className="schoolinfo__container">
            <TitleDescription title={schoolInfo.school.name} />
            <div className="schoolinfo__container__details">
              <p className="schoolinfo__container__details_para">
                Unfortunately we haven't proccessed the school yet.
              </p>
              <p className="schoolinfo__container__details_para">
                Please consider contacting us
              </p>
            </div>
            <div className="schoolinfo__container__button">
              <Button
                size="small"
                text="Contact Support"
                onClick={this.contactSupportMail}
              />
            </div>
          </div>
        )}
        {schoolInfo && schoolInfo.error === 'School not found' && (
          <div className="schoolinfo__container">
            <TitleDescription title={schoolInfo.error} />
            <div className="schoolinfo__container__details">
              <p className="schoolinfo__container__details_para">
                Unfortunately we don't have this school in our database.
              </p>
              <p className="schoolinfo__container__details_para">
                Please check the school code in the url
              </p>
              <p className="schoolinfo__container__details_para">
                You can contact out support
              </p>
            </div>
            <div className="schoolinfo__container__button">
              <Button
                size="small"
                text="Contact Support"
                onClick={this.contactSupportMail}
                showLoader={buttonLoader}
              />
            </div>
          </div>
        )}
        {paymentStatusData &&
          paymentStatusData.status &&
          paymentStatusData.status !== 'success' && (
            <div className="schoolinfo__container">
              <div className="schoolinfo__container__image">
                <img src={CircleIcon} alt="circle" />
                <div className="schoolinfo__container__image_error">!</div>
              </div>
              <div className="schoolinfo__container__text">
                <TitleDescription title={paymentStatusData.data} />
              </div>
              <div className="schoolinfo__container__btn">
                <Button
                  text="Try Again"
                  size="big"
                  color="lightgrey"
                  onClick={this.goToDashboard}
                />
              </div>
            </div>
          )}

        {paymentStatusData && paymentStatusData.status === 'success' && (
          <div className="schoolinfo__container">
            <div className="schoolinfo__container_image">
              <img
                src={CompleteDesktopIcon}
                alt="complete"
                className="schoolinfo__container__image_desktop"
              />
              <img
                src={CompleteMobileIcon}
                alt="complete"
                className="schoolinfo__container__image_mobile"
              />
            </div>
            <div className="schoolinfo__container__text">
              <TitleDescription title={paymentStatusData.data} />
            </div>
            <div className="schoolinfo__container__button">
              <Button
                text="GO TO DASHBOARD"
                size="big"
                onClick={this.goToDashboard}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    schoolInformation: state.preAuthDetail,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSchoolInformation: (data, callback) =>
      dispatch({
        type: ACTION_TYPE_PRE_AUTH_DETAILS.GET_SCHOOL_INFORMATION,
        data,
        callback,
      }),
    showAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
    getPaymentDetail: (data, callback) =>
      dispatch({
        type: ACTION_TYPE_PRE_AUTH_DETAILS.GET_PRE_AUTH_PAYMENT_DETAILS,
        data,
        callback,
      }),
    createPreAuthPayment: (data, callback) =>
      dispatch({
        type: ACTION_TYPE_PRE_AUTH_DETAILS.CREATE_PRE_AUTH_PAYMENT,
        data,
        callback,
      }),
  }
}

export const SchoolInfo = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(componentClass)),
)
