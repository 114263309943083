import { ACTION_TYPE_LUNCHORDER } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  checkoutData: null,
  checkoutVerifyData: null,
}

export default function lunchOrderReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_LUNCHORDER.GET_MENU_LIST:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_LUNCHORDER.GET_MENU_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response,
      }

    case ACTION_TYPE_LUNCHORDER.GET_MENU_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_LUNCHORDER.ADD_CART_CHECKOUT:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_LUNCHORDER.ADD_CART_CHECKOUT_SUCCESS:
      return {
        ...state,
        fetching: false,
        checkoutData: action.response,
      }

    case ACTION_TYPE_LUNCHORDER.ADD_CART_CHECKOUT_ERROR:
      return {
        ...state,
        fetching: false,
        checkoutData: null,
        error: action.error,
      }

    case ACTION_TYPE_LUNCHORDER.CART_CHECKOUT_VERIFY:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_LUNCHORDER.CART_CHECKOUT_VERIFY_SUCCESS:
      return {
        ...state,
        fetching: false,
        checkoutVerifyData: action.response,
      }

    case ACTION_TYPE_LUNCHORDER.CART_CHECKOUT_VERIFY_ERROR:
      return {
        ...state,
        fetching: false,
        checkoutVerifyData: null,
        error: action.error,
      }

    default:
      return state
  }
}
