import './ResetPassword.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { ResetPassword as ResetPasswordEmail } from 'yacob-components'
import { ResetCheck as ResetCheckEmail } from 'yacob-components'
import { CreateNewPassword } from 'yacob-components'
import { ResetDone as ResetPasswordDone } from 'yacob-components'

import {
  EmailValidation,
  ConfirmPasswordValidation,
  PasswordValidation,
} from '../../utils/validation'
import {
  ACTION_TYPE_LOGIN,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'
import { ROUTES as _routes } from '../../constants/app-constants'

class ResetPasswordBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStatus: 'reset',
      validation: {
        email: false,
        newPassword: false,
        confirmNewPassword: false,
      },
      inputvalue: {
        email: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      token: '',
      currentFieldFocus: 'email',
      btnshowloader: false,
    }
  }

  componentDidMount() {
    const { location } = this.props
    const { search } = location
    if (search) {
      this.setNewPassword(search)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { loginData } = this.props
    if (
      nextProps.loginData &&
      nextProps.loginData.forgotPassword &&
      nextProps.loginData.forgotPassword !== loginData.forgotPassword
    ) {
      this.setState({ btnshowloader: false })
      if (nextProps.loginData.forgotPassword.error) {
        this.alert({
          type: 'error',
          content: nextProps.loginData.forgotPassword.error,
        })
      } else {
        this.setState({ currentStatus: 'check' })
      }
    }
    if (
      nextProps.loginData &&
      nextProps.loginData.resetPassword &&
      nextProps.loginData.resetPassword !== loginData.resetPassword
    ) {
      this.setState({ btnshowloader: false })
      if (nextProps.loginData.resetPassword.error) {
        this.alert({
          type: 'error',
          content: nextProps.loginData.resetPassword.error,
        })
      } else {
        this.setState({ currentStatus: 'done' })
      }
    }
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  setNewPassword = data => {
    const dataSplit = data.split('=')
    const [, tokenValue] = dataSplit
    if (tokenValue) {
      this.setState({
        currentStatus: 'newpassword',
        currentFieldFocus: 'newPassword',
        token: tokenValue,
      })
    }
  }

  validationChangeHandler = (id, value) => {
    const { validation } = this.state
    const validationCopy = { ...validation }
    validationCopy[id] = value
    this.setState({ validation: validationCopy })
  }

  onFocusInput = id => {
    this.setState({ currentFieldFocus: id })
  }

  onChangeInput = e => {
    const { target } = e
    const { id } = target
    const { value } = target
    const { inputvalue } = this.state
    const valueCopy = { ...inputvalue }
    valueCopy[id] = value
    this.setState({ inputvalue: valueCopy })
  }

  validateResetEmail = () => {
    const { validation, inputvalue } = this.state
    const validationCopy = { ...validation }
    validationCopy.email = !EmailValidation(inputvalue.email)
    this.setState({ validation: validationCopy })
    return validationCopy
  }

  validateNewPasswordEmail = () => {
    const { validation, inputvalue } = this.state
    const validationCopy = { ...validation }
    validationCopy.newPassword = !PasswordValidation(inputvalue.newPassword)
    validationCopy.confirmNewPassword =
      !inputvalue.confirmNewPassword ||
      !ConfirmPasswordValidation(
        inputvalue.newPassword,
        inputvalue.confirmNewPassword,
      )
    this.setState({ validation: validationCopy })
    return validationCopy
  }

  proceedToLoginHandler = () => {
    const { history } = this.props
    history.replace(_routes.LOGIN)
  }

  validationAlert = key => {
    const { inputvalue } = this.state
    if (key === 'confirmNewPassword' && inputvalue.confirmNewPassword) {
      this.alert({ type: 'error', content: "Passwords don't match" })
    } else if (key === 'newPassword' && inputvalue.newPassword) {
      this.alert({
        type: 'error',
        content:
          'Password must be a combination of upper case, lower case, and number',
      })
    }
  }

  renderContent = () => {
    const {
      currentStatus,
      validation,
      inputvalue,
      currentFieldFocus,
      btnshowloader,
    } = this.state
    const {
      resetConfirmHandler,
      createNewPasswordHandler,
      validationChangeHandler,
      onChangeInput,
      onFocusInput,
    } = this
    let DOM
    switch (currentStatus) {
      case 'reset':
        DOM = (
          <ResetPasswordEmail
            onClick={resetConfirmHandler}
            validation={validation}
            inputvalue={inputvalue}
            currentFieldFocus={currentFieldFocus}
            onChangeInput={onChangeInput}
            onFocusInput={onFocusInput}
            validationChangeHandler={validationChangeHandler}
            btnshowloader={btnshowloader}
          />
        )
        break
      case 'check':
        DOM = <ResetCheckEmail />
        break
      case 'newpassword':
        DOM = (
          <CreateNewPassword
            onClick={createNewPasswordHandler}
            validation={validation}
            inputvalue={inputvalue}
            currentFieldFocus={currentFieldFocus}
            onChangeInput={onChangeInput}
            onFocusInput={onFocusInput}
            validationChangeHandler={validationChangeHandler}
            btnshowloader={btnshowloader}
          />
        )
        break
      case 'done':
        DOM = <ResetPasswordDone onClick={this.proceedToLoginHandler} />
        break
      default:
        DOM = null
    }
    return DOM
  }

  resetConfirmHandler = () => {
    const validation = this.validateResetEmail()
    if (
      Object.keys(validation).every(k => {
        this.setState({ currentFieldFocus: k })
        return !validation[k]
      })
    ) {
      const { getForgotPassword } = this.props
      const { inputvalue } = this.state
      const data = {
        email: inputvalue.email.toLowerCase(),
      }
      this.setState({ btnshowloader: true })
      getForgotPassword(data)
    }
  }

  createNewPasswordHandler = () => {
    const validation = this.validateNewPasswordEmail()
    if (
      Object.keys(validation).every(k => {
        this.setState({ currentFieldFocus: k })
        if (validation[k]) this.validationAlert(k)
        return !validation[k]
      })
    ) {
      const { getResetPassword } = this.props
      const { token, inputvalue } = this.state
      const data = {
        token,
        new_password: inputvalue.newPassword,
      }
      this.setState({ btnshowloader: true })
      getResetPassword(data)
    }
  }

  render() {
    const DOM = (
      <div className="container">
        <div className="resetPassword">{this.renderContent()}</div>
      </div>
    )
    return DOM
  }
}

const mapStateToProps = state => {
  return {
    loginData: state.login,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getForgotPassword: data =>
      dispatch({
        type: ACTION_TYPE_LOGIN.GET_FORGOT_PASSWORD,
        data,
      }),
    getResetPassword: data =>
      dispatch({
        type: ACTION_TYPE_LOGIN.GET_RESET_PASSWORD,
        data,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const ResetPassword = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordBase),
)
