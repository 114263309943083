import './SpendingLimits.scss'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { TitleDescription } from 'yacob-components'
import { LimitCard } from 'yacob-components'
import { AutoSuggestion } from 'yacob-components'
import { Button } from 'yacob-components'

import {
  getSuggestionsDailyLimit,
  getSuggestionValueDailyLimit,
  renderSuggestionDailyLimit,
} from '../../utils/autosuggest'
import { ACTION_TYPE_SPENDINGLIMITS } from '../../redux/actions'
import { LocalStorage } from '../../utils/service'

class SpendingLimitsTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentFieldFocus: '',
      inputvalue: {
        dailylimit: `${LocalStorage.getCurrency()} `,
        weeklylimit: `${LocalStorage.getCurrency()} `,
        monthlylimit: `${LocalStorage.getCurrency()} `,
        uuid: '',
      },
      validation: {
        dailylimit: false,
        weeklylimit: false,
        monthlylimit: false,
      },
      isUpdation: false,
      dailylimitDropdown: [],
      weeklylimitDropdown: [],
      monthlylimitDropdown: [],
      childData:
        props.location && props.location.state && props.location.state.data,
      childuuid:
        props.location &&
        props.location.state &&
        props.location.state.data &&
        props.location.state.data.uuid,
      showloader: false,
    }
  }

  componentDidMount() {
    const {
      getSpendingLimits,
      location,
      getSpendingLimitsDefaults,
    } = this.props
    const { state } = location
    getSpendingLimitsDefaults()
    if (state && state.data && state.data.uuid)
      getSpendingLimits(state.data.uuid)
  }

  componentWillReceiveProps(nextProps) {
    const { limitData } = this.props
    if (
      nextProps.limitData &&
      nextProps.limitData.data !== limitData.data &&
      nextProps.limitData.data.spend_limits
    ) {
      this.setStateLimit(nextProps.limitData.data.spend_limits)
    }
    if (
      nextProps.limitData &&
      nextProps.limitData.addLimit !== limitData.addLimit
    ) {
      this.setState({ showloader: false })
      if (nextProps.limitData.addLimit.spend_limit)
        this.setState({ isUpdation: true })
    }
    if (
      nextProps.limitData &&
      nextProps.limitData.limitdefaults !== limitData.limitdefaults &&
      nextProps.limitData.limitdefaults.spend_limit_defaults
    ) {
      this.setDropdownValue(
        nextProps.limitData.limitdefaults.spend_limit_defaults,
      )
    }
    if (
      nextProps.limitData &&
      nextProps.limitData.editLimit !== limitData.editLimit
    ) {
      this.setState({ showloader: false })
    }
  }

  onFocusInput = id => {
    this.setState({ currentFieldFocus: id })
  }

  onChangeInput = (id, value) => {
    const { inputvalue } = this.state
    const valueCopy = { ...inputvalue }
    const currency = LocalStorage.getCurrency()
    if (value.length < currency.length + 1 && value.match(currency))
      value = `${currency} `
    else if (value.indexOf(`${currency} `) !== 0) value = `${currency} ${value}`

    valueCopy[id] = value
    this.setState({ inputvalue: valueCopy })
  }

  setStateLimit = data => {
    if (data && data.length) {
      const { childuuid, inputvalue } = this.state
      const currency = LocalStorage.getCurrency()
      Object.keys(data).forEach(key => {
        if (data[key].child_uuid === childuuid) {
          inputvalue.dailylimit = `${currency} ${data[key].daily_limit}`
          inputvalue.weeklylimit = `${currency} ${data[key].weekly_limit}`
          inputvalue.monthlylimit = `${currency} ${data[key].monthly_limit}`
          inputvalue.uuid = data[key].uuid
          this.setState({ inputvalue, isUpdation: true })
        }
      })
    }
  }

  setDropdownValue = data => {
    Object.keys(data).forEach(key => {
      const limitType = `${data[key].limit_type.toLowerCase()}limitDropdown`
      this.setState({ [limitType]: data[key].limits })
    })
  }

  removePrefix = data => {
    if (data.indexOf(`${LocalStorage.getCurrency()} `) > -1) {
      data = data.substr(4)
    }
    return Number(data)
  }

  addSpendingLimits = () => {
    const { addSpendingLimits, editSpendingLimits } = this.props
    const { childuuid, inputvalue, isUpdation } = this.state
    const payload = {
      child_uuid: childuuid,
      daily_limit: this.removePrefix(inputvalue.dailylimit),
      weekly_limit: this.removePrefix(inputvalue.weeklylimit),
      monthly_limit: this.removePrefix(inputvalue.monthlylimit),
    }
    this.setState({ showloader: true })
    if (isUpdation) {
      const data = {
        uuid: inputvalue.uuid,
        payload,
      }
      editSpendingLimits(data)
    } else addSpendingLimits(payload)
  }

  render() {
    const {
      currentFieldFocus,
      childData,
      showloader,
      validation,
      inputvalue,
      dailylimitDropdown,
      weeklylimitDropdown,
      monthlylimitDropdown,
    } = this.state
    const { t } = this.props
    const name = `${childData.first_name} ${childData.last_name}`

    const DOM = (
      <div className="spendinglimit_container">
        <div className="spendinglimit">
          <TitleDescription title={`${name} — ${t('spending_limits')}`} />
          <div className="spendinglimit__card">
            <LimitCard mainText={t('daily_average_spend')} subText="AED 87" />
          </div>
          <div className="spendinglimit__input">
            <AutoSuggestion
              id="dailylimit"
              label={t('daily_limit')}
              type="text"
              value={inputvalue.dailylimit}
              currentFieldFocus={currentFieldFocus}
              onChange={this.onChangeInput}
              onFocus={this.onFocusInput}
              suggestionList={dailylimitDropdown}
              validation={validation.dailylimit}
              validationChange={this.validationChangeHandler}
              getSuggestions={getSuggestionsDailyLimit}
              getSuggestionValue={getSuggestionValueDailyLimit}
              renderSuggestion={renderSuggestionDailyLimit}
            />
            <AutoSuggestion
              id="weeklylimit"
              label={t('weekly_limit')}
              type="text"
              value={inputvalue.weeklylimit}
              currentFieldFocus={currentFieldFocus}
              onChange={this.onChangeInput}
              onFocus={this.onFocusInput}
              suggestionList={weeklylimitDropdown}
              validation={validation.weeklylimit}
              validationChange={this.validationChangeHandler}
              getSuggestions={getSuggestionsDailyLimit}
              getSuggestionValue={getSuggestionValueDailyLimit}
              renderSuggestion={renderSuggestionDailyLimit}
            />
            <AutoSuggestion
              id="monthlylimit"
              label={t('monthly_limit')}
              type="text"
              value={inputvalue.monthlylimit}
              currentFieldFocus={currentFieldFocus}
              onChange={this.onChangeInput}
              onFocus={this.onFocusInput}
              suggestionList={monthlylimitDropdown}
              validation={validation.monthlylimit}
              validationChange={this.validationChangeHandler}
              getSuggestions={getSuggestionsDailyLimit}
              getSuggestionValue={getSuggestionValueDailyLimit}
              renderSuggestion={renderSuggestionDailyLimit}
            />
            <Button
              text={t('save')}
              size="big"
              type="block"
              showloader={showloader}
              onClick={this.addSpendingLimits}
            />
          </div>
        </div>
      </div>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    limitData: state.limits,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSpendingLimits: data =>
      dispatch({
        type: ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS,
        data,
      }),
    getSpendingLimitsDefaults: data =>
      dispatch({
        type: ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_DEFAULTS,
        data,
      }),
    addSpendingLimits: data =>
      dispatch({
        type: ACTION_TYPE_SPENDINGLIMITS.ADD_SPENDING_LIMITS,
        data,
      }),
    editSpendingLimits: data =>
      dispatch({
        type: ACTION_TYPE_SPENDINGLIMITS.EDIT_SPENDING_LIMITS,
        data,
      }),
  }
}

export const SpendingLimits = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(SpendingLimitsTrans)),
)
