export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const ENDPOINT = {
  USER: 'users/',
  FACULTY_USER: 'faculties/',
  LOGIN: 'users/login',
  FACULTY_LOGIN: 'faculties/login',
  SIGNUP: 'users/signup',
  FACULTY_SIGNUP: 'faculties/signup',
  CHANGE_PASSWORD: 'auth/change-password',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  REFRESH_TOKEN: 'auth/token/refresh',
  ADDCHILD_SCHOOL: 'schools/search',
  ADDCHILD_GRADE: 'schools/grades/',
  ADDCHILD_SECTION: 'schools/sections/',
  CHILD_ALLERGY: 'allergies/',
  CHILD_ALLERGY_ADD: 'children/set-allergies',
  PARENT_CHILD_ALLERGY: 'children/allergies/',
  CHILD_DIET: 'diets/',
  ADDCHILD_CREATE: 'children/',
  CHILD_LIST: 'children/parent/',
  SPENDING_LIMITS: 'spend-limits/',
  SPENDING_LIMITS_DEFAULTS: 'spend-limit-defaults/',
  MENU_LIST: 'food-items/menu/',
  CHECKOUT: 'orders/',
  ORDER_VERIFY: 'orders/verify',
  PARENT_TOPUP: 'payments/account/top-up',
  VERIFY_PAYMENT: 'payments/verify',
  CHILD_TOPUP: 'payments/child/top-up',
  NUTRITION_CONSUMPTION: 'nutrition/consumption/',
  NUTRITION_ITEM: 'nutrition/items/',
  NUTRITION_INSIGNHT_ADVICE: 'nutrition/insights/',
  REFERRAL_INVITATION: 'users/referrals/send-invites',
  REFERRAL_HISTORY: 'users/referrals/history/',
  REFERRAL_URL: 'users/referrals/',
  ACCOUNT_TRANSACTION: 'payments/history/',
  ACCOUNT_TRANSACTION_DOWNLOAD: 'payments/history/download/',
  NOTIFICATION: 'notification/settings/',
  TRANSACTIONS: '/transactions',
  REPORT_CARD_LOST: 'children/lost-card/',
  SINGLE_USE_TOKEN: 'auth/single-use/token',
  SCHOOL_INFORMATION: 'school-pre-auth/by-code/',
  SCHOOL_PRE_AUTH_PAYMENT_DETAILS: 'school-pre-auth/user/',
  SCHOOL_PRE_AUTH_PAYMENT: 'payments/pre-auth-payment',
}
