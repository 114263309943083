export const ROUTES = {
  SCHOOL_SELECT: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset-password',
  HOME: '/dashboard',
  DELETE_CHILDREN: '/delete-children',
  CHANGE_PASSWORD: '/change-password',
  LUNCH_ORDER: '/lunch-order',
  SPENDING_LIMITS: '/spending-limits',
  EARN_CREDIT: '/earn-credits',
  LUNCH_SUBSCRIBE: '/lunch-subscribe',
  CHILD_TOPUP: '/child-topup',
  ACCOUNT_TOPUP: '/account-topup',
  NUTRITION: '/nutrition',
  CONTACT_DETAILS: '/contact-details',
  NOTIFICATION: '/notification',
  TRANSACTION_HISTORY: '/transaction-history',
  CHILD_TRANSACTION_HISTORY: '/child-transaction-history',
  REPORT_CARD_LOST: '/reporting-card-lost',
  SCHOOL_INFO: '/schools',
}

export const ACCESS_TOKEN = 'ATKN'
export const REFRESH_TOKEN = 'RTKN'
export const USER_DATA = 'USERDATA'
export const PAYMENT_ID = 'PID'

// eslint-disable-next-line global-require
export const LOGO_FILE_PATH = require('../assets/logo.1443f73c.png')

export const COMMON_ERROR_MSG = 'Something went wrong, please try again'
// export { COMMON_ERROR_MSG as default }

export const USER_ROLE = {
  FACULTY: 'FACULTY',
  PARENT: 'PARENT',
}

export const GA_ID = process.env.REACT_APP_GA_ID

export const VENDOR_APPROVAL_STATUS = {
  PENDING: 'PENDING',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  DOCUMENT_UPLOAD_PENDING: 'DOCUMENT_UPLOAD_PENDING',
  SERVICES_NOT_ENABLED: 'SERVICES_NOT_ENABLED',
  DOCUMENT_REJECTED: 'DOCUMENT_REJECTED',
  APPROVED: 'APPROVED',
}

export const VENDOR_COMPLIANCE_DOCUMENT_TYPE = {
  BUSINESS_LICENSE: 'BUSINESS_LICENSE',
  HACCP_LICENSE: 'HACCP_LICENSE',
  MUNICIPALITY_INSPECTION_REPORT: 'MUNICIPALITY_INSPECTION_REPORT',
}

export const SUPPORT_EMAIL = 'support@yacob.com'
