import './Login.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { TitleDescription } from 'yacob-components'
import { InputLabelFloat } from 'yacob-components'
import { Button } from 'yacob-components'
import { RadioButton } from 'yacob-components'

import {
  ROUTES as _routes,
  USER_ROLE as _userRoles,
} from '../../constants/app-constants'

import {
  EmailValidation,
  PasswordValidation,
  HasValueValidation,
} from '../../utils/validation'
import {
  ACTION_TYPE_LOGIN,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'

import { getSelectedSchool } from '../../utils/service'

export class LoginTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validation: {
        email: false,
        password: false,
      },
      inputvalue: {
        email: '',
        password: '',
      },
      currentFieldFocus: 'email',
      showloader: false,
      roleSelection: _userRoles.PARENT,
    }
  }

  componentDidMount() {
    const selectedSchool = getSelectedSchool()

    if (!selectedSchool) {
      return this.props.history.push(_routes.SCHOOL_SELECT)
    }

    this.setState({ selectedSchool })

    const { setProgressData } = this.props
    setProgressData({}, false, 0)
    if (
      document &&
      document.getElementsByClassName('login_input') &&
      document.getElementsByClassName('login_input')[0]
    )
      document
        .getElementsByClassName('login_input')[0]
        .addEventListener('keyup', this.enterListener)
  }

  componentWillReceiveProps(nextProps) {
    // FIXME
    const { loginData } = this.props
    if (
      nextProps.loginData &&
      nextProps.loginData.data &&
      nextProps.loginData.data !== loginData.data
    ) {
      this.setState({ showloader: false })
      if (nextProps.loginData.data.error) {
        return this.alert({
          type: 'error',
          content: nextProps.loginData.data.error,
        })
      }
      const { history } = this.props
      const { status, code } = this.state.selectedSchool
      if (status !== 'ACTIVE') {
        return history.push(`${_routes.SCHOOL_INFO}/${code}`)
      }
      history.push(_routes.HOME)
    }
  }

  componentWillUnmount() {
    if (
      document &&
      document.getElementsByClassName('login_input') &&
      document.getElementsByClassName('login_input')[0]
    )
      document
        .getElementsByClassName('login_input')[0]
        .removeEventListener('keyup', this.enterListener)
  }

  enterListener = event => {
    event.preventDefault()
    if (event.keyCode === 13) {
      this.onClick()
    }
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  validationChangeHandler = (id, value) => {
    const { validation } = this.state
    const validationCopy = { ...validation }
    validationCopy[id] = value
    this.setState({ validation: validationCopy })
  }

  validationOnClickHandler = () => {
    const { validation, inputvalue } = this.state
    const validationCopy = { ...validation }
    validationCopy.email = !EmailValidation(inputvalue.email)
    validationCopy.passwordHasValue = !HasValueValidation(inputvalue.password)
    validationCopy.password = !PasswordValidation(inputvalue.password)
    this.setState({ validation: validationCopy })
    return validationCopy
  }

  validationAlert = key => {
    if (key === 'email') {
      this.alert({ type: 'error', content: 'Please enter vaild email' })
    } else if (key === 'password') {
      this.alert({
        type: 'error',
        content:
          'Password must be a combination of upper case, lower case, and number',
      })
    } else if (key === 'passwordHasValue') {
      this.alert({ type: 'error', content: 'Please enter password' })
    }
  }

  onClick = () => {
    const validation = this.validationOnClickHandler()
    if (
      Object.keys(validation).every(k => {
        this.setState({ currentFieldFocus: k })
        if (validation[k]) this.validationAlert(k)
        return !validation[k]
      })
    ) {
      const { inputvalue, roleSelection } = this.state
      const { setLogin } = this.props
      this.setState({ showloader: true })
      inputvalue.email = inputvalue.email.toLowerCase()
      const data = {
        isFaculty: roleSelection === _userRoles.FACULTY || false,
        payload: inputvalue,
      }
      setLogin(data)
    }
  }

  onFocusInput = id => {
    this.setState({ currentFieldFocus: id })
  }

  onChangeInput = e => {
    const { target } = e
    const { id } = target
    const { value } = target
    const { inputvalue } = this.state
    const valueCopy = { ...inputvalue }
    valueCopy[id] = value
    this.setState({ inputvalue: valueCopy })
  }

  onChangeRadio = e => {
    this.setState({ roleSelection: e.target.value })
  }

  render() {
    const { onClick, onChangeInput } = this
    const {
      validation,
      inputvalue,
      currentFieldFocus,
      showloader,
      roleSelection,
    } = this.state
    const { t } = this.props

    const DOM = (
      <div className="login_container">
        <div className="login">
          <TitleDescription title={t('login')} />
          <div className="login_input">
            <form onSubmit={onClick}>
              <InputLabelFloat
                id="email"
                label={t('email')}
                type="email"
                required
                value={inputvalue.email}
                onChange={onChangeInput}
                validation={validation.email}
                validationChange={this.validationChangeHandler}
                onFocus={this.onFocusInput}
                currentFieldFocus={currentFieldFocus}
              />
              <InputLabelFloat
                id="password"
                label={t('password')}
                type="password"
                required
                value={inputvalue.password}
                onChange={onChangeInput}
                validation={validation.password}
                validationChange={this.validationChangeHandler}
                onFocus={this.onFocusInput}
                currentFieldFocus={currentFieldFocus}
              />
              <div className="login_input_radio">
                <RadioButton
                  name="roleSelection"
                  value={_userRoles.PARENT}
                  checked={roleSelection}
                  onChange={this.onChangeRadio}
                  key="Parent"
                  leftContent="Parent"
                />
                <RadioButton
                  name="roleSelection"
                  value={_userRoles.FACULTY}
                  checked={roleSelection}
                  onChange={this.onChangeRadio}
                  key="Faculty"
                  leftContent="Faculty"
                />
              </div>

              <Button
                onClick={onClick}
                showloader={showloader}
                type="block"
                text={t('login')}
                size="big"
              />
            </form>

            <NavLink to={_routes.RESET_PASSWORD} className="u_text_decoration">
              <div className="login_input_forgot">{t('forgot_password')}?</div>
            </NavLink>
          </div>
        </div>
      </div>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    loginData: state.login,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLogin: data =>
      dispatch({
        type: ACTION_TYPE_LOGIN.GET_LOGIN,
        data,
      }),
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const Login = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginTrans)),
)
