import { ACTION_TYPE_SPENDINGLIMITS } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  addLimit: null,
  editLimit: null,
  limitdefaults: null,
}

export default function spendingLimitsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response,
      }

    case ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_ERROR:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
      }

    case ACTION_TYPE_SPENDINGLIMITS.ADD_SPENDING_LIMITS:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SPENDINGLIMITS.ADD_SPENDING_LIMITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        addLimit: action.response,
      }

    case ACTION_TYPE_SPENDINGLIMITS.ADD_SPENDING_LIMITS_ERROR:
      return {
        ...state,
        fetching: false,
        addLimit: null,
        error: action.error,
      }

    case ACTION_TYPE_SPENDINGLIMITS.EDIT_SPENDING_LIMITS:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SPENDINGLIMITS.EDIT_SPENDING_LIMITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        editLimit: action.response,
      }

    case ACTION_TYPE_SPENDINGLIMITS.EDIT_SPENDING_LIMITS_ERROR:
      return {
        ...state,
        fetching: false,
        editLimit: null,
        error: action.error,
      }

    case ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_DEFAULTS:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_DEFAULTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        limitdefaults: action.response,
      }

    case ACTION_TYPE_SPENDINGLIMITS.GET_SPENDING_LIMITS_DEFAULTS_ERROR:
      return {
        ...state,
        fetching: false,
        limitdefaults: null,
        error: action.error,
      }

    default:
      return state
  }
}
