export const ACTION_TYPE_EARNCREDIT = {
  GET_REFERRAL_INVITATIONS: 'GET_REFERRAL_INVITATIONS',
  GET_REFERRAL_INVITATIONS_SUCCESS: 'GET_REFERRAL_INVITATIONS_SUCCESS',
  GET_REFERRAL_INVITATIONS_ERROR: 'GET_REFERRAL_INVITATIONS_ERROR',

  GET_REFERRAL_HISTORY: 'GET_REFERRAL_HISTORY',
  GET_REFERRAL_HISTORY_SUCCESS: 'GET_REFERRAL_HISTORY_SUCCESS',
  GET_REFERRAL_HISTORY_ERROR: 'GET_REFERRAL_HISTORY_ERROR',

  GET_REFERRAL_URL: 'GET_REFERRAL_URL',
  GET_REFERRAL_URL_SUCCESS: 'GET_REFERRAL_URL_SUCCESS',
  GET_REFERRAL_URL_ERROR: 'GET_REFERRAL_URL_ERROR',
}
