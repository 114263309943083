import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_PRE_AUTH_DETAILS, ACTION_TYPE_SESSION } from '../actions'

import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getSchoolInformation(action) {
  const { callback = () => {} } = action
  try {
    const url = `${BASE_URL}${ENDPOINT.SCHOOL_INFORMATION}${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    yield put({
      type: ACTION_TYPE_PRE_AUTH_DETAILS.GET_SCHOOL_INFORMATION_SUCCESS,
      response,
    })
    callback(response)
  } catch (error) {
    yield put({
      type: ACTION_TYPE_PRE_AUTH_DETAILS.GET_SCHOOL_INFORMATION_ERROR,
      error,
    })
    callback(error)
  }
}

function* getPayment(action) {
  const { callback = () => {} } = action
  try {
    const url = `${BASE_URL}${ENDPOINT.SCHOOL_PRE_AUTH_PAYMENT_DETAILS}?uuid=${action.data.school_uuid}&code=${action.data.school_code}`
    const response = yield call(fetchCall, url, 'GET')
    yield put({
      type: ACTION_TYPE_PRE_AUTH_DETAILS.GET_PRE_AUTH_PAYMENT_DETAILS_SUCCESS,
      response,
    })
    callback(response)
  } catch (error) {
    yield put({
      type: ACTION_TYPE_PRE_AUTH_DETAILS.GET_PRE_AUTH_PAYMENT_DETAILS_ERROR,
      error,
    })
    callback(error)
  }
}

function* createPayment(action) {
  const { callback = () => {} } = action
  try {
    const url = `${BASE_URL}${ENDPOINT.SCHOOL_PRE_AUTH_PAYMENT}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_PRE_AUTH_DETAILS.CREATE_PRE_AUTH_PAYMENT_SUCCESS,
        response,
      })
      callback(response)
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_PRE_AUTH_DETAILS.CREATE_PRE_AUTH_PAYMENT_ERROR,
      error,
    })
    callback(error)
  }
}

export function* watchpreAuthDetailSaga() {
  yield takeLatest(
    ACTION_TYPE_PRE_AUTH_DETAILS.GET_SCHOOL_INFORMATION,
    getSchoolInformation,
  )
  yield takeLatest(
    ACTION_TYPE_PRE_AUTH_DETAILS.GET_PRE_AUTH_PAYMENT_DETAILS,
    getPayment,
  )
  yield takeLatest(
    ACTION_TYPE_PRE_AUTH_DETAILS.CREATE_PRE_AUTH_PAYMENT,
    createPayment,
  )
}
const preAuthDetailSaga = [fork(watchpreAuthDetailSaga)]
export default preAuthDetailSaga
