export const ACTION_TYPE_NUTRITION = {
  GET_NUTRITION_CONSUMPTION: 'GET_NUTRITION_CONSUMPTION',
  GET_NUTRITION_CONSUMPTION_SUCCESS: 'GET_NUTRITION_CONSUMPTION_SUCCESS',
  GET_NUTRITION_CONSUMPTION_ERROR: 'GET_NUTRITION_CONSUMPTION_ERROR',

  GET_NUTRITION_ITEM: 'GET_NUTRITION_ITEM',
  GET_NUTRITION_ITEM_SUCCESS: 'GET_NUTRITION_ITEM_SUCCESS',
  GET_NUTRITION_ITEM_ERROR: 'GET_NUTRITION_ITEM_ERROR',

  GET_NUTRITION_INSIGHT_ADVICE: 'GET_NUTRITION_INSIGHT_ADVICE',
  GET_NUTRITION_INSIGHT_ADVICE_SUCCESS: 'GET_NUTRITION_INSIGHT_ADVICE_SUCCESS',
  GET_NUTRITION_INSIGHT_ADVICE_ERROR: 'GET_NUTRITION_INSIGHT_ADVICE_ERROR',
}
