export const ACTION_TYPE_TOPUP = {
  GET_PARENT_TOPUP: 'GET_PARENT_TOPUP',
  GET_PARENT_TOPUP_SUCCESS: 'GET_PARENT_TOPUP_SUCCESS',
  GET_PARENT_TOPUP_ERROR: 'GET_PARENT_TOPUP_ERROR',

  GET_VERIFY_PAYMENT: 'GET_VERIFY_PAYMENT',
  GET_VERIFY_PAYMENT_SUCCESS: 'GET_VERIFY_PAYMENT_SUCCESS',
  GET_VERIFY_PAYMENT_ERROR: 'GET_VERIFY_PAYMENT_ERROR',

  GET_CHILD_TOPUP: 'GET_CHILD_TOPUP',
  GET_CHILD_TOPUP_SUCCESS: 'GET_CHILD_TOPUP_SUCCESS',
  GET_CHILD_TOPUP_ERROR: 'GET_CHILD_TOPUP_ERROR',

  GET_ACCOUNT_TRANSACTION_HISTORY: 'GET_ACCOUNT_TRANSACTION_HISTORY',
  GET_ACCOUNT_TRANSACTION_HISTORY_SUCCESS:
    'GET_ACCOUNT_TRANSACTION_HISTORY_SUCCESS',
  GET_ACCOUNT_TRANSACTION_HISTORY_ERROR:
    'GET_ACCOUNT_TRANSACTION_HISTORY_ERROR',

  GET_CHILD_TRANSACTION_HISTORY: 'GET_CHILD_TRANSACTION_HISTORY',
  GET_CHILD_TRANSACTION_HISTORY_SUCCESS:
    'GET_CHILD_TRANSACTION_HISTORY_SUCCESS',
  GET_CHILD_TRANSACTION_HISTORY_ERROR: 'GET_CHILD_TRANSACTION_HISTORY_ERROR',
}
