import './DeleteChildren.scss'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'

import React, { Component } from 'react'
import { TitleDescription } from 'yacob-components'
import { Button } from 'yacob-components'

import { ROUTES as _routes } from '../../constants/app-constants'
import { ACTION_TYPE_CHILD } from '../../redux/actions'

class DeleteChildrenTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      childuuid: '',
      name: '',
    }
  }

  componentDidMount() {
    const { location } = this.props
    const { state } = location
    this.setState({ childuuid: state.uuid, name: state.name })
  }

  componentWillReceiveProps(nextProps) {
    const { childData } = this.props
    if (
      nextProps.childData &&
      nextProps.childData.deleteChild !== childData.deleteChild &&
      nextProps.childData.deleteChild.success
    ) {
      this.historyPush()
    }
  }

  historyPush = () => {
    const { history } = this.props
    history.push(_routes.HOME)
  }

  deleteHandler = () => {
    const { childuuid } = this.state
    const { getChildDelete } = this.props
    getChildDelete(childuuid)
  }

  cancelHandler = () => {
    this.historyPush()
  }

  render() {
    const { name } = this.state
    const { t } = this.props

    const DOM = (
      <div className="deletechildren_container">
        <div className="deletechildren">
          <TitleDescription
            title={<Trans i18nKey="delete_child_title">{{ name }}</Trans>}
            description={
              <Trans i18nKey="delete_child_description">{{ name }}</Trans>
            }
          />
          <div className="deletechildren__button-des u_mobile_display_none">
            <Button
              text={t('cancel')}
              color="lightgrey"
              size="big"
              className="u_margin_1rem_top_bot deletechildren__button-des_button"
              onClick={this.cancelHandler}
            />
            <Button
              text={t('delete')}
              color="red"
              size="big"
              className="u_margin_1rem_top_bot  deletechildren__button-des_button"
              onClick={this.deleteHandler}
            />
          </div>
          <div className="deletechildren__button-mob u_mobile_display_block">
            <Button
              text={t('delete')}
              type="block"
              color="red"
              size="big"
              className="u_margin_1rem_top_bot"
              onClick={this.deleteHandler}
            />
            <Button
              text={t('cancel')}
              type="block"
              color="lightgrey"
              size="big"
              className="u_margin_1rem_top_bot"
              onClick={this.cancelHandler}
            />
          </div>
        </div>
      </div>
    )
    return DOM
  }
}

const mapStateToProps = state => {
  return {
    childData: state.child,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getChildDelete: data =>
      dispatch({
        type: ACTION_TYPE_CHILD.GET_CHILD_DELETE,
        data,
      }),
  }
}

export const DeleteChildren = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(DeleteChildrenTrans)),
)
