import './AlertMessage.scss'

import React, { Component } from 'react'

// TODO: Move this to yacob-components

export class AlertMessage extends Component {
  componentDidMount() {
    // This should be in redux action
    const { id, timeout, destroy } = this.props
    if (timeout === 0) {
      return
    }
    const timer = setTimeout(() => {
      clearTimeout(timer)
      destroy(id)
    }, timeout || 3000)
  }

  render() {
    const { type, content, style } = this.props
    return (
      <div ref={this.alert} className={`toast is-${type}`} style={style}>
        {content}
      </div>
    )
  }
}
