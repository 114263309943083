import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AutoSuggestion, Button } from 'yacob-components'

import styles from './SchoolSelect.module.scss'

import {
  getSuggestionsSchool,
  getSuggestionValueSchool,
  renderSuggestionSchool,
} from '../../utils/autosuggest'

import { ROUTES } from '../../constants/app-constants'

import { ACTION_TYPE_SIGNUP } from '../../redux/actions'

const componentClass = class SchoolSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isInvalid: false,
      selectedSchool: {
        value: '',
        status: '',
        uuid: '',
        code: '',
      },
    }
  }

  componentWillMount() {
    const { getSchoolsList, login, history } = this.props
    if (login.data.uuid) {
      return history.push(ROUTES.HOME)
    }
    getSchoolsList()
  }

  onAutoSuggestionChange = (id, value, suggestionList) => {
    const { uuid, status, code } = suggestionList
    const { selectedSchool } = this.state

    this.setState({
      isInvalid: uuid ? false : true,
      selectedSchool: {
        ...selectedSchool,
        uuid,
        status,
        value,
        code,
      },
    })
  }

  validationChangeHandler = (id, isInvalid) => {}

  onSubmit = path => {
    const { selectedSchool, isInvalid } = this.state
    if (isInvalid || !selectedSchool.uuid) {
      return alert('Please select school')
    }
    localStorage.setItem('selectedSchool', JSON.stringify(selectedSchool))
    this.props.history.push(path)
  }

  render() {
    const { onAutoSuggestionChange } = this
    const { t, signup } = this.props
    const schoolsList = signup.schoolListData
      ? signup.schoolListData.schools
      : []
    const { selectedSchool, isInvalid } = this.state

    return (
      <main className={styles.root}>
        <h1>What school do your kids go to?</h1>
        <div className={styles.schoolSelector}>
          <AutoSuggestion
            label={t('school')}
            type="text"
            required
            //onFocus={this.onFocusInput}
            //currentFieldFocus={currentFieldFocus}
            value={selectedSchool.value}
            onChange={onAutoSuggestionChange}
            suggestionList={schoolsList}
            validation={isInvalid}
            validationChange={this.validationChangeHandler}
            getSuggestions={getSuggestionsSchool}
            getSuggestionValue={getSuggestionValueSchool}
            renderSuggestion={renderSuggestionSchool}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            type="block"
            text={t('login')}
            size="big"
            onClick={() => this.onSubmit(ROUTES.LOGIN)}
            color="black"
          />
          <Button
            type="block"
            text={t('signup')}
            size="big"
            onClick={() => this.onSubmit(ROUTES.SIGNUP)}
          />
        </div>
      </main>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getSchoolsList: () =>
    dispatch({
      type: ACTION_TYPE_SIGNUP.GET_ADDCHILD_SCHOOL,
    }),
})

const mapStateToProps = state => {
  return {
    signup: state.signup,
    login: state.login,
  }
}

export const SchoolSelect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(componentClass)),
)
