import { ACTION_TYPE_SESSION } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  isTokenExpired: false,
  refreshToken: null,
  failedApiList: [],
  singleUseToken: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_SESSION.GET_ACCESS_TOKEN:
      return {
        ...state,
        fetching: true,
        error: null,
        data: action.data,
      }

    case ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY:
      return {
        ...state,
        fetching: true,
        error: null,
        isTokenExpired: action.data,
      }

    case ACTION_TYPE_SESSION.FAILED_API_LIST:
      return {
        ...state,
        fetching: true,
        error: null,
        failedApiList: action,
      }

    case ACTION_TYPE_SESSION.GET_REFRESH_TOKEN:
      return {
        ...state,
        fetching: true,
        error: null,
        data: action.data,
      }

    case ACTION_TYPE_SESSION.GET_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        fetching: true,
        error: null,
        refreshToken: action.response,
      }

    case ACTION_TYPE_SESSION.GET_REFRESH_TOKEN_ERROR:
      return {
        ...state,
        fetching: true,
        error: true,
        refreshToken: null,
      }

    case ACTION_TYPE_SESSION.GET_SINGLE_USE_TOKEN:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_SESSION.GET_SINGLE_USE_TOKEN_SUCCESS:
      return {
        ...state,
        fetching: false,
        singleUseToken: action.response,
      }

    case ACTION_TYPE_SESSION.GET_SINGLE_USE_TOKEN_ERROR:
      return {
        ...state,
        fetching: false,
        singleUseToken: null,
        error: action.error,
      }

    default:
      return state
  }
}
