import './ContactDetails.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import {
  Button,
  Loader,
  TitleDescription,
  InputLabelFloat,
} from 'yacob-components'

import {
  ACTION_TYPE_USER,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'
import { LocalStorage } from '../../utils/service'
import {
  USER_DATA,
  USER_ROLE as _userRoles,
} from '../../constants/app-constants'
import { HasValueValidation } from '../../utils/validation'

class ContactDetailsTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputvalue: {
        firstname: '',
        lastname: '',
        email: '',
        mobileno: '',
        referralCode: '',
      },
      currentFieldFocus: '',
      validation: {
        firstname: false,
        lastname: false,
      },
      userData: {},
      showloader: true,
      showbtnloader: false,
    }
  }

  componentDidMount() {
    const { setProgressData } = this.props
    setProgressData({}, false, 0)
    this.getUserData()
  }

  componentWillReceiveProps(nextProps) {
    const { userData } = this.props
    if (nextProps.userData && nextProps.userData.data !== userData.data) {
      this.setInputValue(nextProps.userData.data)
    }
    if (
      nextProps.userData &&
      nextProps.userData.updateUser !== userData.updateUser
    ) {
      this.updateUser(nextProps.userData.updateUser)
      this.getUserData()
      this.alert({ type: 'success', content: 'Updated successfully' })
    }
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  getUserData = () => {
    const { getUserData } = this.props
    const uuid =
      LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid
    const userRole =
      LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).role
    if (uuid) {
      const data = {
        isFaculty: userRole === _userRoles.FACULTY || false,
        uuid,
      }
      getUserData(data)
    }
  }

  setInputValue = data => {
    const { inputvalue } = this.state
    const valueCopy = { ...inputvalue }
    valueCopy.firstname = data.first_name
    valueCopy.lastname = data.last_name
    valueCopy.email = data.email
    valueCopy.mobileno = data.phone_no
    valueCopy.referralCode = data.referral_code
    this.setState({ inputvalue: valueCopy, showloader: false, userData: data })
  }

  updateUser = data => {
    this.setState({ userData: data, showbtnloader: false })
  }

  onChangeInput = e => {
    const { target } = e
    const { id } = target
    const { value } = target
    const { inputvalue } = this.state
    const valueCopy = { ...inputvalue }
    valueCopy[id] = value
    this.setState({ inputvalue: valueCopy })
  }

  validationChangeHandler = (id, value) => {
    const { validation } = this.state
    const validationCopy = { ...validation }
    validationCopy[id] = value
    this.setState({ validation: validationCopy })
  }

  onFocusInput = id => {
    this.setState({ currentFieldFocus: id })
  }

  validationOnClickHandler = () => {
    const { validation, inputvalue } = this.state
    const validationCopy = { ...validation }
    validationCopy.firstname = !HasValueValidation(inputvalue.firstname)
    validationCopy.lastname = !HasValueValidation(inputvalue.lastname)
    this.setState({ validation: validationCopy })
    return validationCopy
  }

  saveChangesClickHandler = () => {
    const validation = this.validationOnClickHandler()
    if (
      Object.keys(validation).every(k => {
        this.setState({ currentFieldFocus: k })
        return !validation[k]
      })
    ) {
      const { inputvalue, userData } = this.state
      const { getUserUpdateData } = this.props
      this.setState({ showbtnloader: true, currentFieldFocus: '' })
      const userRole =
        LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).role
      const data = {
        uuid: userData.uuid,
        isFaculty: userRole === _userRoles.FACULTY || false,
        payload: {
          first_name: inputvalue.firstname,
          last_name: inputvalue.lastname,
        },
      }
      getUserUpdateData(data)
    }
  }

  render() {
    const {
      inputvalue,
      currentFieldFocus,
      validation,
      showloader,
      showbtnloader,
      userData,
    } = this.state
    const { t } = this.props
    let contentDom = null
    if (showloader) {
      contentDom = <Loader key="loader" />
    } else {
      contentDom = (
        <div className="contactdetails">
          <TitleDescription
            title={`${userData.first_name} ${userData.last_name} — ${t(
              'contact_details',
            )}`}
          />
          <div className="contactdetails__input">
            <InputLabelFloat
              id="firstname"
              label={t('first_name')}
              value={inputvalue.firstname}
              required
              type="text"
              currentFieldFocus={currentFieldFocus}
              onChange={this.onChangeInput}
              validation={validation.firstname}
              validationChange={this.validationChangeHandler}
              onFocus={this.onFocusInput}
            />
            <InputLabelFloat
              id="lastname"
              label={t('last_name')}
              value={inputvalue.lastname}
              required
              type="text"
              currentFieldFocus={currentFieldFocus}
              onChange={this.onChangeInput}
              validation={validation.lastname}
              validationChange={this.validationChangeHandler}
              onFocus={this.onFocusInput}
            />
            <InputLabelFloat
              id="email"
              label={t('email')}
              value={inputvalue.email}
              type="email"
              disabled
            />
            <InputLabelFloat
              id="mobileno"
              label={t('mobile')}
              value={inputvalue.mobileno}
              type="text"
              disabled
            />
            <InputLabelFloat
              id="referralCode"
              label={t('referral_code')}
              value={inputvalue.referralCode}
              type="text"
              disabled
              textSelect
            />
            <Button
              type="block"
              size="big"
              text={t('save')}
              onClick={() => this.saveChangesClickHandler()}
              showloader={showbtnloader}
            />
          </div>
        </div>
      )
    }

    const Dom = <div className="contactdetails_container">{contentDom}</div>
    return Dom
  }
}

const mapStateToProps = state => {
  return {
    userData: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserData: data =>
      dispatch({
        type: ACTION_TYPE_USER.GET_USER,
        data,
      }),
    getUserUpdateData: data =>
      dispatch({
        type: ACTION_TYPE_USER.GET_USER_UPDATE,
        data,
      }),
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const ContactDetails = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ContactDetailsTrans)),
)
