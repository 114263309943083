import { ACTION_TYPE_PRE_AUTH_DETAILS } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  getSchoolInformation: null,
  payment: null,
  createPayment: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_PRE_AUTH_DETAILS.GET_SCHOOL_INFORMATION:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_PRE_AUTH_DETAILS.GET_SCHOOL_INFORMATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        getSchoolInformation: action.response,
      }
    case ACTION_TYPE_PRE_AUTH_DETAILS.GET_SCHOOL_INFORMATION_ERROR:
      return {
        ...state,
        fetching: false,
        getSchoolInformation: null,
        error: action.error,
      }

    case ACTION_TYPE_PRE_AUTH_DETAILS.GET_PRE_AUTH_PAYMENT_DETAILS:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_PRE_AUTH_DETAILS.GET_PRE_AUTH_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        payment: action.response,
      }
    case ACTION_TYPE_PRE_AUTH_DETAILS.GET_PRE_AUTH_PAYMENT_DETAILS_ERROR:
      return {
        ...state,
        fetching: false,
        payment: null,
        error: action.error,
      }
    case ACTION_TYPE_PRE_AUTH_DETAILS.CREATE_PRE_AUTH_PAYMENT:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_PRE_AUTH_DETAILS.CREATE_PRE_AUTH_PAYMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        createPayment: action.response,
      }
    case ACTION_TYPE_PRE_AUTH_DETAILS.CREATE_PRE_AUTH_PAYMENT_ERROR:
      return {
        ...state,
        fetching: false,
        createPayment: null,
        error: action.error,
      }

    default:
      return state
  }
}
