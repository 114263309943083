import { ACTION_TYPE_CHILD } from '../actions'

const initialState = {
  data: {},
  fetching: false,
  error: {},
  childList: null,
  allergyList: null,
  allergyChild: null,
  dietList: null,
  deleteChild: null,
  allergyAdd: null,
  editChild: null,
  reportCardLost: null,
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_CHILD.GET_CHILD_LIST:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        childList: action.response,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        childList: null,
        error: action.error,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_LIST:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        allergyList: action.response,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        allergyList: null,
        error: action.error,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_SUCCESS:
      return {
        ...state,
        fetching: false,
        allergyChild: action.response,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ERROR:
      return {
        ...state,
        fetching: false,
        allergyChild: null,
        error: action.error,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ADD:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ADD_SUCCESS:
      return {
        ...state,
        fetching: false,
        allergyAdd: action.response,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ADD_ERROR:
      return {
        ...state,
        fetching: false,
        allergyAdd: null,
        error: action.error,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_DIET:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_DIET_SUCCESS:
      return {
        ...state,
        fetching: false,
        dietList: action.response,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_DIET_ERROR:
      return {
        ...state,
        fetching: false,
        dietList: null,
        error: action.error,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_DELETE:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_DELETE_SUCCESS:
      return {
        ...state,
        fetching: false,
        deleteChild: action.response,
      }

    case ACTION_TYPE_CHILD.GET_CHILD_DELETE_ERROR:
      return {
        ...state,
        fetching: false,
        deleteChild: null,
        error: action.error,
      }

    case ACTION_TYPE_CHILD.EDIT_CHILD:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.EDIT_CHILD_SUCCESS:
      return {
        ...state,
        fetching: false,
        editChild: action.response,
      }

    case ACTION_TYPE_CHILD.EDIT_CHILD_ERROR:
      return {
        ...state,
        fetching: false,
        editChild: null,
        error: action.error,
      }

    case ACTION_TYPE_CHILD.REPORTING_CARD_LOST:
      return {
        ...state,
        fetching: true,
        error: null,
      }

    case ACTION_TYPE_CHILD.REPORTING_CARD_LOST_SUCCESS:
      return {
        ...state,
        fetching: false,
        reportCardLost: action.response,
      }

    case ACTION_TYPE_CHILD.REPORTING_CARD_LOST_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
        reportCardLost: null,
      }

    default:
      return state
  }
}
