export const ACTION_TYPE_PRE_AUTH_DETAILS = {
  GET_SCHOOL_INFORMATION: 'GET_SCHOOL_INFORMATION',
  GET_SCHOOL_INFORMATION_SUCCESS: 'GET_SCHOOL_INFORMATION_SUCCESS',
  GET_SCHOOL_INFORMATION_ERROR: 'GET_SCHOOL_INFORMATION_ERROR',

  GET_PRE_AUTH_PAYMENT_DETAILS: 'GET_PRE_AUTH_PAYMENT_DETAILS',
  GET_PRE_AUTH_PAYMENT_DETAILS_SUCCESS: 'GET_PRE_AUTH_PAYMENT_DETAILS_SUCCESS',
  GET_PRE_AUTH_PAYMENT_DETAILS_ERROR: 'GET_PRE_AUTH_PAYMENT_DETAILS_ERROR',

  CREATE_PRE_AUTH_PAYMENT: 'CREATE_PRE_AUTH_PAYMENT',
  CREATE_PRE_AUTH_PAYMENT_SUCCESS: 'CREATE_PRE_AUTH_PAYMENT_SUCCESS',
  CREATE_PRE_AUTH_PAYMENT_ERROR: 'CREATE_PRE_AUTH_PAYMENT_ERROR',
}
