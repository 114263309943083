import { ACTION_TYPE_PROGRESSTRACK } from '../actions'

const initialState = {
  alerts: [],
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD:
      return {
        ...state,
        data: action.data,
        toggleDisplay: action.toggleDisplay,
        activeIndex: action.activeIndex,
      }
    case ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT: {
      const { alerts } = state
      const { data } = action
      const { id, content } = data
      const alertID = id || content
      const duplicates = state.alerts.filter(alert => alert.id === alertID)
      if (duplicates.length > 0) {
        const duplicate = duplicates[0]
        const count = (duplicate.count || 0) + 1
        return {
          ...state,
          alerts: [
            ...alerts.filter(alert => alert.id !== alertID),
            {
              ...data,
              id: alertID,
              count,
              content: `(x${count}) ${content}`,
            },
          ],
        }
      }
      return {
        ...state,
        alerts: [...alerts, { ...data, id: id || content }],
      }
    }
    case ACTION_TYPE_PROGRESSTRACK.DELETE_ALERT: {
      const { alerts } = state
      const { id } = action
      return {
        ...state,
        alerts: alerts.filter(alert => alert.id !== id),
      }
    }

    default:
      return state
  }
}
