import './Header.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import {
  ROUTES as _routes,
  USER_DATA,
  LOGO_FILE_PATH,
  USER_ROLE as _userRoles,
  ROUTES,
} from '../../constants/app-constants'

import { Button } from 'yacob-components'
import { HeaderUserDetail } from 'yacob-components'
import { Tooltip } from 'yacob-components'
import { ProgressTrack } from 'yacob-components'

import { LocalStorage } from '../../utils/service'
import { ACTION_TYPE_USER } from '../../redux/actions'

const componentClass = class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: 'signup',
      isLoggedIn: props.isLoggedIn,
      progressTrackData: [],
      progressTrackDisplay: false,
      progressTrackActiveIndex: 0,
      showBorder: '',
      userData: LocalStorage.getData(USER_DATA)
        ? LocalStorage.getData(USER_DATA)
        : {},
    }
  }

  componentDidMount() {
    const { history, getUserData, isLoggedIn } = this.props
    this.setButtonText()
    this.setBorder(history)
    const uuid =
      LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).uuid
    const userRole =
      LocalStorage.getData(USER_DATA) && LocalStorage.getData(USER_DATA).role
    if (uuid && isLoggedIn) {
      const data = {
        isFaculty: userRole === _userRoles.FACULTY || false,
        uuid,
      }
      getUserData(data)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps
    const { loginData, isLoggedIn } = this.props

    if (nextProps.progressTrackData && nextProps.progressTrackData.data) {
      this.setState({
        progressTrackDisplay: nextProps.progressTrackData.toggleDisplay,
        progressTrackData: nextProps.progressTrackData.data,
        progressTrackActiveIndex: Number(
          nextProps.progressTrackData.activeIndex,
        ),
      })
    }
    if (
      nextProps.loginData &&
      nextProps.loginData.data &&
      nextProps.loginData.data !== loginData.data
    ) {
      if (!nextProps.loginData.data.error) {
        this.setState({ isLoggedIn: true, userData: nextProps.loginData.data })
      }
    }
    if (nextProps.isLoggedIn !== isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.isLoggedIn })
    }
    this.setButtonText()
    this.setBorder(history)
  }

  setBorder = history => {
    const { location } = history
    const { pathname } = location
    if (
      pathname.indexOf(_routes.SIGNUP) > -1 &&
      !pathname.match(`${_routes.SIGNUP}/success`)
    ) {
      this.setState({ showBorder: true })
    } else {
      this.setState({ showBorder: false })
    }
  }

  setButtonText = () => {
    const { pathname } = window.location
    if (pathname === _routes.LOGIN)
      this.setState({ buttonText: 'signup', isLoggedIn: false })
    else if (LocalStorage.getUserData(USER_DATA)) {
      this.setState({ isLoggedIn: true })
    } else if (pathname.indexOf(_routes.SIGNUP) > -1) {
      this.setState({ buttonText: 'login', isLoggedIn: false })
    }
  }

  onClickProgressData = (key, index, route) => {
    const { history } = this.props
    const { progressTrackActiveIndex, progressTrackDisplay } = this.state
    if (progressTrackDisplay === 'disableSplit') {
      history.push(route)
    }
    if (progressTrackActiveIndex >= index) {
      this.setState({ progressTrackActiveIndex: index })
      history.replace(route)
    }
  }

  logoutOnClick = path => {
    const { history } = this.props

    LocalStorage.clearSessionData()
    history.push(path)
  }

  logoOnClick = () => {
    const { history } = this.props
    if (LocalStorage.getData(USER_DATA)) history.push(_routes.HOME)
    else history.push(_routes.SCHOOL_SELECT)
  }

  render() {
    const {
      buttonText,
      userData,
      isLoggedIn,
      progressTrackData,
      progressTrackDisplay,
      showBorder,
    } = this.state
    const { onClick, logoutOnClick } = this
    const { t } = this.props

    const tooltipListData = [
      { text: t('children'), key: 'children', route: _routes.HOME },
      {
        text: t('top_up'),
        key: 'Topup',
        route: `${_routes.ACCOUNT_TOPUP}/amount`,
      },
      {
        text: t('transaction_history'),
        key: 'transaction history',
        route: _routes.TRANSACTION_HISTORY,
      },
      {
        text: t('contact_details'),
        key: 'Contact details',
        route: _routes.CONTACT_DETAILS,
      },
      {
        text: t('notifications'),
        key: 'notifications',
        route: _routes.NOTIFICATION,
      },
      {
        text: t('change_password'),
        key: 'change password',
        route: _routes.CHANGE_PASSWORD,
      },
      {
        text: t('logout'),
        key: 'logout',
        route: _routes.LOGIN,
        onClick: logoutOnClick,
      },
    ]

    const facultyTooltipListData = [
      {
        text: t('top_up'),
        key: 'Topup',
        route: `${_routes.ACCOUNT_TOPUP}/amount`,
      },
      {
        text: t('transaction_history'),
        key: 'transaction history',
        route: _routes.TRANSACTION_HISTORY,
      },
      {
        text: t('contact_details'),
        key: 'Contact details',
        route: _routes.CONTACT_DETAILS,
      },
      {
        text: t('change_password'),
        key: 'change password',
        route: _routes.CHANGE_PASSWORD,
      },
      {
        text: t('logout'),
        key: 'logout',
        route: _routes.LOGIN,
        onClick: logoutOnClick,
      },
    ]

    const name = userData && `${userData.first_name} ${userData.last_name}`
    const balance = userData && userData.balance
    const userRole = userData && userData.role

    const DOM = (
      <>
        <div className={`header ${showBorder ? 'header_border' : ''}`}>
          <div
            className="header_logo"
            role="presentation"
            onClick={() => this.logoOnClick()}
          >
            <img src={LOGO_FILE_PATH} alt="logo" className="header_logo_icon" />
          </div>

          <div className="header_user">
            {isLoggedIn ? (
              <Tooltip
                listData={
                  userRole === _userRoles.FACULTY
                    ? facultyTooltipListData
                    : tooltipListData
                }
                history={this.props.history}
              >
                <HeaderUserDetail
                  userName={name}
                  statusText={`${t('balance')}: AED ${balance}`}
                />
              </Tooltip>
            ) : (
              this.props.history.location.pathname !== ROUTES.SCHOOL_SELECT && (
                <NavLink
                  to={buttonText === 'login' ? _routes.LOGIN : _routes.SIGNUP}
                >
                  <Button
                    onClick={onClick}
                    size="small"
                    text={t(buttonText)}
                    color={buttonText === 'login' ? 'black' : ''}
                  />
                </NavLink>
              )
            )}
          </div>
        </div>

        {progressTrackDisplay && (
          <div className="header_progress u_mobile_display_block">
            <ProgressTrack
              onClick={this.onClickProgressData}
              track={progressTrackData}
              progressTrackDisplay={progressTrackDisplay}
            />
          </div>
        )}
      </>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    progressTrackData: state.progressTrack,
    loginData: state.login,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserData: data =>
      dispatch({
        type: ACTION_TYPE_USER.GET_USER,
        data,
      }),
  }
}

export const Header = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(componentClass)),
)
