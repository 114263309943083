import { takeLatest, call, put, fork } from 'redux-saga/effects'

import {
  ACTION_TYPE_LOGIN,
  ACTION_TYPE_SESSION,
  ACTION_TYPE_USER,
} from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'
import { LocalStorage } from '../../utils/service'

function* getloginSaga(action) {
  const { callback = () => {} } = action
  try {
    const { data } = action
    const { isFaculty, payload } = data
    const url = `${BASE_URL}${
      isFaculty ? ENDPOINT.FACULTY_LOGIN : ENDPOINT.LOGIN
    }`
    const response = yield call(fetchCall, url, 'POST', payload)
    if (!response.error) LocalStorage.setUserToken(response)
    yield put({
      type: ACTION_TYPE_LOGIN.GET_LOGIN_SUCCESS,
      response,
    })
    if (response && response.user) {
      yield put({
        type: ACTION_TYPE_USER.GET_USER_SUCCESS,
        response,
      })
    }
    callback(response)
  } catch (error) {
    yield put({
      type: ACTION_TYPE_LOGIN.GET_LOGIN_ERROR,
      error,
    })
    callback(error)
  }
}

function* getchangePasswordSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.CHANGE_PASSWORD}`
    const response = yield call(fetchCall, url, 'PUT', action.data.payload)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_LOGIN.GET_CHANGE_PASSWORD_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_LOGIN.GET_CHANGE_PASSWORD_ERROR,
      error,
    })
  }
}

function* getforgotPasswordSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.FORGOT_PASSWORD}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_LOGIN.GET_FORGOT_PASSWORD_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_LOGIN.GET_FORGOT_PASSWORD_ERROR,
      error,
    })
  }
}

function* getresetPasswordSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.RESET_PASSWORD}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_LOGIN.GET_RESET_PASSWORD_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_LOGIN.GET_RESET_PASSWORD_ERROR,
      error,
    })
  }
}

export function* watchLoginSaga() {
  yield takeLatest(ACTION_TYPE_LOGIN.GET_LOGIN, getloginSaga)
  yield takeLatest(ACTION_TYPE_LOGIN.GET_CHANGE_PASSWORD, getchangePasswordSaga)
  yield takeLatest(ACTION_TYPE_LOGIN.GET_FORGOT_PASSWORD, getforgotPasswordSaga)
  yield takeLatest(ACTION_TYPE_LOGIN.GET_RESET_PASSWORD, getresetPasswordSaga)
}
const loginSaga = [fork(watchLoginSaga)]
export default loginSaga
