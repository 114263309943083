import './AccountTopup.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { TopupAmount } from 'yacob-components'
import { Payment } from 'yacob-components'
import { TopupSuccess } from 'yacob-components'

import {
  ACTION_TYPE_PROGRESSTRACK,
  ACTION_TYPE_TOPUP,
  ACTION_TYPE_LOGIN,
  ACTION_TYPE_USER,
} from '../../redux/actions/'

import {
  ROUTES as _routes,
  USER_DATA,
  PAYMENT_ID,
} from '../../constants/app-constants'

import { LocalStorage } from '../../utils/service'

class AccountTopupTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCustomAmount: false,
      customAmount: '',
      currentFieldFocus: '',
      currentProgress: '',
      paymentCardArray: [
        {
          mainText: `${LocalStorage.getCurrency()} 100`,
          amount: 100,
          showloader: false,
        },
        {
          mainText: `${LocalStorage.getCurrency()} 300`,
          amount: 300,
          showloader: false,
        },
        {
          mainText: `${LocalStorage.getCurrency()} 500`,
          amount: 500,
          showloader: false,
        },
      ],
      // progressData: [
      //   { key: 'amount', status: 'active', displayName: 'AMOUNT', index: 0, route: `${_routes.ACCOUNT_TOPUP}/amount` },
      //   { key: 'paymentmethod', status: '', displayName: 'PAYMENT METHOD', index: 1, route: `${_routes.ACCOUNT_TOPUP}/paymentmethod` },
      // ],
      paymentSelectionValue: '',
      childrenTopupList: [
        {
          mainText: 'Top up Mark balance',
          subText: 'Mark current balance is low (AED 300).',
        },
        {
          mainText: 'Top up Julia balance',
          subText: 'Julia current balance is low (AED 300).',
        },
      ],
      paybtnshowloader: false,
      showCardLoader: false,
      verifiedPayment: {},
    }
    this.totalAmount = 0
    this.verifypayment = false
  }

  componentDidMount() {
    const { history, location } = this.props
    // const { progressData } = this.state;
    const { state } = location
    if (state) this.setCustomAmount(state)
    // setProgressData(progressData, true, 0)
    this.currentPath(history)
  }

  componentWillReceiveProps(nextProps) {
    this.currentPath(nextProps.history)
    const { topupdata } = this.props
    if (
      nextProps.topupdata &&
      nextProps.topupdata.data &&
      nextProps.topupdata.data !== topupdata.data
    ) {
      this.setState({ paybtnshowloader: false })
      if (nextProps.topupdata.data.payment_page) {
        this.paymentGatewayRedirect(nextProps.topupdata.data.payment_page)
      } else if (nextProps.topupdata.data.error) {
        this.setState({ showCardLoader: false })
        this.alert({ type: 'error', content: nextProps.topupdata.data.error })
      }
    }
    if (
      nextProps.topupdata &&
      nextProps.topupdata.verifyPayment &&
      nextProps.topupdata.verifyPayment !== topupdata.verifyPayment &&
      nextProps.topupdata.verifyPayment.transaction
    ) {
      this.setverifiedPayment(nextProps.topupdata.verifyPayment.transaction)
    }
  }

  currentPath = history => {
    const splitpath = history.location.pathname.split(
      `${_routes.ACCOUNT_TOPUP}/`,
    )
    this.setProgressDataHandler(splitpath[1])
  }

  setProgressDataHandler = path => {
    // const { customAmount } = this.state;
    const { history } = this.props
    // const { totalAmount } = this;
    // const index = 0;
    const scrollElement =
      document && document.getElementById('accounttopup_container')
    if (scrollElement) scrollElement.scrollTop = 0
    if (path === 'status' && LocalStorage.getData(PAYMENT_ID)) {
      // setProgressData({}, false, index);
      if (history.location.search) {
        const split = history.location.search.split('&')
        const splitStatus = split[0].split('?status=')
        if (splitStatus[1] === 'CANCELLED') {
          history.replace(`${_routes.ACCOUNT_TOPUP}/amount`)
        }
      }
      this.setState({ currentProgress: path })
      if (!this.verifypayment) {
        this.verifypayment = true
        this.verifyPayment()
      }
    } else if (path === 'status' && !LocalStorage.getData(PAYMENT_ID)) {
      history.replace(`${_routes.ACCOUNT_TOPUP}/amount`)
    }
    if (path === 'status') return
    // if (path === 'paymentmethod' && !(totalAmount || customAmount)) {
    //   history.replace(`${_routes.ACCOUNT_TOPUP}/amount`)
    // }
    // Object.keys(progressData).forEach(e => {
    //   if (progressData[e].key === path) {
    //     progressData[e].status = 'active';
    //     index = e;
    //   }
    // })
    // Object.keys(progressData).forEach(e => {
    //   if (e > index) progressData[e].status = '';
    //   else if (e < index) progressData[e].status = 'done';
    // })
    //
    // setProgressData(progressData, true, index);
    this.setState({ currentProgress: path })
  }

  alert = data => {
    const { setAlertMessage } = this.props
    setAlertMessage(data)
  }

  setCustomAmount = historystate => {
    const { amount } = historystate
    const { loginData } = this.props

    if (loginData && loginData.data) {
      const customAmount = `${amount - loginData.data.balance}`
      this.setState({ showCustomAmount: true, customAmount })
    }
  }

  historyPush = path => {
    const { history } = this.props
    history.push(path)
  }

  customAmountClick = () => {
    this.setState({ showCustomAmount: true })
  }

  saveCustomAmountClick = () => {
    const { customAmount } = this.state
    this.onClickPaymentCard(customAmount)
  }

  customAmountChange = e => {
    this.setState({ customAmount: e.target.value })
  }

  onFocusCustomAmount = () => {
    this.setState({ currentFieldFocus: 'customAmount' })
  }

  onClickPaymentCard = (amount, isCard) => {
    if (isCard) this.setState({ showCardLoader: true })
    this.totalAmount = amount
    this.setTopup(amount)
    // this.setProgressDataHandler('paymentmethod')
    // this.historyPush(`${_routes.ACCOUNT_TOPUP}/paymentmethod`)
  }

  onChangeRadioPayment = e => {
    this.setState({ paymentSelectionValue: e.target.value })
  }

  onClickPayment = () => {
    const { totalAmount } = this
    this.setTopup(totalAmount)
  }

  onClickChildrenCard = () => {
    LocalStorage.removeItem(PAYMENT_ID)
    this.historyPush(_routes.CHILD_TOPUP)
  }

  setTopup = amount => {
    const { setTopup } = this.props
    // const { paymentSelectionValue } = this.state;
    const uuid =
      LocalStorage.getUserData(USER_DATA) &&
      LocalStorage.getUserData(USER_DATA).uuid
    // if (paymentSelectionValue) {
    const payload = {
      parent_uuid: uuid,
      amount,
      payment_type: 'Credit card',
      return_url: `${window.location.origin}/account-topup/status`,
    }
    this.setState({ paybtnshowloader: true })
    setTopup(payload)
    // } else {
    //   alert('Please select payment method')
    // }
  }

  verifyPayment = () => {
    const { verifyPayment } = this.props
    const uuid =
      LocalStorage.getUserData(USER_DATA) &&
      LocalStorage.getUserData(USER_DATA).uuid
    const transaction_reference = LocalStorage.getUserData(PAYMENT_ID)
    const payload = {
      parent_uuid: uuid,
      transaction_reference,
    }
    verifyPayment(payload)
  }

  paymentGatewayRedirect = data => {
    window.open(data.payment_url, '_self')
    LocalStorage.setData(PAYMENT_ID, data.tran_ref)
  }

  setverifiedPayment = data => {
    this.setState({ verifiedPayment: data })
    if (data.status === 'SUCCESS') {
      const { setUserData } = this.props
      const userData = LocalStorage.getUserData(USER_DATA)
      userData.balance = data.balance
      const response = { user: userData }
      setUserData(response)
    } else if (data.api_result) {
      // this.alert({ type: 'error', content: data.api_result })
    }
  }

  successContinueClick = () => {
    this.historyPush(_routes.HOME)
  }

  tryAgainClickHandler = () => {
    this.historyPush(`${_routes.ACCOUNT_TOPUP}/amount`)
  }

  renderContent = () => {
    const {
      showCardLoader,
      paybtnshowloader,
      verifiedPayment,
      showCustomAmount,
      childrenTopupList,
      paymentSelectionValue,
      customAmount,
      currentFieldFocus,
      currentProgress,
      paymentCardArray,
    } = this.state
    const { userData, t } = this.props
    const { totalAmount } = this
    let dom = null
    const balance = userData && userData.data && userData.data.balance
    switch (currentProgress) {
      case 'amount':
        dom = (
          <TopupAmount
            balanceText={t('your_current_balance')}
            balanceValue={balance}
            onClickPaymentCard={this.onClickPaymentCard}
            showCustomAmount={showCustomAmount}
            customAmount={customAmount}
            paymentCardValue={paymentCardArray}
            currentFieldFocus={currentFieldFocus}
            customAmountClick={this.customAmountClick}
            customAmountChange={this.customAmountChange}
            onFocusCustomAmount={this.onFocusCustomAmount}
            saveCustomAmountClick={this.saveCustomAmountClick}
            paybtnshowloader={paybtnshowloader}
            showCardLoader={showCardLoader}
          />
        )
        break
      case 'paymentmethod':
        dom = (
          <Payment
            title="Top Up"
            description="Please fill in your card details here:"
            balance={totalAmount}
            onChangeRadio={this.onChangeRadioPayment}
            paymentSelection={paymentSelectionValue}
            onClickPay={this.onClickPayment}
            paybtnshowloader={paybtnshowloader}
          />
        )
        break
      case 'status':
        dom = (
          <TopupSuccess
            cardText={t('your_new_balance')}
            successonClick={this.successContinueClick}
            childrenTopupList={childrenTopupList}
            onClickChildrenCard={this.onClickChildrenCard}
            data={verifiedPayment}
            balance={balance}
            tryagainClick={this.tryAgainClickHandler}
          />
        )
        break
      default:
        break
    }
    return dom
  }

  render() {
    const DOM = (
      <div className="accounttopup_container" id="accounttopup_container">
        {this.renderContent()}
      </div>
    )

    return DOM
  }
}

const mapStateToProps = state => {
  return {
    topupdata: state.topup,
    loginData: state.login,
    userData: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
    setTopup: data =>
      dispatch({
        type: ACTION_TYPE_TOPUP.GET_PARENT_TOPUP,
        data,
      }),
    verifyPayment: data =>
      dispatch({
        type: ACTION_TYPE_TOPUP.GET_VERIFY_PAYMENT,
        data,
      }),
    setUserData: response =>
      dispatch({
        type: ACTION_TYPE_LOGIN.GET_LOGIN_SUCCESS,
        response,
      }),
    getUserData: data =>
      dispatch({
        type: ACTION_TYPE_USER.GET_USER,
        data,
      }),
    setAlertMessage: data =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_ALERT_CONTENT,
        data,
      }),
  }
}

export const AccountTopup = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(AccountTopupTrans)),
)
