export function EmailValidation(data) {
  data = data.trim()
  const re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  return re.test(data)
}

export function PhoneNumberValidation(data) {
  const re = /^[a-zA-Z0-9\-().\s]{10,15}$/
  return re.test(data)
}

export function ConfirmPasswordValidation(password, confirmPassword) {
  if (password !== confirmPassword) return false
  return true
}

export function PasswordValidation(password) {
  // min 8 char, one digit, one lowercase, one uppercase, special char as optional
  const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,}$/
  return re.test(password)
}

export function NumberValidation(value) {
  value = value.trim()
  const reg = /^[0-9]+$/
  if (!value) return true
  return reg.test(value)
}

export function HasValueValidation(data) {
  data = data.trim()
  return data
}
