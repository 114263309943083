export const ACTION_TYPE_SIGNUP = {
  GET_SIGNUP: 'GET_SIGNUP',
  GET_SIGNUP_SUCCESS: 'GET_SIGNUP_SUCCESS',
  GET_SIGNUP_ERROR: 'GET_SIGNUP_ERROR',

  GET_ADDCHILD_SCHOOL: 'GET_ADDCHILD_SCHOOL',
  GET_ADDCHILD_SCHOOL_SUCCESS: 'GET_ADDCHILD_SCHOOL_SUCCESS',
  GET_ADDCHILD_SCHOOL_ERROR: 'GET_ADDCHILD_SCHOOL_ERROR',

  GET_ADDCHILD_GRADE: 'GET_ADDCHILD_GRADE',
  GET_ADDCHILD_GRADE_SUCCESS: 'GET_ADDCHILD_GRADE_SUCCESS',
  GET_ADDCHILD_GRADE_ERROR: 'GET_ADDCHILD_GRADE_ERROR',

  GET_ADDCHILD_CREATE: 'GET_ADDCHILD_CREATE',
  GET_ADDCHILD_CREATE_SUCCESS: 'GET_ADDCHILD_CREATE_SUCCESS',
  GET_ADDCHILD_CREATE_ERROR: 'GET_ADDCHILD_CREATE_ERROR',

  GET_ADDCHILD_SECTION: 'GET_ADDCHILD_SECTION',
  GET_ADDCHILD_SECTION_SUCCESS: 'GET_ADDCHILD_SECTION_SUCCESS',
  GET_ADDCHILD_SECTION_ERROR: 'GET_ADDCHILD_SECTION_ERROR',
}
