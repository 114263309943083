import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_CHILD, ACTION_TYPE_SESSION } from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall, deleteData } from '../../utils/ajax'

function* getChildListSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.CHILD_LIST}${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_LIST_SUCCESS, response })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_LIST_ERROR, error })
  }
}

function* getChildAllergyListSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.CHILD_ALLERGY}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_LIST_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_LIST_ERROR, error })
  }
}

function* getChildAllergySaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.PARENT_CHILD_ALLERGY}${action.data}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_SUCCESS, response })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ERROR, error })
  }
}

function* getChildAllergyAddSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.CHILD_ALLERGY_ADD}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ADD_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ADD_ERROR, error })
  }
}

function* getChildDietSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.CHILD_DIET}`
    const response = yield call(fetchCall, url, 'GET', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_DIET_SUCCESS, response })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_DIET_ERROR, error })
  }
}

function* getChildDeleteSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.ADDCHILD_CREATE}${action.data}`
    const response = yield call(deleteData, url)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_DELETE_SUCCESS, response })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.GET_CHILD_DELETE_ERROR, error })
  }
}

function* editChildListSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.ADDCHILD_CREATE}${action.data.uuid}`
    const response = yield call(fetchCall, url, 'PUT', action.data.payload)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_CHILD.EDIT_CHILD_SUCCESS, response })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.EDIT_CHILD_ERROR, error })
  }
}

function* getReportLost(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.REPORT_CARD_LOST}${action.data.uuid}`
    const response = yield call(fetchCall, url, 'POST', action.data.payload)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_CHILD.REPORTING_CARD_LOST_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE_CHILD.REPORTING_CARD_LOST_ERROR, error })
  }
}

export function* watchChildSaga() {
  yield takeLatest(ACTION_TYPE_CHILD.GET_CHILD_LIST, getChildListSaga)
  yield takeLatest(
    ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_LIST,
    getChildAllergyListSaga,
  )
  yield takeLatest(ACTION_TYPE_CHILD.GET_CHILD_ALLERGY, getChildAllergySaga)
  yield takeLatest(
    ACTION_TYPE_CHILD.GET_CHILD_ALLERGY_ADD,
    getChildAllergyAddSaga,
  )
  yield takeLatest(ACTION_TYPE_CHILD.GET_CHILD_DIET, getChildDietSaga)
  yield takeLatest(ACTION_TYPE_CHILD.GET_CHILD_DELETE, getChildDeleteSaga)
  yield takeLatest(ACTION_TYPE_CHILD.EDIT_CHILD, editChildListSaga)
  yield takeLatest(ACTION_TYPE_CHILD.REPORTING_CARD_LOST, getReportLost)
}
const childSaga = [fork(watchChildSaga)]
export default childSaga
