import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import EnglishLocales from './locales/en.json'
import ArabicLocales from './locales/ar.json'

const { location } = window
const { search } = location
let lang = 'en'

if (search.includes('?lang=')) {
  const searchsplit = search.split('?lang=')
  const [, langvalue] = searchsplit
  lang = langvalue || 'en'
}

const resources = {
  en: {
    translation: EnglishLocales,
  },
  ar: {
    translation: ArabicLocales,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  // FIXME
  keySeparator: false, // we do not use keys in form messages.welcome
})

export default i18n
