import './LunchSubscribe.scss'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import React, { Component } from 'react'

import { ROUTES as _routes } from '../../constants/app-constants'
import { ACTION_TYPE_PROGRESSTRACK } from '../../redux/actions'

import { DeliveryPlan } from 'yacob-components'
import { Payment } from 'yacob-components'

class LunchSubscribeBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentProgress: 'delivery-plan',
      progressData: [
        {
          key: 'delivery-plan',
          status: 'active',
          displayName: 'DELIVERY PLAN',
          index: 0,
          route: `${_routes.LUNCH_SUBSCRIBE}/delivery-plan`,
        },
        {
          key: 'payment',
          status: '',
          displayName: 'payment',
          index: 2,
          route: `${_routes.LUNCH_SUBSCRIBE}/payment`,
        },
      ],
      term: [
        { key: '1_term', displayName: '1 Term', status: 'active', index: 0 },
        { key: '2_term', displayName: '2 Terms', status: '', index: 1 },
        {
          key: 'entire_year',
          displayName: 'Entire year',
          status: '',
          index: 2,
        },
      ],
      perWeekDays: [
        { key: '3_days', displayName: '3 days', status: 'active', index: 0 },
        { key: '4_days', displayName: '4 days', status: '', index: 1 },
        { key: '5_days', displayName: '5 days', status: '', index: 2 },
      ],
      useMyBalanceToggle: false,
      paymentSelection: '',
    }
  }

  componentDidMount() {
    const { setProgressData } = this.props
    const { progressData } = this.state
    setProgressData(progressData, true, 0)
  }

  componentWillReceiveProps(nextProps) {
    this.currentPath(nextProps.history)
  }

  currentPath = history => {
    const { currentProgress } = this.state
    const splitpath = history.location.pathname.split(
      `${_routes.LUNCH_SUBSCRIBE}/`,
    )
    if (splitpath[1] !== currentProgress)
      this.setProgressDataHandler(splitpath[1])
  }

  setProgressDataHandler = path => {
    const { progressData } = this.state
    const { setProgressData } = this.props
    let index = 0

    Object.keys(progressData).forEach(e => {
      if (progressData[e].key === path) {
        progressData[e].status = 'active'
        index = e
      }
    })
    Object.keys(progressData).forEach(e => {
      if (e > index) {
        progressData[e].status = ''
      } else if (e < index) {
        progressData[e].status = 'done'
      }
    })
    this.setState({
      currentProgress: path,
    })
    setProgressData(progressData, true, index)
  }

  historyPush = path => {
    const { history } = this.props
    history.push(path)
  }

  useMyBalanceToggleHandler = e => {
    const { target } = e
    const { checked, id } = target
    this.setState({ [id]: checked })
  }

  statusChangeHandler = (data, selectedItem) => {
    Object.keys(data).forEach(e => {
      if (data[e].key === selectedItem.key) {
        data[e].status = 'active'
      } else {
        data[e].status = ''
      }
    })
    return data
  }

  termonClick = selectedItem => {
    const { term } = this.state
    const termChange = this.statusChangeHandler(term, selectedItem)
    this.setState({ term: termChange })
  }

  perWeekDaysonClick = selectedItem => {
    const { perWeekDays } = this.state
    const perWeekDaysChange = this.statusChangeHandler(
      perWeekDays,
      selectedItem,
    )
    this.setState({ perWeekDays: perWeekDaysChange })
  }

  onChangeRadio = e => {
    this.setState({ paymentSelection: e.target.value })
  }

  renderContent = () => {
    const {
      currentProgress,
      useMyBalanceToggle,
      paymentSelection,
      term,
      perWeekDays,
    } = this.state
    let dom = null
    switch (currentProgress) {
      case 'delivery-plan':
        dom = (
          <DeliveryPlan
            submitClick={this.deliveryPlanSubmitClick}
            termonClick={this.termonClick}
            perWeekDaysonClick={this.perWeekDaysonClick}
            term={term}
            perWeekDays={perWeekDays}
          />
        )
        break
      case 'payment':
        dom = (
          <Payment
            title="Subscribe"
            description="Select the best payment method for you:"
            showUseMyBalance
            paymentSelection={paymentSelection}
            onChangeRadio={this.onChangeRadio}
            id="useMyBalanceToggle"
            toggleValue={useMyBalanceToggle}
            onChange={this.useMyBalanceToggleHandler}
          />
        )
        break
      default:
        break
    }
    return dom
  }

  deliveryPlanSubmitClick = () => {
    const { progressData } = this.state
    const { setProgressData } = this.props
    progressData[0].status = 'done'
    progressData[1].status = 'active'
    this.setState({ progressData, currentProgress: 'payment' })
    setProgressData(progressData, true, 0)
    this.historyPush(`${_routes.LUNCH_SUBSCRIBE}/payment`)
  }

  render() {
    const DOM = (
      <div className="lunchSubscribe_container">{this.renderContent()}</div>
    )
    return DOM
  }
}

const mapStateToProps = state => {
  return {
    progressTrackData: state.progressTrack,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
  }
}

export const LunchSubscribe = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LunchSubscribeBase),
)
