import './ChildTransactionHistory.scss'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { LocalStorage } from '../../utils/service'
import { ROUTES as _routes } from '../../constants/app-constants'
import {
  ACTION_TYPE_TOPUP,
  ACTION_TYPE_PROGRESSTRACK,
} from '../../redux/actions'

import {
  Pagination,
  Loader,
  TitleDescription,
  FilterDropdown,
  Table,
} from 'yacob-components'

class ChildTransactionHistoryTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterdata: [
        { key: '7', displayName: 'Last week', index: 0 },
        { key: '30', displayName: 'Last 30 days', index: 1 },
        { key: '60', displayName: 'Last 60 days', index: 2 },
        { key: '', displayName: 'None of the Above', index: 3 },
      ],
      filterActiveValue: 'Filter By',
      filterActiveKey: '',
      historyData: [],
      historyColumns: [
        {
          title: 'items',
          key: 'no_items',
          sort: 'a-d',
          render: item => <div>{item}</div>,
        },
        {
          title: 'reference',
          key: 'ref',
          sort: 'a-d',
        },
        {
          title: 'date',
          key: 'date',
          head_classname: 'u_text_right',
          sort: 'a-d',
          render: item => (
            <div className="u_text_right">
              {moment(item).format('DD MMMM, YYYY')}
            </div>
          ),
        },
        {
          title: 'amount',
          key: 'amount',
          head_classname: 'u_text_right',
          data_classname: 'u_font_roboto_medium',
          sort: 'a-d',
          render: item => (
            <div className="u_text_right ">{`${LocalStorage.getCurrency()} ${item}`}</div>
          ),
        },
      ],
      childData: {},
      activePage: 1,
      totalRecords: 50,
      activeSort: '',
      showloader: true,
    }
  }

  componentDidMount() {
    const { location, history } = this.props
    const { state } = location
    if (!state) {
      history.replace(_routes.HOME)
    } else if (state.data) {
      const { data } = state
      const { setProgressData, getTransactionHistory } = this.props
      setProgressData({}, false, 0)
      getTransactionHistory({ uuid: data.uuid })
      this.setState({ childData: data })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { topupData } = this.props

    if (
      nextProps.topupData &&
      nextProps.topupData.childTransactionData &&
      nextProps.topupData.childTransactionData !==
        topupData.childTransactionData
    ) {
      this.setState({ showloader: false })
      if (nextProps.topupData.childTransactionData.transactions)
        this.setState({
          historyData: nextProps.topupData.childTransactionData.transactions,
        })
      if (nextProps.topupData.childTransactionData.total_records)
        this.setState({
          totalRecords: nextProps.topupData.childTransactionData.total_records,
        })
    }
  }

  getTransactionHistory = (page, sort, date_limit) => {
    const { childData } = this.state
    const uuid = childData && childData.uuid
    const { getTransactionHistory } = this.props
    const data = { uuid, page, limit: 20, sort, date_limit }
    getTransactionHistory(data)
  }

  onfilterValueClick = (key, value) => {
    const { activePage, activeSort } = this.state
    this.setState({ filterActiveValue: value, filterActiveKey: key })
    this.getTransactionHistory(activePage, activeSort, key)
  }

  tableItemsSort = key => {
    const { historyColumns, activePage, filterActiveKey } = this.state
    historyColumns.forEach((item, index) => {
      if (key === item.key) {
        const split = item.sort.split('')
        const array = split.reverse()
        const string = array.join('')
        historyColumns[index].sort = string

        if (string === 'a-d') key = `%2B${key}`
        else key = `%20${key}`
        this.setState({ activeSort: key, showloader: true })
        this.getTransactionHistory(activePage, key, filterActiveKey)
      } else {
        historyColumns[index].sort = 'a-d'
      }
    })
    this.setState({ historyColumns })
  }

  onChangePagination = key => {
    const { activeSort, filterActiveKey } = this.state
    this.setState({ activePage: key })
    this.getTransactionHistory(key, activeSort, filterActiveKey)
  }

  render() {
    const {
      filterdata,
      showloader,
      filterActiveValue,
      historyData,
      historyColumns,
      childData,
      totalRecords,
    } = this.state
    const { t } = this.props
    const name = childData && `${childData.first_name} ${childData.last_name}`

    const mobileView =
      historyData && historyData.length ? (
        historyData.map(data => {
          return (
            <div
              className="chidTransactionHistory__table_list"
              key={data.item_uuid}
            >
              <div className="chidTransactionHistory__table_list_left">
                <div className="chidTransactionHistory__table_list_bold">
                  {data.ref}
                </div>
                <div className="chidTransactionHistory__table_list_itemvalue">{`Items : ${data.items}`}</div>
              </div>
              <div className="chidTransactionHistory__table_list_right">
                <div className="chidTransactionHistory__table_list_date">
                  {moment(data.date).format('DD MMMM, YYYY')}
                </div>
                <div className="chidTransactionHistory__table_list_bold">{`${LocalStorage.getCurrency()} ${
                  data.amount
                }`}</div>
              </div>
            </div>
          )
        })
      ) : (
        <div className="chidTransactionHistory__nodata">No Data !!!</div>
      )

    const contentDom = []

    contentDom.push(
      <>
        <div className="chidTransactionHistory__filter">
          <FilterDropdown
            data={filterdata}
            filterActiveValue={filterActiveValue}
            onClick={this.onfilterValueClick}
          />
        </div>
        {showloader ? (
          <Loader />
        ) : (
          <>
            <div className="chidTransactionHistory__table">
              {window.innerWidth <= 768 ? (
                <div className="chidTransactionHistory__table_list_wrapper">
                  {mobileView}
                </div>
              ) : (
                <Table
                  data={historyData}
                  columns={historyColumns}
                  tableSort={this.tableItemsSort}
                  itemkey="date"
                />
              )}
            </div>
            {historyData && historyData.length ? (
              <div className="transactionHistory__table_pagination">
                <Pagination
                  totalPage={Math.ceil(totalRecords / 20)}
                  onChange={this.onChangePagination}
                />
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </>,
    )

    return (
      <div className="chidTransactionHistory_container">
        <div className="chidTransactionHistory">
          <TitleDescription title={`${name} — ${t('transaction_history')}`} />
          {contentDom}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    topupData: state.topup,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTransactionHistory: data =>
      dispatch({
        type: ACTION_TYPE_TOPUP.GET_CHILD_TRANSACTION_HISTORY,
        data,
      }),
    setProgressData: (data, toggleDisplay, activeIndex) =>
      dispatch({
        type: ACTION_TYPE_PROGRESSTRACK.GET_SIGNUP_ADD_CHILD,
        data,
        toggleDisplay,
        activeIndex,
      }),
  }
}

export const ChildTransactionHistory = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ChildTransactionHistoryTrans)),
)
