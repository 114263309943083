import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_LUNCHORDER, ACTION_TYPE_SESSION } from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getmenulistSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.MENU_LIST}${action.data.uuid}?from_date=${action.data.from_date}&to_date=${action.data.to_date}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_LUNCHORDER.GET_MENU_LIST_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_LUNCHORDER.GET_MENU_LIST_ERROR,
      error,
    })
  }
}

function* addCheckoutSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.CHECKOUT}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({
        type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY,
        data,
      })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_LUNCHORDER.ADD_CART_CHECKOUT_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_LUNCHORDER.ADD_CART_CHECKOUT_ERROR,
      error,
    })
  }
}

function* getCheckoutVerifySaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.ORDER_VERIFY}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_LUNCHORDER.CART_CHECKOUT_VERIFY_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_LUNCHORDER.CART_CHECKOUT_VERIFY_ERROR,
      error,
    })
  }
}

export function* watchLunchOrderSaga() {
  yield takeLatest(ACTION_TYPE_LUNCHORDER.GET_MENU_LIST, getmenulistSaga)
  yield takeLatest(ACTION_TYPE_LUNCHORDER.ADD_CART_CHECKOUT, addCheckoutSaga)
  yield takeLatest(
    ACTION_TYPE_LUNCHORDER.CART_CHECKOUT_VERIFY,
    getCheckoutVerifySaga,
  )
}
const lunchOrderSaga = [fork(watchLunchOrderSaga)]
export default lunchOrderSaga
