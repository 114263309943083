import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { ACTION_TYPE_TOPUP, ACTION_TYPE_SESSION } from '../actions'
import { BASE_URL, ENDPOINT } from '../../constants/api-constants'

import { fetchCall } from '../../utils/ajax'

function* getParentTopupSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.PARENT_TOPUP}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_TOPUP.GET_PARENT_TOPUP_SUCCESS, response })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_TOPUP.GET_PARENT_TOPUP_ERROR,
      error,
    })
  }
}

function* getVerifyPaymentSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.VERIFY_PAYMENT}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_TOPUP.GET_VERIFY_PAYMENT_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_TOPUP.GET_VERIFY_PAYMENT_ERROR,
      error,
    })
  }
}

function* getChildTopupSaga(action) {
  try {
    const url = `${BASE_URL}${ENDPOINT.CHILD_TOPUP}`
    const response = yield call(fetchCall, url, 'POST', action.data)
    if (response.tokenExpired) {
      const data = true
      yield put({ type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY, data })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({ type: ACTION_TYPE_TOPUP.GET_CHILD_TOPUP_SUCCESS, response })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_TOPUP.GET_CHILD_TOPUP_ERROR,
      error,
    })
  }
}

function* getAccountTransactionSaga(action) {
  try {
    const { data } = action
    const { uuid, page, limit, sort, date_limit } = data
    let endpoint = ''
    endpoint += page ? `page=${page}` : ''
    endpoint += limit ? `&limit=${limit}` : ''
    endpoint += sort ? `&sort=${sort}` : ''
    endpoint += date_limit ? `&date_limit=${date_limit}` : ''
    const url = `${BASE_URL}${ENDPOINT.ACCOUNT_TRANSACTION}${uuid}?${endpoint}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const datatokenExpired = true
      yield put({
        type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY,
        datatokenExpired,
      })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_TOPUP.GET_ACCOUNT_TRANSACTION_HISTORY_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_TOPUP.GET_ACCOUNT_TRANSACTION_HISTORY_ERROR,
      error,
    })
  }
}

function* getChildTransactionSaga(action) {
  try {
    const { data } = action
    const { uuid, page, limit, sort, date_limit } = data
    let endpoint = ''
    endpoint += page ? `page=${page}` : ''
    endpoint += limit ? `&limit=${limit}` : ''
    endpoint += sort ? `&sort=${sort}` : ''
    endpoint += date_limit ? `&date_limit=${date_limit}` : ''
    const url = `${BASE_URL}${ENDPOINT.ADDCHILD_CREATE}${uuid}${ENDPOINT.TRANSACTIONS}?${endpoint}`
    const response = yield call(fetchCall, url, 'GET')
    if (response.tokenExpired) {
      const datatokenExpired = true
      yield put({
        type: ACTION_TYPE_SESSION.GET_ACCESS_TOKEN_EXPIRY,
        datatokenExpired,
      })
      yield put({ type: ACTION_TYPE_SESSION.FAILED_API_LIST, action })
    } else {
      yield put({
        type: ACTION_TYPE_TOPUP.GET_CHILD_TRANSACTION_HISTORY_SUCCESS,
        response,
      })
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPE_TOPUP.GET_CHILD_TRANSACTION_HISTORY_ERROR,
      error,
    })
  }
}

export function* watchTopupSaga() {
  yield takeLatest(ACTION_TYPE_TOPUP.GET_PARENT_TOPUP, getParentTopupSaga)
  yield takeLatest(ACTION_TYPE_TOPUP.GET_VERIFY_PAYMENT, getVerifyPaymentSaga)
  yield takeLatest(ACTION_TYPE_TOPUP.GET_CHILD_TOPUP, getChildTopupSaga)
  yield takeLatest(
    ACTION_TYPE_TOPUP.GET_ACCOUNT_TRANSACTION_HISTORY,
    getAccountTransactionSaga,
  )
  yield takeLatest(
    ACTION_TYPE_TOPUP.GET_CHILD_TRANSACTION_HISTORY,
    getChildTransactionSaga,
  )
}
const topupSaga = [fork(watchTopupSaga)]
export default topupSaga
