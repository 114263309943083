/* eslint-disable func-names */
import { ACCESS_TOKEN } from '../constants/app-constants'
import { LocalStorage } from './service'

export async function fetchCall(url, method, payload) {
  return new Promise(function (resolve, reject) {
    try {
      const options = {
        method,
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: LocalStorage.getUserData(ACCESS_TOKEN),
        },
      }
      fetch(url, options)
        .then(res => {
          if (
            (res.status === 401 || res.status === 419) &&
            url.indexOf('/login') <= -1
          ) {
            return { tokenExpired: true }
          }
          return res.json()
        })
        .then(res => {
          resolve(res)
        })
        .catch(error => reject(error))
    } catch (error) {
      reject(error)
    }
  })
}

export async function deleteData(url) {
  return new Promise(function (resolve, reject) {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: LocalStorage.getUserData(ACCESS_TOKEN),
        },
      }
      fetch(url, options)
        .then(res => {
          if (res.status === 204) {
            return { success: true }
          }
          return res.json()
        })
        .then(res => {
          resolve(res)
        })
        .catch(error => reject(error))
    } catch (error) {
      reject(error)
    }
  })
}

export async function uploadCall(url, method, payload) {
  return new Promise(function (resolve, reject) {
    try {
      const options = {
        method,
        body: payload,
        headers: {
          Authorization: LocalStorage.getUserData(ACCESS_TOKEN),
        },
      }
      fetch(url, options)
        .then(res => {
          if (
            (res.status === 401 || res.status === 419) &&
            url.indexOf('/login') <= -1
          ) {
            return { tokenExpired: true }
          }
          return res.json()
        })
        .then(res => {
          resolve(res)
        })
        .catch(error => reject(error))
    } catch (error) {
      reject(error)
    }
  })
}
